import axios from 'axios'
import { API_ENDPOINT } from '../../tools/apiEndpoint'

export const oganizationListApi = async () => {
  const URL = `${API_ENDPOINT}/organizations`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const response = await axios.get(
    URL,
    config
  )

  return response
}
