import React from 'react'
import i18n from 'i18next'

function PageNotFound () {
  return (
      <div className="wrapper">
        <div>
          {
            <h2 style={{ textAlign: 'center', position: 'absolute', margin: '0 auto', width: '100%', top: '50%', bottom: '50%' }}>{i18n.t('USER-MANAGEMENT_USER-MANAGEMENT_ACCESS-ERROR-MESSAGE_LABEL')}</h2>
          }
          </div>
      </div>
  )
}

export default PageNotFound
