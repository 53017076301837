import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Open-Sans, Helvetica, Sans-Serif;
  }
`

export default GlobalStyle

export const GlobAttr = {
  primeColor: '#B9103D',
  inactiveColor: '#e0e0e0',
  white: '#fff',
  unHilight: '#757575'
}
