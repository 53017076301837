import React from 'react'
import PropTypes from 'prop-types'
import { ModalWrap } from './styles'
function Modal (props) {
  const handleMiddle = (e) => {
    e.stopPropagation()
  }
  return (
    <ModalWrap show={props.show} onClick={props.onClose} className={props.className}>
      <div data-testid="inner-modal" className="modal-box" onClick={handleMiddle}>
        {props.children}
      </div>
    </ModalWrap>
  )
}

export default Modal

Modal.propTypes = {
  children: PropTypes.element,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string
}
