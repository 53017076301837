import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import i18n from 'i18next'

import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import minMax from 'dayjs/plugin/minMax'
import MultiSelect from '../../components/MultiSelect'
// import Select from '@material-ui/core/Select';
import { ContractManagementWrapper } from './styles'
import { SearchButton } from '../../components/Button'
import ContractViewToggle from '../../components/ContractViewToggle'
import ContractQuarterly from '../../components/ContractQuarterly'
import ContractDetail from '../../components/ContractDetail'
import ContractMonthly from '../../components/ContractMonthly'
import YearClick from '../../components/YearClick'
import { CircularProgress } from '@material-ui/core'
import Modal from '../../components/Modal/index'
import { getOrganizationList } from '../../features/organizations/organizationSlice'
import { getContract, resetState, getContractDetail } from '../../features/contracts/contractSlice'
import { useLocation } from 'react-router-dom'
dayjs.extend(quarterOfYear)
dayjs.extend(minMax)

function ContractManagement () {
  const dispatch = useDispatch()
  const location = useLocation()
  const [selectedView, setView] = React.useState('monthly')
  const [currentYear, setYear] = React.useState(dayjs().format('YYYY'))
  const [company, setCompany] = React.useState('')
  const [focusElement, setFocus] = React.useState(dayjs())
  const [focusQuarter, setFocusQuarter] = React.useState(dayjs().format('YYYY-MM-DD'))
  const [branchs, setBranchs] = React.useState(location.state?.organizationIdDefault ? location.state.organizationIdDefault : [])
  const [lastFetch, setLastFetch] = React.useState('')
  const [fromDate, setFromDate] = React.useState(dayjs().startOf('month').format('YYYY-MM-DD'))
  const [toDate, setToDate] = React.useState(dayjs().endOf('month').format('YYYY-MM-DD'))
  const contractState = useSelector(state => state.contract)
  const organizationState = useSelector(state => state.organization)

  React.useEffect(() => {
    dispatch(getOrganizationList())
    dispatch(resetState())
    // dispatch(getContract('monthly', { year: dayjs().format('YYYY') }))

    // TODO: call another api for get detail on right bottom of screen render
    // dispatch(getContractDetail())

    // get company list >> selector
    // get branch list >> selector
    // fetch data from ALL, first branch
    // filter by monthly, quarter, period, year
    if (!location.state) return
    const { companyName, organizationIdDefault } = location.state
    const { from, to, view, year, focusQuarter } = location.state.period
    if (from.split('-')[1] !== to.split('-')[1] && view === 'quarterly') {
      setView('quarterly')
      setYear(year)
      setFocusQuarter(focusQuarter)
      setFromDate(dayjs(from).format('YYYY-MM-DD'))
      // onSelectQuarter({ from, to })
    }
    searchDataInState(location.state.period, organizationIdDefault, companyName)
  }, [])

  const getCompanyOptions = () => {
    let companyOptions = [...new Set(organizationState.list.map(item => item.company))]
    companyOptions = companyOptions.map(item => { return { key: item, value: item } })
    return [{ key: '', value: i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL') }, ...companyOptions]
  }

  const handleCompanyChange = (e) => {
    setCompany(e[0].key)
  }

  const handleBranchChange = (e) => {
    setBranchs(e.map(item => item.id))
  }

  const searchDataInState = (period, organizationId, companyName) => {
    const { from, to, year, focus } = period
    setBranchs(organizationId)
    setYear(year)
    const dayjsFrom = dayjs(from)
    const dayjsTo = dayjs(to)
    setFocus(focus)
    setFromDate(dayjsFrom.format('YYYY-MM-DD'))
    setToDate(dayjsTo.format('YYYY-MM-DD'))
    const selectedId = branchs.length > 0 ? branchs : []
    const body = {
      year,
      organizationId: selectedId
    }

    setLastFetch(selectedId)
    dispatch(getContract({ body }))
    dispatch(getContractDetail({
      body: {
        from: dayjsFrom.format('YYYY-MM-DD'),
        to: dayjsTo.format('YYYY-MM-DD'),
        organizationId: selectedId
      }
    }))
  }

  const onClickSearch = () => {
    // From old multiple module
    // if (branchs.length === 0 && lastFetch.length > 0) {
    //   setBranchs(lastFetch)
    // }
    location.state = undefined
    const selectedId = branchs.length > 0 ? branchs : ''

    const body = {
      year: currentYear,
      organizationId: selectedId || []
    }
    dispatch(getContract({ body }))
    dispatch(getContractDetail({
      body: {
        from: fromDate,
        to: toDate,
        organizationId: selectedId || []
      }
    }
    ))

    setLastFetch(selectedId || [])
  }

  const middleHop = year => {
    setYear(year)
    const body = {
      year: year,
      organizationId: lastFetch || []
    }
    if (lastFetch) {
      dispatch(getContract({ body }))
    }
  }

  const getCurrentPeriod = view => {
    const currentDate = dayjs()
    switch (view) {
      case 'monthly':
        return {
          from: currentDate.startOf('month').format('YYYY-MM-DD'),
          to: currentDate.endOf('month').format('YYYY-MM-DD')
        }
      case 'quarterly':
        return {
          from: currentDate.startOf('quarter').format('YYYY-MM-DD'),
          to: currentDate.endOf('quarter').format('YYYY-MM-DD')
        }
    }
  }

  const handleClick = e => {
    const currentDate = dayjs()
    const period = getCurrentPeriod(e.target.id)
    setView(e.target.id)
    setFocus(currentDate)
    setFromDate(period.from)
    setToDate(period.to)

    if (currentYear !== currentDate.format('YYYY')) {
      setYear(currentDate.format('YYYY'))
      if (lastFetch) {
        const body = {
          year: currentDate.format('YYYY'),
          organizationId: lastFetch
        }
        dispatch(getContract({ type: e.target.id, body }))
      }
    }

    if (lastFetch) {
      dispatch(getContractDetail({
        body: {
          from: period.from,
          to: period.to,
          organizationId: lastFetch
        }
      }))
    }
  }

  const onSelectQuarter = period => {
    setFromDate(period.from)
    setToDate(period.to)
    setFocusQuarter(period.from)
    if (lastFetch) {
      dispatch(getContractDetail({
        body: {
          from: period.from,
          to: period.to,
          organizationId: lastFetch
        }
      }
      ))
    }
  }

  const setMiddleFocus = e => {
    setFocus(e)
    setFromDate(e.startOf('month').format('YYYY-MM-DD'))
    setToDate(e.endOf('month').format('YYYY-MM-DD'))
    if (lastFetch) {
      dispatch(getContractDetail({
        body: {
          from: e.startOf('month').format('YYYY-MM-DD'),
          to: e.endOf('month').format('YYYY-MM-DD'),
          organizationId: lastFetch
        }
      }))
    }
  }

  // const renderCompanyOptions = () => {
  //   const companyOptions = [...new Set(organizationState.list.map(item => item.company))]
  //   return companyOptions.map(item => (
  //     <option key={item} value={item}>{item}</option>
  //   ))
  // }

  // const setCompanyMiddle = e => {
  //   setCompany(e.target.value)
  //   const branchObjList = renderBranchOptions(e.target.value).map(i => i.value)
  //   setBranchs([...branchObjList, 'All'])
  // }

  const renderDateErrorText = () => {
    const from = fromDate
    const to = toDate
    const dateErrorText = from > to ? i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL') : ''
    return dateErrorText ? <p data-testid="error-text" className="error-text">{`${dateErrorText}`}</p> : ''
  }

  const setDateChange = e => {
    switch (e.target.id) {
      case 'from':
        setFromDate(e.target.value || '')
        // execute fetch if the another one have value
        if (lastFetch && toDate && e.target.value) {
          dispatch(getContractDetail({
            body: {
              from: dayjs.max(dayjs(e.target.value), dayjs('1970-01-01')).format('YYYY-MM-DD'),
              to: toDate,
              organizationId: lastFetch
            }
          }
          ))
        }
        break
      case 'to':
        setToDate(e.target.value || '')
        // execute fetch if the another one have value
        if (lastFetch && fromDate && e.target.value) {
          dispatch(getContractDetail({
            body: {
              from: fromDate,
              to: dayjs.max(dayjs(e.target.value), dayjs('1970-01-01')).format('YYYY-MM-DD'),
              organizationId: lastFetch
            }
          }
          ))
        }
        break
    }
  }

  const viewMapper = {
    monthly: <ContractMonthly data={contractState.data} selected={focusElement} onSelectMonth={setMiddleFocus} currentYear={currentYear} />,
    quarterly: <ContractQuarterly currentYear={currentYear} data={contractState.data} onSelectQuarter={onSelectQuarter} initialFromDate={fromDate} selectedQuarter={focusQuarter} />
  }
  return (
    <ContractManagementWrapper>
      <section className="header">
        <div className="header-title">{i18n.t('CONTRACT_CONTRACT-MANAGEMENT_PAGE-HEADER_LABEL')}</div>
      </section>
      <section className="header">
        <div className="header-actions">
          {/* TODO: create Button 車両データ取込ボタン - (11-1 Element) */}
          {/* TODO: create Button ファイナンスデータ取込ボタン - (12-1 Element) */}
          <div className="action-options">
            <label>{i18n.t('COMMON_COMMON_COMPANY-DROPDOWN_LABEL')}</label>
            <MultiSelect
              width="100%"
              placeholder={i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}
              options={getCompanyOptions()}
              valueField="key"
              labelField="value"
              onChange={handleCompanyChange}
              defaultValue={(location.state && location.state.organizationIdDefault.length > 0) ? location.state.companyName : company }
            />
          </div>
          <div className="action-options">
            <label>{i18n.t('COMMON_COMMON_BRANCH-DROPDOWN_LABEL')}</label>
            <MultiSelect
              disabled={!company}
              width="100%"
              placeholder={i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}
              multi={true}
              allItem={true}
              options={organizationState.list.filter(item => item.company === company)}
              valueField="id"
              labelField="branch"
              onChange={handleBranchChange}
              defaultValue={(location.state && location.state.organizationIdDefault.length > 0) && location.state.organizationIdDefault}
            />
          </div>
          <div className="action-options"></div>
          <div className="action-options"></div>
          <div className="action-options" style={{ alignItems: 'flex-end', marginRight: '24px' }}>
            <div className="submit-container">
              <SearchButton data-testid="search-button" onClick={onClickSearch} />
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div>
          <h3>{i18n.t('CONTRACT_CONTRACT-MANAGEMENT_SECTION-HEADER_LABEL')}</h3>
          <div className="filter-scope">
            <ContractViewToggle active={selectedView} onClick={handleClick}/>
            <YearClick onChange={middleHop} year={currentYear} />
          </div>
          <div className="display-month-type-wrapper">
            {viewMapper[selectedView]}
          </div>
        </div>
        <div>
          <h3>{i18n.t('CONTRACT_CONTRACT-MANAGEMENT_SUBSECTION-HEADER_LABEL')}</h3>
          <div className="input-date-wrapper">
            <div className="label-container">
              <span>{i18n.t('CONTRACT_CONTRACT-MANAGEMENT_DATE-FILTER1_LABEL')}</span>
            </div>
            <div>
              <div>
                <input data-testid="date-from" type="date" onChange={setDateChange} id="from" value={fromDate} min="1890-01-01" max={toDate || '9999-12-31'} />
                <span>{i18n.t('CONTRACT_CONTRACT-MANAGEMENT_DATE-FILTER2_LABEL')}</span>
                <input data-testid="date-to" type="date" onChange={setDateChange} id="to" value={toDate} min={fromDate || '1890-01-01'} max="9999-12-31" />
              </div>
              {renderDateErrorText()}
            </div>
          </div>
          <ContractDetail period={{ from: fromDate, to: toDate, view: selectedView, year: currentYear, focus: focusElement, focusQuarter }} org={!location.state ? organizationState.list : location.state.organizationList} data={contractState.detail} lastFetchIds={lastFetch} />
        </div>
      </section>
      <Modal show={contractState.load}><CircularProgress></CircularProgress></Modal>
    </ContractManagementWrapper>
  )
}

export default ContractManagement
