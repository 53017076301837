import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { reportListApi } from './reportAPI'
const initialState = {
  list: null,
  itemCount: 0,
  load: false
}

const parsePeriod = (input) => {
  const month = input % 100

  switch (month) {
    case 1:
      return 'january'
    case 2:
      return 'febuary'
    case 3:
      return 'march'
    case 4:
      return 'april'
    case 5:
      return 'may'
    case 6:
      return 'june'
    case 7:
      return 'july'
    case 8:
      return 'august'
    case 9:
      return 'september'
    case 10:
      return 'october'
    case 11:
      return 'november'
    case 12:
      return 'december'
  }
}

const transformConsumptionData = (data) => {
  for (const rowIdx in data) {
    const row = data[rowIdx]
    if (!row.consumptions) {
      continue
    }
    let totalMileage = 0
    let totalFuelAmount = 0
    let hasData = false
    for (const itemIdx in row.consumptions) {
      const item = row.consumptions[itemIdx]
      const periodName = parsePeriod(item.period)

      if (item.mileage !== null ||
        item.fuelAmt !== null ||
        item.rate !== null) {
        hasData = true
      }

      row[`${periodName}Mileage`] = item.mileage === null ? '' : Math.round(item.mileage * 100) / 100
      row[`${periodName}FuelAmount`] = item.fuelAmt === null ? '' : Math.round(item.fuelAmt * 100) / 100
      row[`${periodName}FuelConsumption`] = item.rate === null ? '' : item.rate.toFixed(2)

      totalMileage += item.mileage
      totalFuelAmount += item.fuelAmt
    }

    row.sumMileage = hasData ? Math.round(totalMileage * 100) / 100 : ''
    row.sumFuel = hasData ? Math.round(totalFuelAmount * 100) / 100 : ''
    row.AverageConsumption = hasData ? (totalFuelAmount ? totalMileage / totalFuelAmount : 0).toFixed(2) : ''
  }

  return data
}

export const getReportList = createAsyncThunk(
  'report/list',
  async (props, { rejectWithValue }) => {
    try {
      const response = await reportListApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clearState: (state) => {
      state.list = null
      state.itemCount = 0
      return state
    }
  },
  extraReducers: {
    [getReportList.fulfilled]: (state, { payload }) => {
      state.list = transformConsumptionData(payload.rows)
      state.itemCount = payload.count
      state.load = false
      return state
    },
    [getReportList.rejected]: (state, { payload }) => {
      state.load = false
    },
    [getReportList.pending]: (state) => {
      state.load = true
      state.list = null
      state.itemCount = 0
    }
  }
})

export const { clearState } = reportSlice.actions

// export const userSelector = (state) => state.vehicle
export default reportSlice.reducer
