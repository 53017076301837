import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { importFinanceApi } from './importFinanceAPI'
const initialState = {
  check: {
    load: false,
    error: {},
    success: {}
  },
  import: {
    load: false,
    data: {}
  }
}

export const importFinance = createAsyncThunk(
  'vehicle/import-finance',
  async (props, { rejectWithValue }) => {
    try {
      const response = await importFinanceApi({ ...props, exec: true })
      return response.data
    } catch (err) {
      if (err.response.status >= 500) {
        return rejectWithValue({ error: { unknown: true } })
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const checkFinance = createAsyncThunk(
  'vehicle/import-check-finance',
  async (props, { rejectWithValue }) => {
    try {
      const response = await importFinanceApi({ ...props, exec: false })
      // console.log('RES', response);
      return response.data
    } catch (err) {
      if (err.response.status >= 500) {
        return rejectWithValue({ error: { unknown: true } })
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const importFinanceSlice = createSlice({
  name: 'importFinance',
  initialState,
  reducers: {
    clearState: (state) => {
      state.check.load = false
      state.check.error = {}
      state.check.success = {}
      state.import.data = {}
      return state
    }
  },
  extraReducers: {
    [checkFinance.fulfilled]: (state, { payload }) => {
      state.check.load = false
      state.check.success = payload
      return state
    },
    [checkFinance.rejected]: (state, { payload }) => {
      state.check.load = false
      state.check.error = payload.error
    },
    [checkFinance.pending]: (state) => {
      state.check.load = true
      state.check.success = {}
      state.check.error = {}
      state.import.data = {}
    },
    [importFinance.fulfilled]: (state, { payload }) => {
      state.import.load = false
      state.import.data = payload
      return state
    },
    [importFinance.rejected]: (state, { payload }) => {
      state.import.load = false
      state.check.error = payload.error
    },
    [importFinance.pending]: (state) => {
      state.import.load = true
      state.check.success = {}
      state.import.data = {}
    }
  }
})

export const { clearState } = importFinanceSlice.actions
export default importFinanceSlice.reducer
