import styled from 'styled-components'

export const ContractDetailWrapper = styled.div`
  display: grid;
  grid-template: 1fr 1fr 1fr/1fr 1fr;
  grid-column-gap: 12px;
  padding: 24px 0;
  .type-card {
    min-height: 172px;
    .header-contain {
      padding-bottom: 15px;
      display: flex;
      justify-content: space-between;
      .title-node {
        position: relative;
      }
      span {
        display: flex;
        align-items: center;
        gap: 5px;
        svg {
          width: 10px;
        }
      }
      #inspection > svg {
        color: rgb(168 0 47);
      }
      #lease > svg {
        color: rgb(23 86 99);
      }
      #regular-maintenance > svg {
        color: rgb(230 134 56);
      }
      #mandatory-insurance > svg {
        color: rgb(55 173 78);
      }
      #special-vehicle-pass > svg {
        color: rgb(36 36 36);
      }
      #voluntary-insurance > svg {
        color: rgb(50 66 168);
      }
    }
    .value-contain {
      line-height: 30px;
      margin-bottom: 10px;
      .value-box {
        display: flex;
        justify-content: space-between;
        .name-box {
          margin-left: 15px;
        }
        .inner-name-box {
          margin-left: 30px;
        }
      }
    }
  }
`
