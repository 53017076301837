import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { getFileApi, editFileApi } from './fileAPI'
const initialState = {
  list: [],
  itemCount: 0,
  error: null,
  load: false,
  isLoading: false,
  isEditing: false
}

export const getFile = createAsyncThunk(
  'file/list',
  async (props, { rejectWithValue }) => {
    try {
      const response = await getFileApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const execFile = createAsyncThunk(
  'file/exec',
  async (props, { rejectWithValue }) => {
    try {
      const response = await editFileApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    clearFileState: (state) => {
      state.list = []
      state.itemCount = 0
      state.error = null
      return state
    },
    setEditing: (state, { payload }) => {
      state.isEditing = payload
      return state
    }
  },
  extraReducers: {
    [getFile.fulfilled]: (state, { payload }) => {
      state.error = null
      state.list = state.list.concat(payload)
      state.isLoading = false
      return state
    },
    [getFile.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.list = []
    },
    [getFile.pending]: (state) => {
      state.isLoading = true
      state.list = []
    },
    [execFile.fulfilled]: (state, { payload }) => {
      state.error = 'none'
      state.isLoading = false
      return state
    },
    [execFile.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload && payload.errors
    },
    [execFile.pending]: (state) => {
      state.isLoading = true
    }
  }
})

export const { clearFileState, setEditing } = fileSlice.actions
export default fileSlice.reducer
