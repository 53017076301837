import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Modal from '../Modal'
import ModalError from './ModalError'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ImportVehicleWrap } from './styles'
import { ImportVehicleButton, NoButton, OkButton, YesButton } from '../Button'
import i18n from 'i18next'
import { checkVehicle, clearState, importVehicle } from '../../features/vehicles/importVehicleSlice'
function ImportVehicle ({ children }) {
  const hiddenFileInput = React.useRef(null)

  const [selectedFile, setFile] = React.useState('')
  const [randomString, setRandomString] = React.useState('0')
  const [show, setShow] = React.useState(false)
  const [showSuccess, setSuccessShow] = React.useState(false)
  const dispatch = useDispatch()
  const vehicleState = useSelector(state => state.importVehicle)

  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  const handleClose = () => {
    setShow(false)
    dispatch(clearState())
  }

  const handleSuccessClose = () => {
    setSuccessShow(false)
    dispatch(clearState())
  }

  React.useEffect(() => {
    dispatch(clearState()) // 2022/06/15 temporary close due to error on other data
  }, [])

  const submitImport = e => {
    dispatch(importVehicle({ file: selectedFile }))
    setShow(false)
  }

  const handleShow = e => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0])
      dispatch(checkVehicle({ file: e.target.files[0] }))
    }
    e.target.value = ''
  }
  React.useEffect(() => {
    const { check } = vehicleState
    if ('insert' in check.success && 'update' in check.success) {
      setShow(true)
    }
    if ('insert' in vehicleState.import.data && 'update' in vehicleState.import.data) {
      setSuccessShow(true)
    }
  }, [vehicleState])
  return (
    <ImportVehicleWrap>
      <ImportVehicleButton data-testid="import-button" onClick={(handleClick)} />
      <input
        type="file"
        data-testid="file-input"
        key={randomString}
        ref={hiddenFileInput}
        onChange={handleShow}
        accept=".csv"
        style={{ display: 'none' }}
      />
      {(vehicleState.import.load || vehicleState.check.load) &&
        <Modal id="model-popup-dialog" show={true}>
          <CircularProgress />
        </Modal>
      }
      {showSuccess && <Modal id="model-popup-dialog" show={true}>
        <section className="confirm-modal">
          <span className="flex-1">{i18n.t('REPORT_CO2-REPORT_POPUP-CONFIRM_LABEL')}</span>
          <div className="flex-1">
            <span>- {vehicleState.import.data?.insert} {i18n.t('REPORT_CO2-REPORT_POPUP-SUCCESS-MESSAGE-INSERT-1_TEXT')}</span>
            <span>- {vehicleState.import.data?.update} {i18n.t('REPORT_CO2-REPORT_POPUP-SUCCESS-MESSAGE-INSERT-2_TEXT')}</span>
          </div>
          <div className="bottom">
            <OkButton data-testid="success-button" onClick={handleSuccessClose} />
          </div>
        </section>
      </Modal>}
      {show && <Modal id="model-popup-dialog" show={true}>
        <section className="confirm-modal">
          <span className="flex-1">{i18n.t('REPORT_CO2-REPORT_POPUP-CONFIRM_LABEL')}</span>
          <div className="flex-1">
            <span>- {vehicleState.check.success.insert} {i18n.t('REPORT_CO2-REPORT_POPUP-RECORDS-MESSAGE-INSERT-1_TEXT')}</span>
            <span>- {vehicleState.check.success.update} {i18n.t('REPORT_CO2-REPORT_POPUP-RECORDS-MESSAGE-INSERT-2_TEXT')}</span>
          </div>
          <div className="bottom">
            <YesButton data-testid="yes-button" onClick={submitImport} />
            <NoButton data-testid="no-button" onClick={handleClose} />
          </div>
        </section>
      </Modal>}
      {!!vehicleState.check.error.fieldName && <ModalError
        title={i18n.t('REPORT_CO2-REPORT_POPUP-WARNING1_LABEL')}
        show={true}
        testId='fieldname-modal'
        onOk={setRandomString}
      />}
      {!!vehicleState.check.error.unknown && <ModalError
        title={i18n.t('COMMON_UNKNOWN-ERROR_MESSAGE_LABEL')}
        value=""
        show={true}
        testId='unknown-modal'
        onOk={setRandomString}
      />}
      {!!vehicleState.check.error.csvError && <ModalError
        title={i18n.t('REPORT_CO2-REPORT_POPUP-WARNING7_LABEL')}
        show={true}
        testId='csverror-modal'
        onOk={setRandomString}
      />}
    </ImportVehicleWrap>
  )
}

export default ImportVehicle
ImportVehicle.propTypes = {
  children: PropTypes.node
}
