import React from 'react'
import { API_ENDPOINT } from '../../tools/apiEndpoint'
import { LoginWrapper } from './styles'
import { LoginButton } from '../../components/Button'
import i18n from 'i18next'

function Login () {
  return (
    <LoginWrapper>
      <div className="login-box">
        <div>
          <h2>{i18n.t('LOGIN_LOGIN_WELCOME-MESSAGE_LABEL')}</h2>
        </div>
        <div>
          <h4>{i18n.t('LOGIN_LOGIN_LOGIN-DESC_LABEL')}</h4>
          <form action={`${API_ENDPOINT}/auth/login`} method="POST" >
            <input type="hidden" name="client" value={localStorage.getItem('uid') || ''}/>
            <LoginButton type="submit"/>
          </form>
        </div>
      </div>
    </LoginWrapper>)
}

export default Login
