
export const generalTab = {
  licensePlateNumber: 'VEHICLE_COMMON_LICENSE-PLATE_LABEL', // licensePlateAddress, licensePlateClass, licensePlateHiragana, licensePlateNumber
  'organization.company': 'VEHICLE_COMMON_COMPANY_LABEL', // organization.company
  'organization.department': 'VEHICLE_COMMON_DEPARTMENT_LABEL', // organization.department
  'organization.businessUnit': 'VEHICLE_COMMON_BUSINESS-UNIT_LABEL', // organization.businessUnit
  'organization.branchOffice': 'VEHICLE_COMMON_BRANCH-OFFICE_LABEL', // organization.branchOffice
  'organization.branch': 'VEHICLE_COMMON_BRANCH_LABEL', // organization.branch
  carNumber: 'VEHICLE_COMMON_CAR-NUMBER_LABEL',
  pastLicensePlate: 'VEHICLE_COMMON_PAST-LICENSE-PLATE_LABEL',
  model: 'VEHICLE_COMMON_MODEL_LABEL',
  code: 'VEHICLE_COMMON_VEHICLE-ID_LABEL', //
  type: 'VEHICLE_COMMON_TYPE_LABEL',
  brand: 'VEHICLE_COMMON_BRAND_LABEL',
  class: 'VEHICLE_COMMON_CLASS_LABEL',
  bodyShape: 'VEHICLE_COMMON_BODY-SHAPE_LABEL',
  specialEquipment: 'VEHICLE_COMMON_SPECIAL-EQUIPMENT_LABEL',
  initialRegistrationDate: 'VEHICLE_COMMON_INITIAL-REGISTRATION-YEAR-MONTH_LABEL',
  registrationDate: 'VEHICLE_COMMON_REGISTRATION-DATE_LABEL',
  engineModel: 'VEHICLE_COMMON_ENGINE-MODEL_LABEL',
  totalDisplacement: 'VEHICLE_COMMON_TOTAL-DISPLACEMENT_LABEL',
  fuelType: 'VEHICLE_COMMON_FUEL-TYPE_LABEL',
  status: 'VEHICLE_COMMON_STATUS_LABEL'
}

export const genetalTabOnSearch = {
  ...generalTab,
  class: 'VEHICLE_COMMON_CLASS_LABEL',
  specialEquipment: 'VEHICLE_COMMON_SPECIAL-EQUIPMENT_LABEL'
}

export const registrationTab = {
  licensePlateNumber: 'VEHICLE_COMMON_LICENSE-PLATE_LABEL',
  owner: 'VEHICLE_COMMON_OWNER_LABEL',
  user: 'VEHICLE_COMMON_USER_LABEL',
  userAddress: 'VEHICLE_COMMON_USER-ADDRESS_LABEL',
  withLease: 'VEHICLE_COMMON_WITH-OR-WITHOUT-LEASE_LABEL',
  expensesIncludingLease: 'VEHICLE_COMMON_EXPENSES-INCLUDE-LEASE_LABEL', // 6-6
  leaseEndDate: 'VEHICLE_COMMON_LEASE-END-DATE_LABEL',
  leaseFee: 'VEHICLE_COMMON_LEASE-FEE_LABEL',
  automobileTax: 'VEHICLE_COMMON_AUTOMOBILE-TAX_LABEL',
  weightTax: 'VEHICLE_COMMON_WEIGHT-TAX_LABEL',
  inspectionExpireDate: 'VEHICLE_COMMON_INSPECTION-EXPIRE-DATE_LABEL',
  mileage: 'VEHICLE_COMMON_MILEAGE_LABEL',
  lastRegularMaintenanceDate: 'VEHICLE_COMMON_LAST-REGULAR-MAINTENANCE-DATE_LABEL',
  regularMaintenanceCycle: 'VEHICLE_COMMON_LEGAL-INSPECTION-CYCLE_LABEL', // 6-14
  nextRegularMaintenanceMonth: 'VEHICLE_COMMON_NEXT-REGULAR-MAINTENANCE-MONTH_LABEL',
  mandatoryInsuranceEndDate: 'VEHICLE_COMMON_MANDATORY-INSURANCE-END-DATE_LABEL',
  voluntaryInsuranceType: 'VEHICLE_COMMON_VOLUNTARY-INSURANCE-TYPE_LABEL',
  voluntaryInsuranceEndDate: 'VEHICLE_COMMON_VOLUNTARY-INSURANCE-END-DATE_LABEL',
  usage: 'VEHICLE_COMMON_USAGE_LABEL',
  privateBusinessUse: 'VEHICLE_COMMON_PRIVATE-USE-OR-BUSINESS-USE_LABEL',
  factoryInCharge: 'VEHICLE_COMMON_ASSIGN-FACTORY_LABEL',
  specialVehiclePassExpireDate: 'VEHICLE_COMMON_SPECIAL-VEHICLE-PASS-EXPIRE-DATE_LABEL'
}

const weightSizeInfoTab = {
  licensePlateNumber: 'VEHICLE_COMMON_LICENSE-PLATE_LABEL',
  seatingCapacity: 'VEHICLE_COMMON_SEATING-CAPACITY_LABEL',
  maxLoadCapacity: 'VEHICLE_COMMON_MAXIMUM-LOAD-CAPACITY_LABEL',
  vehicleWeight: 'VEHICLE_COMMON_VEHICLE-WEIGHT_LABEL',
  grossVehicleWeight: 'VEHICLE_COMMON_GROSS-VEHICLE-WEIGHT_LABEL',
  length: 'VEHICLE_COMMON_LENGTH_LABEL',
  width: 'VEHICLE_COMMON_WIDTH_LABEL',
  height: 'VEHICLE_COMMON_HEIGHT_LABEL',
  frontFrontAxleLoad: 'VEHICLE_COMMON_FF-AXLE-LOAD_LABEL',
  frontRearAxleLoad: 'VEHICLE_COMMON_FR-AXLE-LOAD_LABEL',
  rearFrontAxleLoad: 'VEHICLE_COMMON_RF-AXLE-LOAD_LABEL',
  rearRearAxleLoad: 'VEHICLE_COMMON_RR-AXLE-LOAD_LABEL',
  fifthWheelLoad: 'VEHICLE_COMMON_FIFTH-WHEEL-LOAD_LABEL',
  modelDesignationNumber: 'VEHICLE_COMMON_MODEL-DESIGNATION-NUMBER_LABEL',
  classificationCategoryNumber: 'VEHICLE_COMMON_CLASSIFICATION-CATEGORY-NUMBER_LABEL',
  batteryType: 'VEHICLE_COMMON_BATTERY-TYPE_LABEL',
  batteryReplacementDate: 'VEHICLE_COMMON_BATTERY-REPLACEMENT-DATE_LABEL',
  transmission: 'VEHICLE_COMMON_TRANSMISSION_LABEL',
  cabinColor: 'VEHICLE_COMMON_CABIN-COLOR_LABEL',
  bodyColor: 'VEHICLE_COMMON_BODY-COLOR_LABEL',
  sbsMarking: 'VEHICLE_COMMON_SBS-MARKING_LABEL',
  innerLength: 'VEHICLE_COMMON_INNER-LENGTH_LABEL',
  innerWidth: 'VEHICLE_COMMON_INNER-WIDTH_LABEL',
  loadingPlatformIndoorHeight: 'VEHICLE_COMMON_LOADING-PLATFORM-INDOOR-HEIGHT_LABEL',
  aoriHeight: 'VEHICLE_COMMON_AORI-HEIGHT_LABEL',
  floorClearance: 'VEHICLE_COMMON_FLOOR-CLEARANCE_LABEL',
  lashingRailPosition: 'VEHICLE_COMMON_LASHING-RAIL-POSITION_LABEL',
  tireManufacturer: 'VEHICLE_COMMON_TIRE-MANUFACTURER_LABEL',
  snowTire: 'VEHICLE_COMMON_SNOW-TIRE_LABEL',
  tireChain: 'VEHICLE_COMMON_TIRE-CHAIN_LABEL',
  spareTire: 'VEHICLE_COMMON_SPARE-TIRE_LABEL',
  tireSizeFront: 'VEHICLE_COMMON_TIRE-SIZE-FRONT_LABEL',
  tireSizeRear: 'VEHICLE_COMMON_TIRE-SIZE-REAR_LABEL'
}
const detailedVehicleInfoTab = {
  licensePlateNumber: 'VEHICLE_COMMON_LICENSE-PLATE_LABEL',
  body: 'VEHICLE_COMMON_BODY_LABEL',
  bodyManufacturer: 'VEHICLE_COMMON_BODY-MANUFACTURER_LABEL',
  wing: 'VEHICLE_COMMON_WING_LABEL',
  powerGate: 'VEHICLE_COMMON_POWER-GATE_LABEL',
  powerManufacturer: 'VEHICLE_COMMON_POWER-MANUFACTURER_LABEL',
  powerGateLift: 'VEHICLE_COMMON_POWER-GATE-LIFT_LABEL',
  crane: 'VEHICLE_COMMON_CRANE_LABEL',
  craneManufacturer: 'VEHICLE_COMMON_CRANE-MANUFACTURER_LABEL',
  craneLift: 'VEHICLE_COMMON_CRANE-LIFT_LABEL',
  withJorder: 'VEHICLE_COMMON_WITH-OR-WITHOUT-JORDER_LABEL',
  airSuspension: 'VEHICLE_COMMON_AIR-SUSPENSION_LABEL',
  floorMaterial: 'VEHICLE_COMMON_FLOOR-MATERIAL_LABEL',
  floorWork: 'VEHICLE_COMMON_FLOOR-WORK_LABEL',
  fuelTank: 'VEHICLE_COMMON_FUEL-TANK_LABEL',
  refrigerator: 'VEHICLE_COMMON_REFRIGERATOR_LABEL',
  refrigeratorManufacturer: 'VEHICLE_COMMON_REFRIGERATOR-MANUFACTURER_LABEL',
  refrigeratorModel: 'VEHICLE_COMMON_REFRIGERATOR-MODEL_LABEL',
  inVehicleFreezingEquipment: 'VEHICLE_COMMON_IN-VEHICLE-FREEZING-EQUIPMENT_LABEL',
  temperatureRecord: 'VEHICLE_COMMON_TEMPERATURE-RECORD_LABEL',
  standBy: 'VEHICLE_COMMON_STAND-BY_LABEL',
  etcTwo: 'VEHICLE_COMMON_ETC_LABEL',
  etcOnBoardUnitId: 'VEHICLE_COMMON_ON-BOARD-UNIT-ID_LABEL',
  etcCorporateCardNumber: 'VEHICLE_COMMON_ETC-CORPORATE-CARD-NUMBER_LABEL',
  fuelCardNo: 'VEHICLE_COMMON_FUEL-CARD-NO_LABEL',
  adBlue: 'VEHICLE_COMMON_ADBLUE_LABEL',
  dashcam: 'VEHICLE_COMMON_DASHCAM_LABEL',
  dashcamManufacturer: 'VEHICLE_COMMON_DASHCAM-MANUFACTURER_LABEL',
  withBackEyeCamera: 'VEHICLE_COMMON_WITH-OR-WITHOUT-BACK-EYE-CAMERA_LABEL',
  backEyeCameraManufacturer: 'VEHICLE_COMMON_BACK-EYE-CAMERA-MANUFACTURER_LABEL',
  cornerSensor: 'VEHICLE_COMMON_CORNER-SENSOR_LABEL',
  cornerSensorManufacturer: 'VEHICLE_COMMON_CORNER-SENSOR-MANUFACTURER_LABEL',
  digiTacho: 'VEHICLE_COMMON_DIGI-TACHO_LABEL',
  digiTachoManufacture: 'VEHICLE_COMMON_DIGI-TACHO-MANUFACTURER_LABEL',
  digiTachoUniqueCode: 'VEHICLE_COMMON_DIGI-TACHO-UNIQUE-CODE_LABEL',
  remarks: 'VEHICLE_COMMON_REMARKS_LABEL'
  // according the design file
  // engineNumber: 'VEHICLE_COMMON_ENGINE-NUMBER_LABEL',
  // modelDesignationNumber: 'VEHICLE_COMMON_MODEL-DESIGNATION-NUMBER_LABEL',
  // classificationCategoryNumber: 'VEHICLE_COMMON_CLASSIFICATION-CATEGORY-NUMBER_LABEL',
  // transmission: 'VEHICLE_COMMON_TRANSMISSION_LABEL',
  // tireManufacturer: 'VEHICLE_COMMON_TIRE-MANUFACTURER_LABEL',
  // snowTire: 'VEHICLE_COMMON_SNOW-TIRE_LABEL',
  // spareTire: 'VEHICLE_COMMON_SPARE-TIRE_LABEL',
  // tireSize: 'VEHICLE_COMMON_TIRE-SIZE_LABEL',
  // rLiftType: 'VEHICLE_COMMON_R-LIFT-TYPE_LABEL',
  // rLiftManufacturer: 'VEHICLE_COMMON_R-LIFT-MANUFACTURER_LABEL',
  // innerLength: 'VEHICLE_COMMON_INNER-LENGTH_LABEL',
  // innerWidth: 'VEHICLE_COMMON_INNER-WIDTH_LABEL',
  // loadingPlatformIndoorHeight: 'VEHICLE_COMMON_LOADING-PLATFORM-INDOOR-HEIGHT_LABEL',
  // aoriHeight: 'VEHICLE_COMMON_AORI-HEIGHT_LABEL',
  // floorClearance: 'VEHICLE_COMMON_FLOOR-CLEARANCE_LABEL',
  // lashingRailPosition: 'VEHICLE_COMMON_LASHING-RAIL-POSITION_LABEL',
  // factoryInCharge: 'VEHICLE_COMMON_FACTORY-IN-CHARGE_LABEL',
  // color: 'VEHICLE_COMMON_COLOR_LABEL',
  // cabinColor: 'VEHICLE_COMMON_CABIN-COLOR_LABEL',
  // safixFifthWheelLoad: 'VEHICLE_COMMON_SAFIX-FIFTH-WHEEL-LOAD_LABEL',
  // paintColor: 'VEHICLE_COMMON_PAINT-COLOR_LABEL',
  // batteryType: 'VEHICLE_COMMON_BATTERY-TYPE_LABEL',
  // batteryReplacementDate: 'VEHICLE_COMMON_BATTERY-REPLACEMENT-DATE_LABEL',
  // accessories: 'VEHICLE_COMMON_ACCESSORIES_LABEL',
  // rigType: 'VEHICLE_COMMON_RIG-TYPE_LABEL',
  // containerManufacturer: 'VEHICLE_COMMON_CONTAINER-MANUFACTURER_LABEL',
  // specialEquipment: 'VEHICLE_COMMON_SPECIAL-EQUIPMENT_LABEL',
  // directConnectionType: 'VEHICLE_COMMON_DIRECT-CONNECTION-TYPE_LABEL',
  // fuelCard: 'VEHICLE_COMMON_FUEL-CARD_LABEL',
}
const updatedhistoryTab = {
  licensePlateNumber: 'VEHICLE_COMMON_LICENSE-PLATE_LABEL',
  'vehiclehistory.organizationIdUpdatedAt': 'VEHICLE_COMMON_ORGANIZATION-ID-UPDATED-AT_LABEL',
  'vehiclehistory.organizationIdUpdatedBy': 'VEHICLE_COMMON_ORGANIZATION-ID-UPDATED-BY_LABEL',
  'vehiclehistory.registrationDateUpdatedAt': 'VEHICLE_COMMON_REGISTRATION-DATE-UPDATED-AT_LABEL',
  'vehiclehistory.registrationDateUpdatedBy': 'VEHICLE_COMMON_REGISTRATION-DATE-UPDATED-BY_LABEL',
  'vehiclehistory.statusUpdatedAt': 'VEHICLE_COMMON_STATUS-UPDATED-AT_LABEL',
  'vehiclehistory.statusUpdatedBy': 'VEHICLE_COMMON_STATUS-UPDATED-BY_LABEL',
  'vehiclehistory.userAddressUpdatedAt': 'VEHICLE_COMMON_USER-ADDRESS-UPDATED-AT_LABEL',
  'vehiclehistory.userAddressUpdatedBy': 'VEHICLE_COMMON_USER-ADDRESS-UPDATED-BY_LABEL',
  'vehiclehistory.mileageUpdatedAt': 'VEHICLE_COMMON_MILEAGE-UPDATED-AT_LABEL',
  'vehiclehistory.mileageUpdatedBy': 'VEHICLE_COMMON_MILEAGE-UPDATED-BY_LABEL',
  'vehiclehistory.document1UpdatedAt': 'VEHICLE_COMMON_DOCUMENT1-UPDATED-AT_LABEL',
  'vehiclehistory.document1UpdatedBy': 'VEHICLE_COMMON_DOCUMENT1-UPDATED-BY_LABEL',
  'vehiclehistory.document3UpdatedAt': 'VEHICLE_COMMON_DOCUMENT3-UPDATED-AT_LABEL',
  'vehiclehistory.document3UpdatedBy': 'VEHICLE_COMMON_DOCUMENT3-UPDATED-BY_LABEL',
  'vehiclehistory.batteryReplacementDateUpdatedAt': 'VEHICLE_COMMON_BATTERY-REPLACEMENT-DATE-UPDATED-AT_LABEL',
  'vehiclehistory.batteryReplacementDateUpdatedBy': 'VEHICLE_COMMON_BATTERY-REPLACEMENT-DATE-UPDATED-BY_LABEL',
  'vehiclehistory.snowTireUpdatedAt': 'VEHICLE_COMMON_SNOW-TIRE-UPDATED-AT_LABEL',
  'vehiclehistory.snowTireUpdatedBy': 'VEHICLE_COMMON_SNOW-TIRE-UPDATED-BY_LABEL',
  'vehiclehistory.tireChainUpdatedAt': 'VEHICLE_COMMON_TIRE-CHAIN-UPDATED-AT_LABEL',
  'vehiclehistory.tireChainUpdatedBy': 'VEHICLE_COMMON_TIRE-CHAIN-UPDATED-BY_LABEL'
}

export const consumptionReportTab = {
  licensePlateNumber: 'VEHICLE_COMMON_LICENSE-PLATE_LABEL',
  'organization.company': 'VEHICLE_COMMON_COMPANY_LABEL',
  'organization.branch': 'VEHICLE_COMMON_BRANCH_LABEL',
  status: 'VEHICLE_COMMON_STATUS_LABEL',
  code: 'VEHICLE_COMMON_VEHICLE-ID_LABEL',
  type: 'VEHICLE_COMMON_TYPE_LABEL',
  model: 'VEHICLE_COMMON_MODEL_LABEL',
  grossVehicleWeight: 'VEHICLE_COMMON_GROSS-VEHICLE-WEIGHT_LABEL',
  januaryMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  januaryFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  januaryFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  febuaryMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  febuaryFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  febuaryFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  marchMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  marchFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  marchFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  aprilMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  aprilFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  aprilFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  mayMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  mayFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  mayFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  juneMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  juneFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  juneFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  julyMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  julyFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  julyFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  augustMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  augustFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  augustFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  septemberMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  septemberFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  septemberFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  octoberMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  octoberFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  octoberFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  novemberMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  novemberFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  novemberFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  decemberMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  decemberFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  decemberFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  sumMileage: 'REPORT_CO2-REPORT_TOTAL-MILEAGE_LABEL',
  sumFuel: 'REPORT_CO2-REPORT_TOTAL-FUEL-AMOUNT_LABEL',
  AverageConsumption: 'REPORT_CO2-REPORT_AVERAGE-FUEL-CONSUMPTION_LABEL'
}

export const consumptionReportTabApril = {
  licensePlateNumber: 'VEHICLE_COMMON_LICENSE-PLATE_LABEL',
  'organization.company': 'VEHICLE_COMMON_COMPANY_LABEL',
  'organization.branch': 'VEHICLE_COMMON_BRANCH_LABEL',
  status: 'VEHICLE_COMMON_STATUS_LABEL',
  code: 'VEHICLE_COMMON_VEHICLE-ID_LABEL',
  type: 'VEHICLE_COMMON_TYPE_LABEL',
  model: 'VEHICLE_COMMON_MODEL_LABEL',
  grossVehicleWeight: 'VEHICLE_COMMON_GROSS-VEHICLE-WEIGHT_LABEL',
  aprilMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  aprilFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  aprilFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  mayMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  mayFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  mayFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  juneMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  juneFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  juneFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  julyMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  julyFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  julyFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  augustMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  augustFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  augustFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  septemberMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  septemberFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  septemberFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  octoberMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  octoberFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  octoberFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  novemberMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  novemberFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  novemberFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  decemberMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  decemberFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  decemberFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  januaryMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  januaryFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  januaryFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  febuaryMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  febuaryFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  febuaryFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  marchMileage: 'REPORT_CO2-REPORT_MILEAGE_LABEL',
  marchFuelAmount: 'REPORT_CO2-REPORT_FUEL-AMOUNT_LABEL',
  marchFuelConsumption: 'REPORT_CO2-REPORT_FUEL-CONSUMPTION_LABEL',
  sumMileage: 'REPORT_CO2-REPORT_TOTAL-MILEAGE_LABEL',
  sumFuel: 'REPORT_CO2-REPORT_TOTAL-FUEL-AMOUNT_LABEL',
  AverageConsumption: 'REPORT_CO2-REPORT_AVERAGE-FUEL-CONSUMPTION_LABEL'
}

export const consumptionGroup = [
  {
    label: 'COMMON_COMMON_MONTH-JAN_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-FEB_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-MAR_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-APR_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-MAY_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-JUN_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-JUL_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-AUG_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-SEP_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-OCT_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-NOV_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-DEC_LABEL',
    colspan: 3
  },
  {
    label: 'REPORT_CO2-REPORT_TOTAL_LABEL',
    colspan: 3
  }
]

export const consumptionGroupApril = [
  {
    label: 'COMMON_COMMON_MONTH-APR_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-MAY_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-JUN_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-JUL_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-AUG_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-SEP_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-OCT_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-NOV_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-DEC_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-JAN_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-FEB_LABEL',
    colspan: 3
  },
  {
    label: 'COMMON_COMMON_MONTH-MAR_LABEL',
    colspan: 3
  },
  {
    label: 'REPORT_CO2-REPORT_TOTAL_LABEL',
    colspan: 3
  }
]

export const consumptionColumnMap = {
  consumptionReportTabJan: consumptionReportTab,
  consumptionReportTabApr: consumptionReportTabApril,
  consumptionGroupJan: consumptionGroup,
  consumptionGroupApr: consumptionGroupApril
}

export const columnMap = {
  0: generalTab,
  1: registrationTab,
  2: weightSizeInfoTab,
  3: detailedVehicleInfoTab,
  4: updatedhistoryTab
}
