/* istanbul ignore file */
// A mock function to mimic making an async request for data
import axios from 'axios'
import { API_ENDPOINT } from '../../tools/apiEndpoint'

export const userListApi = async ({ page, perPage, sort, body }) => {
  const URL = `${API_ENDPOINT}/users/search`
  const params = {
    page,
    perPage
  }
  if (sort) {
    params.sort = `${sort.column} ${sort.by}`
  }
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params
  }

  const bodyParameters = { ...body }
  const response = await axios.post(
    URL,
    null || bodyParameters,
    config

  )

  return response
}

export const getSelfDataApi = async () => {
  const URL = `${API_ENDPOINT}/users/me`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const response = await axios.get(
    URL,
    config
  )

  return response
}

export const createUserApi = async ({ exec, body }) => {
  const URL = `${API_ENDPOINT}/users?exec=${exec}`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const bodyParameters = { ...body }

  const response = await axios.post(
    URL,
    null || bodyParameters,
    config

  )

  return response
}

export const editUserApi = async ({ id, body }) => {
  const URL = `${API_ENDPOINT}/users/${id}`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const bodyParameters = { ...body }
  const response = await axios.patch(
    URL,
    bodyParameters,
    config
  )

  return response
}

export const deleteUserApi = async ({ id }) => {
  const URL = `${API_ENDPOINT}/users/${id}`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }
  const response = await axios.delete(
    URL,
    config
  )

  return response
}

export const userExportApi = async ({ sort, body }) => {
  const URL = `${API_ENDPOINT}/users/export`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: sort ? { sort: `${sort.column} ${sort.by}` } : {}
  }

  const bodyParameters = { ...body }

  return (await axios.post(
    URL,
    null || bodyParameters,
    config
  ))
}

// export function userCallApi (email = 'test') {
//   return new Promise((resolve) =>
//     setTimeout(() => resolve({ data: email }), 500)
//   )
// }

// TODO: legacy login logout flow pattern
// export const loginApi = async (uid) => {
//   const URL = `${API_ENDPOINT}/auth/login`
//   const response = await axios.post(URL, {
//     client: uid
//   })
//   return response
// }

export const logoutApi = async () => {
  const URL = `${API_ENDPOINT}/auth/logout`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }
  const bodyParameters = {
    token: localStorage.getItem('token')
  }
  const response = axios.post(
    URL,
    bodyParameters,
    config
  ).then((res) => {
    return res.data
  })
    .catch((error) => {
      return error.response.status
    })
  return response
}

export const userApi = async () => {
  const URL = `${API_ENDPOINT}/users/me`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  return (await axios.get(
    URL,
    config
  ))
}
