import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UserWrapper } from './styles'
import { getOrganizationList } from '../../features/organizations/organizationSlice'
import PropTypes from 'prop-types'

import i18n from 'i18next'
import { createUser, deleteUser, editUser, getSelfData, getUserList, clearState, clearErrorState } from '../../features/users/userSlice'
import Modal from '../../components/Modal'
import Sorting from '../../components/Sorting'
import { dialogMessageList, userColumn } from './valueMapping'
import { useForm } from 'react-hook-form'
import { userExportApi } from '../../features/users/userAPI'
import MultiSelect from '../../components/MultiSelect'
import { CircularProgress } from '@material-ui/core'
import { ExportButton, SearchButton, AddUserButton, EditButton, DeleteButton, SaveButton, CancelButton, YesButton, NoButton, OkButton } from '../../components/Button'
import { useMounted } from '../../tools'
import Pagination from '../../components/Pagination'
import ImportOrganization from '../../components/ImportOrganization'
const PER_PAGE = 10

function UserManagement (props) {
  const isMounted = useMounted()
  const dispatch = useDispatch()
  const [sortOrder, setSortOrder] = React.useState()
  const [page, setPage] = React.useState(1)
  const [company, setCompany] = React.useState('')
  const [newCompany, setNewCompany] = React.useState('')
  const [multipleBranch, setMultipleBranch] = React.useState([])
  const [newBranch, setNewBranch] = React.useState('')
  const [userId, setUserId] = React.useState('')
  const [record, setRecord] = React.useState({})
  const [searchBody, setSearchBody] = React.useState({})
  const [showMessage, setShowMessage] = React.useState(false)
  const [showEdit, setShowEdit] = React.useState(false)
  const [dialogInfo, setDialogInfo] = React.useState({})
  const [editDialogInfo, setEditDialogInfo] = React.useState({})
  const [requestBody, setRequestBody] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(false)
  const userState = useSelector(state => state.user)
  const organizationState = useSelector(state => state.organization)
  const noMoreErrors = 'none'
  const duplication = 'DUPLICATION'
  const addAction = 'add'
  const editAction = 'edit'
  const deleteAction = 'delete'
  const oragnizationItems = ['company', 'branch']
  const { register, getValues, setValue, formState: { errors }, setError, clearErrors } = useForm()

  React.useEffect(() => {
    // Get master data
    dispatch(clearState())
    dispatch(getOrganizationList())
    dispatch(getSelfData())
  }, [])
  React.useEffect(() => {
    if (isMounted) {
      if (sortOrder) {
        dispatch(getUserList({ page, perPage: PER_PAGE, sort: { column: sortOrder.column, by: sortOrder.by }, body: searchBody }))
      } else {
        dispatch(getUserList({ page, perPage: PER_PAGE, body: searchBody }))
      }
    }
  }, [page])

  React.useEffect(async () => {
    const { error } = userState
    if (error && error !== noMoreErrors) {
      if (error[0] === duplication) return showDialog('id_exist')
      else return showDialog('unknown')
    }
    if (error && error === noMoreErrors) {
      (showEdit && editDialogInfo.code === addAction) && await dispatch(createUser({ exec: true, body: requestBody }))
      setShowEdit(false)
      resetValues()
      dispatch(getUserList({ page, perPage: PER_PAGE, body: searchBody }))
    }
  }, [userState])

  const onSearchSubmit = () => {
    setPage(1)
    const body = buildSearchBody()
    setSearchBody(body)
    setCurrentColumn('')
    dispatch(getUserList({ page: 1, perPage: PER_PAGE, body }))
  }

  const onExport = async () => {
    setIsLoading(true)
    const response = await userExportApi({ body: searchBody })
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF])
    const blob = new Blob([bom, response.data], { type: response.headers['content-type'] })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = i18n.t('USER-MANAGEMENT_USER-LIST-EXPORT_DEFAULT-EXPORT-FILE-NAME_LABEL')
    link.click()
    link.remove()
    setIsLoading(false)
  }

  const getOrganizationId = () => {
    return multipleBranch
  }

  const buildSearchBody = () => {
    const body = {}
    const ids = getOrganizationId()
    if (ids.length > 0) body.organizationId = { in: ids }
    if (userId.length > 0) body.email = { ilike: `%${userId}%` }
    return body
  }

  const sortHandle = (column, by) => {
    setSortOrder(by === 'def' ? null : { column, by })
    const searchCondition = { page, perPage: PER_PAGE, body: searchBody }
    if (by !== 'def') {
      searchCondition.sort = { column, by }
    }
    dispatch(getUserList(searchCondition))
  }

  const handlePageChange = pagePag => {
    setPage(pagePag)
  }

  const renderCompanyOptions = () => {
    const companyOptions = [...new Set(organizationState.list.map(item => item.company))]
    return companyOptions.map(item => (
      <option key={item} value={item}>{item}</option>
    ))
  }

  const getCompanyOptions = () => {
    let companyOptions = [...new Set(organizationState.list.map(item => item.company))]
    companyOptions = companyOptions.map(item => { return { key: item, value: item } })
    return [{ key: '', value: i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL') }, ...companyOptions]
  }

  const renderBranchOptions = (companyValue) => {
    const branchOptions = organizationState.list.filter(item => item.company === companyValue)
    return branchOptions.map(item => (
      <option key={item.id} value={item.id}>{item.branch}</option>
    ))
  }

  const handleCompanyChange = (e) => {
    company !== e && setMultipleBranch([])
    e && setCompany(e[0].key)
  }

  const handleBranchChange = (e) => {
    setMultipleBranch(e.map(item => item.id))
  }

  const setUserIdMiddle = (e) => {
    setUserId(e.target.value)
  }

  const createOrEdit = (messageCode) => {
    setShowEdit(true)
    clearErrors(['email', 'company', 'organizationId', 'privilege'])
    setEditDialogInfo(dialogMessageList.find((e) => e.code === messageCode))
  }

  const [currentColumn, setCurrentColumn] = React.useState('')
  const sortTable = (e, col, type) => {
    let newCol = col
    if (oragnizationItems.includes(col)) newCol = `organization.${col}`
    setCurrentColumn(col)
    sortHandle(newCol, type)
  }

  const validation = () => {
    clearErrors(['email', 'company', 'organizationId', 'privilege'])
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    let numOfErrs = 0
    if (!getValues('isEdit') && !getValues('isAdmin')) {
      numOfErrs += 1
      setError('privilege', {
        type: 'manual',
        message: i18n.t('USER-MANAGEMENT_USER-MANAGEMENT_WARNING5_LABEL')
      })
    }
    Object.keys(getValues()).map((field) => {
      let validationMessage = ''
      const value = getValues(field)
      switch (field) {
        case 'email':
          if (value === '') {
            numOfErrs += 1
            validationMessage = 'USER-MANAGEMENT_USER-MANAGEMENT_WARNING1_LABEL'
          } else if (!value.match(mailformat)) {
            numOfErrs += 1
            validationMessage = 'USER-MANAGEMENT_USER-MANAGEMENT_WARNING2_LABEL'
          }
          break
        case 'company':
          if (value === '') {
            numOfErrs += 1
            validationMessage = 'USER-MANAGEMENT_USER-MANAGEMENT_WARNING3_LABEL'
          }
          break
        case 'organizationId':
          if (value === '') {
            numOfErrs += 1
            validationMessage = 'USER-MANAGEMENT_USER-MANAGEMENT_WARNING4_LABEL'
          }
          break
      }
      return setError(field, {
        type: 'manual',
        message: i18n.t(validationMessage)
      })
    })
    if (numOfErrs > 0) return false
    return true
  }

  const saveButtonClickHandler = async () => {
    if (editDialogInfo.code === addAction) {
      const body = {
        email: getValues('email').toLowerCase(),
        isAdmin: getValues('isAdmin') | false,
        isEdit: getValues('isEdit') | false,
        organizationId: getValues('organizationId')
      }
      setRequestBody(body)
      if (validation()) {
        await dispatch(createUser({ exec: false, body }))
      }
    } else {
      if (userState.self.id === record.id && !getValues('isAdmin')) {
        return showDialog('self_delete')
      }
      if (!getValues('isEdit') && !getValues('isAdmin')) {
        return showDialog(deleteAction)
      }
      const body = {
        email: record.email,
        organizationId: record.organizationId,
        isAdmin: getValues('isAdmin') | false,
        isEdit: getValues('isEdit') | false
      }
      setRequestBody(body)
      await dispatch(editUser({ id: record.id, body }))
    }
  }

  const editButtonClickHandler = (data) => {
    setRecord(data)
    Object.keys(getValues()).map((key) => {
      let value = data[key]
      if (oragnizationItems.includes(key) && JSON.stringify(data.organization) !== JSON.stringify({})) {
        value = data.organization[key]
        key === 'company' && setNewCompany(value)
      }
      if (key === 'organizationId') setNewBranch(value)
      return setValue(key, value)
    })
    createOrEdit(editAction)
  }

  const deleteButtonClickHandler = (data) => {
    setRecord(data)
    showDialog(deleteAction)
  }

  const cancelButtonClickHandler = () => {
    if (editDialogInfo.code === editAction) {
      showDialog('cancel')
    } else {
      resetValues()
      setShowEdit(false)
    }
  }

  const resetValues = () => {
    setNewCompany('')
    setNewBranch('')
    Object.keys(getValues()).map((key) => setValue(key, ''))
  }

  const showDialog = (messageCode, execPrms = {}) => {
    setShowMessage(true)
    setDialogInfo(dialogMessageList.find((e) => e.code === messageCode))
  }

  const onClickConfirm = async (dlgInfo) => {
    if (dlgInfo.code === deleteAction) {
      const { self } = userState
      if (self.id === record.id) {
        return showDialog('self_delete')
      }
      await dispatch(deleteUser({ id: record.id }))
      showMessage && setShowMessage(false)
      !showEdit && resetValues()
      dispatch(getUserList({ page, perPage: PER_PAGE, body: searchBody }))
    } else {
      setShowMessage(false)
      setShowEdit(false)
      resetValues()
    }
  }

  const onClickCancel = (dlgInfo) => {
    setShowMessage(false)
  }

  const renderErrorText = field => {
    let errorMessage = ''
    if (errors[field]) {
      errorMessage = errors[field]
    }
    return errorMessage && errorMessage.message !== '' ? <p className="error-text" data-testid={`error-text-${field}`}>{`* ${errorMessage.message}`}</p> : ''
  }

  const renderColumnName = d => {
    const result = []
    for (const i in d) {
      result.push(<td key={i} className={`column-name column-${d[i]}`}>{i18n.t(d[i])} {i !== 'buttons' && <Sorting column={i} sort={sortTable} currentColumn={currentColumn} setCurrentColumn={setCurrentColumn} />}</td>)
    }
    return <thead className="header"><tr>{result}</tr></thead>
  }

  const renderData = list => {
    // no data
    if (list && list.length === 0) {
      return (
        <tbody className="table">
          <tr>
            <td className="table-message">{i18n.t('COMMON_COMMON_DATA-NOT-FOUND_LABEL')}</td>
          </tr>
        </tbody>
      )
    }
    // initial
    if (!list) {
      list = []
    }
    let result = []

    result = list.map((item, index) => {
      const i = { ...item }
      i.editable = false
      const row = []
      let dex = 0
      for (const j in userColumn) {
        let value = i[j]
        if (i.organization && oragnizationItems.includes(j)) {
          value = i.organization[j]
        }
        if (j === 'isEdit' || j === 'isAdmin') {
          if (!value) i[j] = false
          row.push(
            <td data-testid='render-value' key={dex} className="value">
              <label>{value ? '◯' : ''}</label>
            </td>)
        } else if (j === 'buttons') {
          row.push(
            <td data-testid='render-value' key={dex} className="value">
              <div className="cell-buttons">
                <EditButton onClick={() => editButtonClickHandler(i)} data-testid={`btn-edit-${i.id}`} />
                <DeleteButton onClick={() => deleteButtonClickHandler(i)} data-testid={`btn-delete-${i.id}`} />
              </div>
            </td>)
        } else {
          row.push(<td data-testid='render-value' key={dex} className="value">{value}</td>)
        }
        dex = ++dex
      }

      return <tr onClick={() => { }} id={list[index].id} key={index} className="row">
        {row}
      </tr>
    })
    return <tbody className="table">{result}</tbody>
  }

  const handleOnChangeCompanyDialog = (e) => {
    setNewCompany(e.target.value)
    setValue('organizationId', '')
  }

  const handleClose = () => {
    setShowMessage(false)
    dispatch(clearErrorState())
  }

  const countToPage = page * PER_PAGE < userState.itemCount ? page * PER_PAGE : userState.itemCount
  const startItem = userState.itemCount > 0 ? 1 + ((page - 1) * 10) : 0
  return (
    <UserWrapper>
      <Modal show={isLoading || userState.isFetching} className="loading-modal"><CircularProgress></CircularProgress></Modal>
      <Modal show={showEdit} className="add-user-modal">
        <>
          <div className="modal-edit-title">{i18n.t(editDialogInfo.message)}</div>
          <section className="edit">
            <div className="edit-actions">
              <div className="action-options">
                <div className="user-info">
                  <label>{i18n.t('USER-MANAGEMENT_USER-MANAGEMENT_USER-ID_LABEL')}</label>
                  <input className="input-data" data-testid="userid-add" id="userid-add" {...register('email')} {...editDialogInfo.code === editAction && { disabled: true }} />
                </div>
                {renderErrorText('email')}
              </div>
              <div className="action-options">
                <div className="user-info">
                  <label>{i18n.t('COMMON_COMMON_COMPANY-DROPDOWN_LABEL')}</label>
                  <select data-testid="company-add" id="company-add" defaultprops="" {...register('company')} onChange={(e) => handleOnChangeCompanyDialog(e)}
                    {...editDialogInfo.code === editAction && { disabled: true }} >
                    <option value="">{i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}</option>
                    {renderCompanyOptions()}
                  </select>
                </div>
                {renderErrorText('company')}
              </div>
              <div className="action-options">
                <div className="user-info">
                  <label>{i18n.t('COMMON_COMMON_BRANCH-DROPDOWN_LABEL')}</label>
                  <select data-testid="branch-add" id="branch-add" {...register('organizationId')} onChange={(e) => { }}
                    {...editDialogInfo.code === editAction && { value: newBranch }}
                    {...editDialogInfo.code === editAction && { disabled: true }}>
                    <option value="">{i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}</option>
                    {renderBranchOptions(newCompany)}
                  </select>
                </div>
                {renderErrorText('organizationId')}
              </div>
              <div className="action-options">
                <div className="privilege">
                  <div className="privilege-items">
                    <label>{i18n.t('USER-MANAGEMENT_USER-MANAGEMENT_EDIT_LABEL')}</label>
                    <input type="checkbox" data-testid="edit-checkbox" {...register('isEdit')} onChange={() => { }} />
                  </div>
                  <div className="privilege-items">
                    <label>{i18n.t('USER-MANAGEMENT_USER-MANAGEMENT_ADMIN_LABEL')}</label>
                    <input type="checkbox" data-testid="admin-checkbox" {...register('isAdmin')} onChange={() => { }} />
                  </div>
                </div>
                {renderErrorText('privilege')}
              </div>
            </div>
          </section>
          <div className="modal-edit-message-button">
            <SaveButton data-testid="btn-save" onClick={saveButtonClickHandler} />
            <CancelButton data-testid="btn-cancel" onClick={cancelButtonClickHandler} />
          </div>
        </>
      </Modal>
      <Modal show={showMessage} className="modal-message-container">
        <>
          <div className="modal-message-content">{i18n.t(dialogInfo.message)}</div>
          {dialogInfo.type === 'warning'
            ? <div className="modal-message-button">
              <OkButton data-testid="btn-ok" onClick={handleClose} />
            </div>
            : <div className="modal-message-button">
              <YesButton data-testid="btn-yes" onClick={() => onClickConfirm(dialogInfo)} />
              <NoButton data-testid="btn-no" onClick={() => onClickCancel(dialogInfo)} />
            </div>}
        </>
      </Modal>
      <section className="header">
        <div className="header-title">{i18n.t('USER-MANAGEMENT_USER-MANAGEMENT_PAGE-HEADER_LABEL')} </div>
      </section>
      <section className="header">
        <div className="header-actions">
          <div className="action-options">
            <label>{i18n.t('COMMON_COMMON_COMPANY-DROPDOWN_LABEL')}</label>
            <MultiSelect
              testId="select-company"
              placeholder={i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}
              options={getCompanyOptions()}
              valueField="key"
              labelField="value"
              onChange={handleCompanyChange}
              defaultValue=""
            />
          </div>
          <div className="action-options">
            <label>{i18n.t('COMMON_COMMON_BRANCH-DROPDOWN_LABEL')}</label>
            <MultiSelect
              testId="select-branch"
              disabled={!company}
              placeholder={i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}
              multi={true}
              allItem={true}
              options={organizationState.list.filter(item => item.company === company)}
              valueField="id"
              labelField="branch"
              onChange={handleBranchChange}
            />
          </div>
          <div className="action-options">
            <label>{i18n.t('USER-MANAGEMENT_USER-MANAGEMENT_USER-ID_LABEL')}</label>
            <input data-testid="userid" id="userid" className="userid" onChange={setUserIdMiddle} />
          </div>
          <div className="action-options"></div>
          <div className="action-options" style={{ alignItems: 'flex-end', marginRight: '24px' }}>
            <div className="submit-container">
              <SearchButton data-testid="submit-button" tabIndex={31} type="submit" onClick={onSearchSubmit} />
            </div>
          </div>
        </div>
      </section>

      <section className="list">
        <div className="list-action-wrapper">
          <div className="submit-container">
            <AddUserButton data-testid="add-button" onClick={() => createOrEdit(addAction)} />
          </div>
          <div className="action-container">
            <div className='import-button' style={{ marginRight: '18px' }}>
              <ImportOrganization data-testid="import-button" />
            </div>
            <ExportButton data-testid="export-button" onClick={onExport} {...userState.itemCount <= 0 && { disabled: true }} style={{ marginRight: '18px' }}/>
            <span>{i18n.t('COMMON_COMMON_PAGINATION-DISPLAY_LABEL', { page: startItem, countToPage, total: userState.itemCount })}</span>
            <Pagination page={page} count={Math.ceil(userState.itemCount / PER_PAGE)} onChange={handlePageChange} />
          </div>
        </div>
        <table>
          {renderColumnName(userColumn)}
          {renderData(userState.list)}
        </table>
      </section>

    </UserWrapper>)
}

Sorting.propTypes = {
  sort: PropTypes.func,
  column: PropTypes.string,
  setCurrentColumn: PropTypes.func,
  currentColumn: PropTypes.string
}

export default UserManagement
