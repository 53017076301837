import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import styled from 'styled-components'
import { OkButton, ExportTxtButton } from '../Button'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import i18n from 'i18next'

export const ErrorModalWrapper = styled.section`
  padding: 16px;
  max-width: 400px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  .value {
    flex: 1;
    overflow-wrap: break-word;
    span {
      margin-right: 6px;
    }
  }
  button {
    display: inline-block;
    width: auto;
    cursor: pointer;
    margin: 0.2rem 0.5rem;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    color: white;
    background-color: ${props => props.theme.primeColor};
    border: 1px solid ${props => props.theme.primeColor};
  }
  .bottom {
    margin: auto;
  }
`

function ModalError (props) {
  const { title, value = [], show, onOk, testId } = props
  const [showModal, setShowModal] = React.useState(false)
  const consumptionState = useSelector(state => state.consumption)
  React.useEffect(() => {
    setShowModal(show)
  }, [show])

  const handleShow = () => {
    setShowModal(false)
    // this line is for make input re render
    onOk(Math.random().toString(36))
  }

  const exportTxt = () => {
    const element = document.createElement('a')
    const consumptionContent = consumptionState.check.error.csvError.join('\n').toString()

    const file = new Blob([consumptionContent], {
      type: 'plain/text'
    })

    element.href = URL.createObjectURL(file)
    element.download = `${i18n.t('REPORT_CO2-REPORT-IMPORT-ERROR-FIELD-NAME_LABEL')}${dayjs().format('YYYYMMDDHHmm')}.txt`
    element.click()
    element.remove()
    setShowModal(false)
  }
  return (
    <Modal id="model-popup-dialog" show={showModal}>
      <ErrorModalWrapper data-testid={testId}>
        <span className="value">{title}</span>
        {value.length > 0 && <div className="value">{value.map((i, index) => <span key={index}>{i},</span>)}</div>}
        <div className="bottom">
          {testId === 'csverror-modal'
            ? <ExportTxtButton data-testid="csverror-modal-button" onClick={exportTxt} />
            : <OkButton data-testid="error-modal-button" onClick={handleShow} />
          }
        </div>
      </ErrorModalWrapper>
    </Modal>
  )
}

export default ModalError

ModalError.propTypes = {
  title: PropTypes.string,
  testId: PropTypes.string,
  value: PropTypes.any,
  show: PropTypes.bool,
  onOk: PropTypes.func
}
