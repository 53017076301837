import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { FilterTabWrapper } from './styles'
import i18n from 'i18next'
import dayjs from 'dayjs'
// import { minimumInputNumber, maximumInputNumber } from '../../tools/constants'
import { SearchButton } from '../Button'
import { integerNumberOnly } from './advanceSearchOptions'

const fieldNames = {
  licenseAddress: { name: 'licensePlateAddress', type: 'license' },
  licenseClassNo: { name: 'licensePlateClass', type: 'license' },
  licenseNumber: { name: 'licensePlateNumber', type: 'license' },
  lease: {
    label: 'VEHICLE_COMMON_WITH-OR-WITHOUT-LEASE_LABEL',
    name: 'withLease',
    type: 'select',
    selectOptions: ['あり', 'なし'],
    tabIndexes: [5]
  },
  usage: {
    label: 'VEHICLE_COMMON_USAGE_LABEL',
    name: 'usage',
    type: 'select',
    selectOptions: ['乗用', '貨物', '乗合', '特種', '建設機械'],
    tabIndexes: [24]
  },
  owner: {
    label: 'VEHICLE_COMMON_OWNER_LABEL',
    name: 'owner',
    type: 'text',
    maxLength: 255,
    tabIndexes: [4]
  },
  privateBusinessUse: {
    label: 'VEHICLE_COMMON_PRIVATE-USE-OR-BUSINESS-USE_LABEL',
    name: 'privateBusinessUse',
    type: 'select',
    selectOptions: ['自家用', '事業用'],
    tabIndexes: [25]
  },

  voluntaryInsuranceType: {
    label: 'VEHICLE_COMMON_VOLUNTARY-INSURANCE-TYPE_LABEL',
    name: 'voluntaryInsuranceType',
    type: 'select',
    selectOptions: ['フリート', 'ノンフリート', 'OP'],
    tabIndexes: [28]
  },
  automobileTax: {
    label: 'VEHICLE_COMMON_AUTOMOBILE-TAX_LABEL',
    name: 'automobileTax',
    type: 'number',
    tabIndexes: [11, 12]
  },
  weightTax: {
    label: 'VEHICLE_COMMON_WEIGHT-TAX_LABEL',
    name: 'weightTax',
    type: 'number',
    tabIndexes: [13, 14]
  },
  mileage: {
    label: 'VEHICLE_COMMON_MILEAGE_LABEL',
    name: 'mileage',
    type: 'number',
    tabIndexes: [17, 18]
  },
  leaseFee: {
    label: 'VEHICLE_COMMON_LEASE-FEE_LABEL',
    name: 'leaseFee',
    type: 'number',
    tabIndexes: [9, 10]
  },
  leaseEndDate: {
    label: 'VEHICLE_COMMON_LEASE-END-DATE_LABEL',
    name: 'leaseEndDate',
    type: 'date',
    tabIndexes: [7, 8]
  },
  inspectionExpireDate: {
    label: 'VEHICLE_COMMON_INSPECTION-EXPIRE-DATE_LABEL',
    name: 'inspectionExpireDate',
    type: 'date',
    tabIndexes: [15, 16]
  },
  mandatoryInsuranceEnd: {
    label: 'VEHICLE_COMMON_MANDATORY-INSURANCE-END-DATE_LABEL',
    name: 'mandatoryInsuranceEndDate',
    type: 'date',
    tabIndexes: [26, 27]
  },
  lastRegularMaintenance: {
    label: 'VEHICLE_COMMON_LAST-REGULAR-MAINTENANCE-DATE_LABEL',
    name: 'lastRegularMaintenanceDate',
    type: 'date',
    tabIndexes: [19, 20]
  },
  nextRegularMaintenance: {
    label: 'VEHICLE_COMMON_NEXT-REGULAR-MAINTENANCE-MONTH_LABEL',
    name: 'nextRegularMaintenanceMonth',
    type: 'month',
    tabIndexes: [22, 23]
  },
  voluntaryInsuranceEndDate: {
    label: 'VEHICLE_COMMON_VOLUNTARY-INSURANCE-END-DATE_LABEL',
    name: 'voluntaryInsuranceEndDate',
    type: 'date',
    tabIndexes: [29, 30]
  },
  specialVehiclePassExpire: {
    label: 'VEHICLE_COMMON_SPECIAL-VEHICLE-PASS-EXPIRE-DATE_LABEL',
    name: 'specialVehiclePassExpireDate',
    type: 'date',
    tabIndexes: [32, 33]
  },
  expensesIncludingLease: {
    label: 'VEHICLE_COMMON_EXPENSES-INCLUDE-LEASE_LABEL',
    name: 'expensesIncludingLease',
    type: 'select',
    selectOptions: ['自動車税のみ', '自動車税と重量税', '自動車税と自賠責', '自動車税と重量税と自賠責'],
    tabIndexes: [6]
  },
  factoryInCharge: {
    label: 'VEHICLE_COMMON_ASSIGN-FACTORY_LABEL',
    name: 'factoryInCharge',
    type: 'text',
    maxLength: 255,
    tabIndexes: [31]
  },
  regularMaintenanceCycle: {
    label: 'VEHICLE_COMMON_LEGAL-INSPECTION-CYCLE_LABEL',
    name: 'regularMaintenanceCycle',
    type: 'select',
    selectOptions: ['3ヶ月', '6ヶ月', '12ヶ月'],
    tabIndexes: [21]
  }
}

function RegistrationTab (props) {
  const { onSearchSubmit, defaultValue } = props
  const { register, handleSubmit, formState: { errors }, setError, getValues, setValue, clearErrors, watch } = useForm()
  const [errorFields, setErrorFields] = React.useState([])
  const allowedChars = '0123456789.'

  const onSubmit = data => {
    const body = prepareSearchBody(data)
    onSearchSubmit(body)
  }

  const prepareSearchBody = data => {
    const body = {}

    for (const key of Object.keys(fieldNames)) {
      const fieldName = fieldNames[key].name
      const type = fieldNames[key].type

      switch (type) {
        case 'license':
          if (data[fieldName].length > 0) {
            switch (fieldName) {
              case 'licensePlateNumber':
                body[fieldName] = data[fieldName]
                break
              default:
                body[fieldName] = { ilike: `%${data[fieldName]}%` }
                break
            }
          }
          break
        case 'text':
          if (data[fieldName].length > 0) body[fieldName] = { ilike: `%${data[fieldName]}%` }
          break
        case 'number':
        case 'month':
        case 'date':
          if (data[fieldName].from && data[fieldName].to) {
            body[fieldName] = { between: [data[fieldName].from, data[fieldName].to] }
          } else if (data[fieldName].from && !data[fieldName].to) {
            body[fieldName] = { gte: data[fieldName].from }
          } else if (!data[fieldName].from && data[fieldName].to) {
            body[fieldName] = { lte: data[fieldName].to }
          }
          break
        case 'select':
          if (data[fieldName] !== 'All') body[fieldName] = data[fieldName]
          break
      }
    }

    return body
  }

  const addZeroBeforeDot = (item) => {
    if (getValues(item).length === 1) {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}0`)
    } else {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}`)
    }
  }

  const onNumberBlur = field => () => {
    getValues(`${field}.from`) && addZeroBeforeDot(`${field}.from`)
    getValues(`${field}.to`) && addZeroBeforeDot(`${field}.to`)
    clearErrors([`${field}.from`, `${field}.to`])
    const index = errorFields.indexOf(`${field}.from`)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    const from = getValues(`${field}.from`)
    const to = getValues(`${field}.to`)
    if (parseFloat(from) > parseFloat(to) && !Number.isNaN(parseFloat(from)) && !Number.isNaN(parseFloat(to))) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    }
    if ((from && !/^[0-9.]*$/.test(from)) || (to && !/^[0-9.]*$/.test(to))) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_WARNING-NUMERIC_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    }
  }

  const onDateBlur = field => () => {
    clearErrors([`${field}.from`, `${field}.to`])
    const from = Date.parse(getValues(`${field}.from`))
    const to = Date.parse(getValues(`${field}.to`))
    if (from > to && !Number.isNaN(from) && !Number.isNaN(to)) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    } else {
      const index = errorFields.indexOf(`${field}.from`)
      if (index > -1) {
        errorFields.splice(index, 1)
      }
    }
  }

  const onLicenseBlur = (item) => {
    const index = errorFields.indexOf(item)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    clearErrors(item)
    const licenseNum = getValues(item)
    if (licenseNum && !/^[0-9.]*$/.test(licenseNum)) {
      setErrorFields(prevState => [...prevState, item])
      setError(item, { type: 'manual', message: i18n.t('VEHICLE_VEHICLE-LIST_WARNING-NUMERIC_LABEL') })
    }
  }

  const renderErrorText = field => {
    let errorMessage = ''
    if (errors[field]) {
      errorMessage = errors[field].message || errors[field].from.message
    }
    return errorMessage ? <p data-testid={`${field}-error-text`} className="error-text">{`${errorMessage}`}</p> : ''
  }

  const getDefaultValue = (key, fromTo) => {
    let value = ''
    if (defaultValue[key]) {
      switch (fromTo) {
        case 'from':
          if (key === 'nextRegularMaintenanceMonth') {
            value = dayjs(defaultValue[key].between[0], 'YYYY-MM-DD').format('YYYY-MM')
          } else value = defaultValue[key].between[0]
          break
        case 'to':
          if (key === 'nextRegularMaintenanceMonth') {
            value = dayjs(defaultValue[key].between[1], 'YYYY-MM-DD').format('YYYY-MM')
          } else value = defaultValue[key].between[1]
          break
      }
    }
    return value
  }

  const contains = (stringValue, charValue) => {
    return stringValue.indexOf(charValue) > -1
  }
  const allowTypeNumericOnly = (e) => {
    const invalidKey = (e.key.length === 1 && !contains(allowedChars, e.key)) || (e.key === '.' && contains(e.target.value, '.'))
    return invalidKey && e.preventDefault()
  }

  const renderInput = options => {
    let component = ''
    switch (options.type) {
      case 'select':
        component = (
          <select tabIndex={options.tabIndexes[0]} defaultValue="All" className="input-item" data-testid={options.name} {...register(options.name)}>
            <option key="All" value="All">{i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL')}</option>
            {options.selectOptions.map(item => <option key={item} value={item}>{item}</option>)}
          </select>
        )
        break
      case 'text':
        component = (
          <input
            className="input-item"
            type={options.type}
            tabIndex={options.tabIndexes[0]}
            maxLength={options.maxLength}
            data-testid={options.name}
            {...register(options.name)} />
        )
        break
      case 'date':
      case 'month':
      case 'number':
        component = (
          <>
            <input
              {...!integerNumberOnly.includes(options.name) && { step: 'any' }}
              onKeyDown={(e) => allowTypeNumericOnly(e)}
              className="input-item"
              type={options.type === 'number' ? 'text' : options.type}
              tabIndex={options.tabIndexes[0]}
              placeholder="From"
              {...(options.type === 'date' && { min: '1890-01-01' })}
              {...(options.type !== 'month' && { max: watch(`${options.name}.to`) || '9999-12-31' })}
              {...(options.type === 'month' && { max: watch(`${options.name}.to`) || '9999-12' })}
              // max={getValues(`${options.name}.to`) || '9999-12-31'}
              defaultValue={options.type !== 'number' ? getDefaultValue(options.name, 'from') : ''}
              data-testid={`${options.name}.from`}
              {...register(`${options.name}.from`)} />
            <input
              {...!integerNumberOnly.includes(options.name) && { step: 'any' }}
              onKeyDown={(e) => allowTypeNumericOnly(e)}
              className="input-item"
              type={options.type === 'number' ? 'text' : options.type}
              tabIndex={options.tabIndexes[1]}
              placeholder="To"
              min={getValues(`${options.name}.from`) || '1890-01-01'}
              {...(options.type === 'date' && { max: '9999-12-31' })}
              {...(options.type === 'month' && { max: '9999-12' })}
              defaultValue={options.type !== 'number' ? getDefaultValue(options.name, 'to') : ''}
              data-testid={`${options.name}.to`}
              {...register(`${options.name}.to`)} />
          </>
        )
        break
    }
    return component
  }

  const renderInputGroup = options => {
    let onBlur = null
    if (options.type === 'date' || options.type === 'month') onBlur = onDateBlur(options.name)
    if (options.type === 'number') onBlur = onNumberBlur(options.name)

    return (
      <>
        <div className="label-text"><label>{i18n.t(options.label)}</label></div>
        <div>
          <div data-testid={`${options.name}-input-group`} className="input-group" onBlur={onBlur}>{renderInput(options)}</div>
          {renderErrorText(options.name)}
        </div>
      </>
    )
  }

  return (
    <FilterTabWrapper>
      <form className="form-container" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="filter-container">
          <div className="input-container">
            <div className="label-text">
              <label>{i18n.t('VEHICLE_COMMON_LICENSE-PLATE_LABEL')}</label>
            </div>
            <div>
              <div className="input-group" data-testid={'licensePlate-input-group'} onBlur={(e) => onLicenseBlur(fieldNames.licenseNumber.name)}>
                <input
                  className="input-item"
                  type="text"
                  maxLength={255}
                  data-testid={`${fieldNames.licenseAddress.name}`}
                  placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-ADDRESS_LABEL')}
                  {...register(fieldNames.licenseAddress.name)}
                  tabIndex={1} />
                <input
                  className="input-item"
                  type="text"
                  maxLength={255}
                  data-testid={`${fieldNames.licenseClassNo.name}`}
                  placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-CLASS-NO_LABEL')}
                  {...register(fieldNames.licenseClassNo.name)}
                  tabIndex={2} />
                <input
                  onKeyDown={(e) => { (e.key.length === 1 && !contains('0123456789', e.key)) && e.preventDefault() }}
                  className="input-item"
                  type="text"
                  maxLength={4}
                  data-testid={`${fieldNames.licenseNumber.name}`}
                  placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-NUMBER_LABEL')}
                  {...register(fieldNames.licenseNumber.name)}
                  tabIndex={3} />
              </div>
              {renderErrorText(fieldNames.licenseNumber.name)}
            </div>
            {renderInputGroup(fieldNames.expensesIncludingLease)}
            {renderInputGroup(fieldNames.automobileTax)}
            {renderInputGroup(fieldNames.mileage)}
            {renderInputGroup(fieldNames.nextRegularMaintenance)}
            {renderInputGroup(fieldNames.mandatoryInsuranceEnd)}
            {renderInputGroup(fieldNames.factoryInCharge)}
          </div>
          <div className="input-container">
            {renderInputGroup(fieldNames.owner)}
            {renderInputGroup(fieldNames.leaseEndDate)}
            {renderInputGroup(fieldNames.weightTax)}
            {renderInputGroup(fieldNames.lastRegularMaintenance)}
            {renderInputGroup(fieldNames.usage)}
            {renderInputGroup(fieldNames.voluntaryInsuranceType)}
            {renderInputGroup(fieldNames.specialVehiclePassExpire)}

          </div>
          <div className="input-container">
            {renderInputGroup(fieldNames.lease)}
            {renderInputGroup(fieldNames.leaseFee)}
            {renderInputGroup(fieldNames.inspectionExpireDate)}
            {renderInputGroup(fieldNames.regularMaintenanceCycle)}
            {renderInputGroup(fieldNames.privateBusinessUse)}
            {renderInputGroup(fieldNames.voluntaryInsuranceEndDate)}

          </div>
        </div>
        <div className="submit-container">
          <SearchButton data-testid="submit-button" tabIndex={34} type="submit" {...errorFields.length > 0 && { disabled: true }}/>
        </div>
      </form>
    </FilterTabWrapper>
  )
}

export default RegistrationTab

RegistrationTab.propTypes = {
  onSearchSubmit: PropTypes.func,
  defaultValue: PropTypes.object
}
