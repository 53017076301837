import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import MainRoute from './containers'
import { store } from './app/store'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from 'styled-components'
import GlobalStyle, { GlobAttr } from './globalStyles'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en/locale-en'
import ja from './locales/ja/locale-ja'
import { interceptor } from './tools'
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
      ja: {
        translation: ja
      }
    },
    fallbackLng: 'ja',
    interpolation: {
      escapeValue: false
    }
  })
// For POST REQUEST 401 ERROR CHECK
interceptor()

// This function is temporary use for testing purpose.
// Please don't forget to remove before production.
/* istanbul ignore next */
i18n.changeLanguage('ja')
// const changeLanguage = (lng) => {
//   i18n.changeLanguage(lng)
// }

ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={GlobAttr}>
        {/* <div className="language-container">
          <button onClick={() => changeLanguage('ja')}>JA</button>
          <button className="language-en" onClick={() => changeLanguage('en')}>EN</button>
        </div> */}
        <GlobalStyle />
        <MainRoute />
      </ThemeProvider>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
