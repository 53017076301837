import styled from 'styled-components'

export const LoginWrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  .login-box {
    padding: 48px 36px;
    background: #808080a3;
    border-radius: 6px;
    text-align: center;
    color: white;
    width: 340px;
    form {
      display: flex;
      justify-content: center;
    }
  }
`
