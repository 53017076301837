
export const userColumn = {
  email: 'USER-MANAGEMENT_USER-MANAGEMENT_USER-ID_LABEL',
  company: 'COMMON_COMMON_COMPANY-DROPDOWN_LABEL',
  branch: 'COMMON_COMMON_BRANCH-DROPDOWN_LABEL',
  isEdit: 'USER-MANAGEMENT_USER-MANAGEMENT_EDIT_LABEL',
  isAdmin: 'USER-MANAGEMENT_USER-MANAGEMENT_ADMIN_LABEL',
  buttons: ''
}

export const dialogMessageList = [
  { type: 'info', code: 'add', message: 'USER-MANAGEMENT_USER-MANAGEMENT_POPUP-HEADER-ADD-USER_LABEL' },
  { type: 'info', code: 'edit', message: 'USER-MANAGEMENT_USER-MANAGEMENT_POPUP-HEADER-EDIT-PRIVILEGE_LABEL' },
  { type: 'info', code: 'delete', message: 'USER-MANAGEMENT_USER-MANAGEMENT_POPUP-DELETE_LABEL' },
  { type: 'info', code: 'cancel', message: 'USER-MANAGEMENT_USER-MANAGEMENT_POPUP-CANCEL_LABEL' },
  { type: 'warning', code: 'id_exist', message: 'USER-MANAGEMENT_USER-MANAGEMENT_POPUP-WARNING2_LABEL' },
  { type: 'warning', code: 'self_delete', message: 'USER-MANAGEMENT_USER-MANAGEMENT_POPUP-WARNING1_LABEL' },
  { type: 'warning', code: 'unknown', message: 'COMMON_UNKNOWN-ERROR_MESSAGE_LABEL' }
]

export const defaultAddValue = {
  email: '',
  company: '',
  organizationId: '',
  isEdit: '',
  isAdmin: ''
}
