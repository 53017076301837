import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { PrivateRoute, RedirectPage } from '../tools'
import Login from './Login'
import VehicleManagement from './VehicleManagement'
import ContractManagement from './ContractManagement'
import VehicleReport from './VehicleReport'
import { useSelector, useDispatch } from 'react-redux'
import VehicleDetail from './VehicleManagement/VehicleDetail'
import EditVehicle from './VehicleManagement/EditVehicle'
import { userInfo } from './../features/users/userInfoSlice'
import PageNotFound from './PageNotFound'
import UserManagement from './UserManagement'
import DropDownManagement from './DropDownManagement'
import EditDropDown from './DropDownManagement/EditDropDown'
import { withTranslation } from 'react-i18next'

function initUid () {
  if (!localStorage.getItem('uid')) {
    localStorage.setItem('uid', uuidv4())
  }
}

function MainRoute () {
  initUid()
  const dispatch = useDispatch()
  const [userPage, setUserPage] = React.useState('')
  const [vehicleCreatePage, setVehicleCreatePage] = React.useState('')
  const [vehicleEditPage, setVehicleEditPage] = React.useState('')
  const [pageNotFound, setPageNotFound] = React.useState('')
  const [dropDwonPage, setDropDwonPage] = React.useState('')
  const [editDropDwonPage, setEditDropDwonPage] = React.useState('')
  const userState = useSelector(state => state.userInfo)
  React.useEffect(() => {
    if ((localStorage.getItem('token'))) {
      dispatch(userInfo())
    }
  }, [])

  React.useEffect(() => {
    if (!(localStorage.getItem('token'))) {
      const currentPath = window.location.pathname
      if (currentPath !== '/login') {
        window.location.href = '/login'
      }
    }
    if (!userState.email) {
      return
    }
    userState.isAdmin && setUserPage(<PrivateRoute path="/usermanagement" component={UserManagement} exact />)
    userState.isEdit && setVehicleCreatePage(<PrivateRoute path="/vehicle/create" component={EditVehicle} exact />)
    userState.isEdit && setVehicleEditPage(<PrivateRoute path="/editinfo/:id/edit" component={EditVehicle} exact />)
    userState.isAdmin && setDropDwonPage(<PrivateRoute path="/dropdownmanagement" component={DropDownManagement} exact />)
    userState.isAdmin && setEditDropDwonPage(<PrivateRoute path="/dropdownmanagement/edit/:id" component={EditDropDown} exact />)
    setPageNotFound(<Route path="*" component={PageNotFound} />)
  }, [userState])

  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/redirect" component={RedirectPage} />
        <PrivateRoute path="/" component={VehicleManagement} exact />
        <PrivateRoute path="/vehicle" component={VehicleManagement} exact />
        <PrivateRoute path="/contract" component={ContractManagement} exact />
        <PrivateRoute path="/report" component={VehicleReport} exact />
        <PrivateRoute path="/viewinfo/:id" component={VehicleDetail} exact />
        {userPage}
        {vehicleEditPage}
        {vehicleCreatePage}
        {dropDwonPage}
        {editDropDwonPage}
        {pageNotFound}
    </Switch>
    </Router>
  )
}

export default withTranslation()(MainRoute)
