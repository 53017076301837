import styled from 'styled-components'
export const ValueGroupWrapper = styled.div`
  .value-contain {
    display: grid;
    grid-template: 1fr / 3fr 2fr;
    grid-gap: 5px;
    font-size: 14px;
    line-height: 2;
    .value {
      position: relative;
      display: grid;
      grid-template: 1fr / 1fr;
      position: relative;
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      // :hover .tooltip-wrapper {
      //   display: block;
      // }

      .tooltip-wrapper {
        position: absolute;
        display: none;
        bottom: 100%;
        right: 260px;
        width: 0;

        .tooltiptext {
          display: block;
          position: relative;
          right: 100%;
          width: 250px;
          color: #FFFFFF;
          background: #555;
          text-align: center;
          border-radius: 8px;
          z-index: 1;
          word-wrap: break-word;
          white-space: normal;
          padding: 6px;
        }
      }
    }

  }
  .content {
    padding: 0px 12px;
    ${props => props.column
? `
      display: grid;
      column-gap: 10px;
      grid-template-columns: repeat(${props.column}, 1fr);
      grid-template-rows: repeat(${(props.column - 1) * (props.row || 9)}, 1fr);
      grid-auto-flow: column;
    `
: ''}
  }
`
