import styled from 'styled-components'
export const EditDropDownWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  section.header {
    padding: 12px;
    display: flex;
    .header-actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: normal;
      gap: 10px;
    }
    .header-title {
      flex: 1;
      font-size: 24px;
    }
  }
  section.main {
    padding: 12px;
    padding-left: 3%;
    height: calc(100% - 85px);
    .main-title {
      flex: 1;
      font-size: 24px;
      border-bottom: 2px solid #CCC;
    }
    .main-table {
      width: 100%;
      height: 100%;
      display: block;
      overflow:auto;
      table-layout: fixed;
    }
    .table-tbody{
      width: 100%;
      display: table;
    }
    .table-thead-tr{
      height: 50px;
      font-size: 20px;
      text-align: center;
      color: #FFF;
    }
    .table-thead-tr th{
      position: sticky;
      top: 0;
      left: 0;
      background: #AAA;
    }
    .table-tbody-tr{
      font-size: 20px;
      text-align: center;
      background: #EEE;
    }
    .table-tbody-tr:nth-child(odd){
      background-color: #CCC;
    }
    .table-tbody-tr tb{
      
    }
    .input-text{
      width: 95%;
      font-size: 20px;
      border-radius: 5px;
      margin: 0.3em 0em 0.3em 0em;
    }
    .input-number{
      width: 90%;
      font-size: 20px;
      border-radius: 5px;
      margin: 0.3em 0em 0.3em 0em;
    }
    .input-checkbox{
      inline-size: 1.5rem;
      block-size: 1.5rem;
    }
    .button{
      border: 0px;
      background:rgba(0,0,0,0)
    }
  }
  p.error-text {
    margin: 0 0 0 0;
    font-size: 20px;
    color: red;
    text-align: left;
  }
  p.error-text-row {
    margin: 0 0 0 0;
    font-size: 12px;
    color: red;
    text-align: left;
    padding-left: 3%;
  }

  .modal-box {
    position: relative;
    width: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .modal-message-content {
    min-width: 300px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .modal-message-button {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 30px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
