import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { QuarterWrapper } from './styles'
dayjs.extend(quarterOfYear)

function QuarterChart ({ currentYear, data = [], onSelectQuarter, initialFromDate, selectedQuarter }) {
  const [activePeriod, setActivePeriod] = useState({ year: '', quarter: '' })

  useEffect(() => {
    setActivePeriod({
      year: dayjs(selectedQuarter).format('YYYY'),
      quarter: `${dayjs(selectedQuarter).quarter()}` // `${dayjs(initialFromDate).quarter()}`
    })
  }, [])

  const headerMapping = {
    1: 'CONTRACT_CONTRACT-MANAGEMENT_QUARTER-1ST_LABEL',
    2: 'CONTRACT_CONTRACT-MANAGEMENT_QUARTER-2ND_LABEL',
    3: 'CONTRACT_CONTRACT-MANAGEMENT_QUARTER-3RD_LABEL',
    4: 'CONTRACT_CONTRACT-MANAGEMENT_QUARTER-4TH_LABEL'
  }

  const labelMapping = {
    inspection: 'CONTRACT_CONTRACT-MANAGEMENT_INSPECTION_LABEL',
    regularMaintenance: 'CONTRACT_CONTRACT-MANAGEMENT_REGULAR-MAINTENACE_LABEL',
    specialVehiclePass: 'CONTRACT_CONTRACT-MANAGEMENT_SPECIAL-VEHICLE-PASS_LABEL',
    lease: 'CONTRACT_CONTRACT-MANAGEMENT_LEASE_LABEL',
    mandatoryInsurance: 'CONTRACT_CONTRACT-MANAGEMENT_MANDATORY-INSURANCE_LABEL',
    voluntaryInsurance: 'CONTRACT_CONTRACT-MANAGEMENT_VOLUNTARY-INSURANCE_LABEL'
  }

  const buildDatePeriod = quarter => {
    const year = currentYear || dayjs().format('YYYY')
    const from = dayjs().year(year).quarter(quarter).startOf('quarter').format('YYYY-MM-DD')
    const to = dayjs().year(year).quarter(quarter).endOf('quarter').format('YYYY-MM-DD')
    return { from, to }
  }

  const onPeriodClick = quarter => () => {
    setActivePeriod({ year: currentYear, quarter })
    onSelectQuarter(buildDatePeriod(quarter))
  }

  const isActivePeriod = quarter => {
    return (activePeriod.year === currentYear) && (activePeriod.quarter === quarter)
  }

  const getQuarterData = quarter => {
    const quarterData = {}
    data.forEach(item => {
      if (dayjs(`${item.period}`, 'M').quarter() === parseInt(quarter)) {
        Object.keys(labelMapping).forEach(label => {
          if (quarterData[label]) {
            quarterData[label] = quarterData[label] + (parseInt(item.data[label]) || 0)
          } else quarterData[label] = parseInt(item.data[label]) || 0
        })
      }
    })
    return quarterData
  }

  const renderItem = (quarter, data) => {
    return Object.entries(labelMapping).map(([key, value], index) => (
      <div key={index} className={`item ${key}`}>
        <label data-testid={`${quarter}-${key}-text`} className="text">{i18n.t(value)}</label>
        <label data-testid={`${quarter}-${key}-number`} className="number">{isFinite(data[key]) ? Intl.NumberFormat().format(data[key]) : ''}</label>
      </div>
    ))
  }

  const renderChart = () => {
    return Object.entries(headerMapping).map(([quarter, value], index) => (
      <div data-testid={`period-${quarter}`} key={quarter} className={`chart-item ${isActivePeriod(quarter) ? 'active' : ''}`} onClick={onPeriodClick(quarter)}>
        <div className="chart-header">
          <p data-testid={`period-${quarter}-label`} className="chart-label">{i18n.t(value)}</p>
        </div>
        <div className="chart-body">
          {renderItem(quarter, getQuarterData(quarter))}
        </div>
      </div>
    ))
  }

  return (
    <QuarterWrapper>
      <div className="chart-container" data-testid="quarterly-view">
        {renderChart()}
      </div>
    </QuarterWrapper>
  )
}

export default QuarterChart

QuarterChart.propTypes = {
  data: PropTypes.array,
  currentYear: PropTypes.string,
  onSelectQuarter: PropTypes.func,
  initialFromDate: PropTypes.string,
  selectedQuarter: PropTypes.string
}
