import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { oganizationListApi } from './organizationAPI'
const initialState = {
  list: [],
  restructureData: {},
  itemCount: 1
}

export const getOrganizationList = createAsyncThunk(
  'organization/list',
  async () => {
    const response = await oganizationListApi()
    return response.data
  }
)

const restructureOrganization = (rows) => {
  const result = {}
  for (const element of rows) {
    if (!(element.company in result)) {
      result[element.company] = {}
    }
    if (!(element.department in result[element.company])) {
      result[
        element.company][
        element.department] = {}
    }
    if (!(element.businessUnit in result[element.company][
      element.department])) {
      result[
        element.company][
        element.department][
        element.businessUnit] = {}
    }
    if (!(element.branchOffice in result[element.company][
      element.department][
      element.businessUnit])) {
      result[
        element.company][
        element.department][
        element.businessUnit][
        element.branchOffice] = {}
    }
    result[
      element.company][
      element.department][
      element.businessUnit][
      element.branchOffice][
      element.branch] = element
  }
  return result
}

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
  },
  extraReducers: {
    [getOrganizationList.fulfilled]: (state, { payload }) => {
      state.list = payload.rows
      state.restructureData = restructureOrganization(payload.rows)
      state.itemCount = payload.count
      return state
    },
    [getOrganizationList.rejected]: (state, { payload }) => {
    },
    [getOrganizationList.pending]: (state) => {
      state.list = []
      state.restructureData = {}
      state.itemCount = 1
    }
  }
})

export default organizationSlice.reducer
