import styled from 'styled-components'

export const ImportConsumptionWrap = styled.div`
  button {
    height: 36px;
    padding: 0.50rem 1rem;
  }
  section.confirm-modal {
    padding: 16px;
    max-width: 400px;
    min-height: 150px;
    min-width: 320px;
    display: flex;
    flex-direction: column;
  }
  .flex-1 {
    flex: 1;
    flex-direction: column;
    display: flex;
  }
  .bottom {
    margin: auto;
    display: flex;
    button {
      margin: 0px 16px;
    }
  }
`
