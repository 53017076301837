import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { importOrganizationApi } from './importOrganizationAPI'
const initialState = {
  check: {
    load: false,
    error: {},
    success: {}
  },
  import: {
    load: false,
    data: {}
  }
}

export const importOrganization = createAsyncThunk(
  'organization/import',
  async (props, { rejectWithValue }) => {
    try {
      const response = await importOrganizationApi({ ...props, exec: true })
      return response.data
    } catch (err) {
      if (err.response.status >= 500) {
        return rejectWithValue({ error: { unknown: true } })
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const checkOrganization = createAsyncThunk(
  'organization/import-check',
  async (props, { rejectWithValue }) => {
    try {
      const response = await importOrganizationApi({ ...props, exec: false })
      // console.log('RES', response);
      return response.data
    } catch (err) {
      if (err.response.status >= 500) {
        return rejectWithValue({ error: { unknown: true } })
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const importOrganizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    clearState: (state) => {
      state.check.load = false
      state.check.error = {}
      state.check.success = {}
      state.import.data = {}
      return state
    }
  },
  extraReducers: {
    [checkOrganization.fulfilled]: (state, { payload }) => {
      state.check.load = false
      state.check.success = payload
      return state
    },
    [checkOrganization.rejected]: (state, { payload }) => {
      state.check.load = false
      state.check.error = payload.error
    },
    [checkOrganization.pending]: (state) => {
      state.check.load = true
      state.check.success = {}
      state.check.error = {}
      state.import.data = {}
    },
    [importOrganization.fulfilled]: (state, { payload }) => {
      state.import.load = false
      state.import.data = payload
      return state
    },
    [importOrganization.rejected]: (state, { payload }) => {
      state.import.load = false
      state.check.error = payload.error
    },
    [importOrganization.pending]: (state) => {
      state.import.load = true
      state.check.success = {}
      state.import.data = {}
    }
  }
})

export const { clearState } = importOrganizationSlice.actions
export default importOrganizationSlice.reducer
