
export const fieldNames = {
  licenseAddress: { name: 'licensePlateAddress', type: 'string' },
  licenseClassNo: { name: 'licensePlateClass', type: 'string' },
  licenseNumber: { name: 'licensePlateNumber', type: 'string' }
}

export const advanceSearchOptions = [
  // { value: 'engineNumber', label: 'VEHICLE_COMMON_ENGINE-NUMBER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'body', label: 'VEHICLE_COMMON_BODY_LABEL', type: 'dropdown', maxLength: '', options: ['ドライバン', '保温車', '冷蔵車', '冷凍車', '冷蔵冷凍（2層式）', '平ボディ', 'バルク車', 'パッカー車（プレス）', 'パッカー車（回転式）', 'その他（上記以外）'], selected: false },
  { value: 'bodyManufacturer', label: 'VEHICLE_COMMON_BODY-MANUFACTURER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'wing', label: 'VEHICLE_COMMON_WING_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'powerGate', label: 'VEHICLE_COMMON_POWER-GATE_LABEL', type: 'dropdown', maxLength: '', options: ['はね上', '格納', '垂直', 'なし'], selected: false },
  { value: 'powerManufacturer', label: 'VEHICLE_COMMON_POWER-MANUFACTURER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'powerGateLift', label: 'VEHICLE_COMMON_POWER-GATE-LIFT_LABEL', type: 'number - fromto', maxLength: '10', selected: false },
  { value: 'crane', label: 'VEHICLE_COMMON_CRANE_LABEL', type: 'dropdown', maxLength: '255', options: ['あり', 'なし'], selected: false },
  { value: 'craneManufacturer', label: 'VEHICLE_COMMON_CRANE-MANUFACTURER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'craneLift', label: 'VEHICLE_COMMON_CRANE-LIFT_LABEL', type: 'number - fromto', maxLength: '10', selected: false },
  { value: 'withJorder', label: 'VEHICLE_COMMON_WITH-OR-WITHOUT-JORDER_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'airSuspension', label: 'VEHICLE_COMMON_AIR-SUSPENSION_LABEL', type: 'dropdown', maxLength: '', options: ['総輪', '後輪', 'なし'], selected: false },
  { value: 'floorMaterial', label: 'VEHICLE_COMMON_FLOOR-MATERIAL_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'floorWork', label: 'VEHICLE_COMMON_FLOOR-WORK_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'fuelTank', label: 'VEHICLE_COMMON_FUEL-TANK_LABEL', type: 'number - fromto', maxLength: '10', selected: false },
  { value: 'refrigerator', label: 'VEHICLE_COMMON_REFRIGERATOR_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'refrigeratorManufacturer', label: 'VEHICLE_COMMON_REFRIGERATOR-MANUFACTURER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'refrigeratorModel', label: 'VEHICLE_COMMON_REFRIGERATOR-MODEL_LABEL', type: 'dropdown', maxLength: '', required: false, options: ['直結型', 'サブエンジン型'] },
  { value: 'inVehicleFreezingEquipment', label: 'VEHICLE_COMMON_IN-VEHICLE-FREEZING-EQUIPMENT_LABEL', type: 'dropdown', maxLength: '', options: ['7.5KW未満', '7.5KW以上', 'なし'], selected: false },
  { value: 'temperatureRecord', label: 'VEHICLE_COMMON_TEMPERATURE-RECORD_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'standBy', label: 'VEHICLE_COMMON_STAND-BY_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'etcTwo', label: 'VEHICLE_COMMON_ETC_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'etcOnBoardUnitId', label: 'VEHICLE_COMMON_ON-BOARD-UNIT-ID_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'etcCorporateCardNumber', label: 'VEHICLE_COMMON_ETC-CORPORATE-CARD-NUMBER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'fuelCardNo', label: 'VEHICLE_COMMON_FUEL-CARD-NO_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'adBlue', label: 'VEHICLE_COMMON_ADBLUE_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'dashcam', label: 'VEHICLE_COMMON_DASHCAM_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'dashcamManufacturer', label: 'VEHICLE_COMMON_DASHCAM-MANUFACTURER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'withBackEyeCamera', label: 'VEHICLE_COMMON_WITH-OR-WITHOUT-BACK-EYE-CAMERA_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'backEyeCameraManufacturer', label: 'VEHICLE_COMMON_BACK-EYE-CAMERA-MANUFACTURER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'cornerSensor', label: 'VEHICLE_COMMON_CORNER-SENSOR_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'cornerSensorManufacturer', label: 'VEHICLE_COMMON_CORNER-SENSOR-MANUFACTURER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'digiTacho', label: 'VEHICLE_COMMON_DIGI-TACHO_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'digiTachoManufacture', label: 'VEHICLE_COMMON_DIGI-TACHO-MANUFACTURER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'digiTachoUniqueCode', label: 'VEHICLE_COMMON_DIGI-TACHO-UNIQUE-CODE_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'remarks', label: 'VEHICLE_COMMON_REMARKS_LABEL', type: 'text', maxLength: '255', selected: false }
  // according the design file
  // { value: 'modelDesignationNumber', label: 'VEHICLE_COMMON_MODEL-DESIGNATION-NUMBER_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'classificationCategoryNumber', label: 'VEHICLE_COMMON_CLASSIFICATION-CATEGORY-NUMBER_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'transmission', label: 'VEHICLE_COMMON_TRANSMISSION_LABEL', type: 'dropdown', maxLength: '', options: ['AT', 'MT'], selected: false },
  // { value: 'tireManufacturer', label: 'VEHICLE_COMMON_TIRE-MANUFACTURER_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'snowTire', label: 'VEHICLE_COMMON_SNOW-TIRE_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  // { value: 'spareTire', label: 'VEHICLE_COMMON_SPARE-TIRE_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  // // { value: 'body', label: 'VEHICLE_COMMON_BODY_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'rLiftType', label: 'VEHICLE_COMMON_R-LIFT-TYPE_LABEL', type: 'number - fromto', maxLength: '10', selected: false },
  // { value: 'aoriHeight', label: 'VEHICLE_COMMON_AORI-HEIGHT_LABEL', type: 'number - fromto', maxLength: '10', selected: false },
  // { value: 'lashingRailPosition', label: 'VEHICLE_COMMON_LASHING-RAIL-POSITION_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'factoryInCharge', label: 'VEHICLE_COMMON_FACTORY-IN-CHARGE_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'color', label: 'VEHICLE_COMMON_COLOR_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'cabinColor', label: 'VEHICLE_COMMON_CABIN-COLOR_LABEL', type: 'dropdown', maxLength: '', options: ['ドリームカラー（ライン塗装）', 'ドリームカラー（後塗り）', 'その他'], selected: false },
  // { value: 'safixFifthWheelLoad', label: 'VEHICLE_COMMON_SAFIX-FIFTH-WHEEL-LOAD_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'paintColor', label: 'VEHICLE_COMMON_PAINT-COLOR_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'batteryType', label: 'VEHICLE_COMMON_BATTERY-TYPE_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'batteryReplacementDate', label: 'VEHICLE_COMMON_BATTERY-REPLACEMENT-DATE_LABEL', type: 'date - fromto', maxLength: '', selected: false },
  // { value: 'accessories', label: 'VEHICLE_COMMON_ACCESSORIES_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'rigType', label: 'VEHICLE_COMMON_RIG-TYPE_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'containerManufacturer', label: 'VEHICLE_COMMON_CONTAINER-MANUFACTURER_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'specialEquipment', label: 'VEHICLE_COMMON_SPECIAL-EQUIPMENT_LABEL', type: 'text', maxLength: '255', selected: false },
  // { value: 'directConnectionType', label: 'VEHICLE_COMMON_DIRECT-CONNECTION-TYPE_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  // { value: 'fuelCard', label: 'VEHICLE_COMMON_FUEL-CARD_LABEL', type: 'text', maxLength: '255', selected: false },
]

export const integerNumberOnly = ['seatingCapacity', 'licensePlateNumber']

export default advanceSearchOptions
