import styled from 'styled-components'
export const DropDownWrapper = styled.div`
  height: 100vh;
  display: ${props => props.show ? 'none' : 'flex'};
  flex-direction: column;
  section.header {
    padding: 12px;
    display: flex;
    .header-title {
      flex: 1;
      font-size: 24px;
    }
  }
  section.main {
    padding: 12px;
    padding-left: 3%;
    height: calc(100% - 85px);
    .main-title {
      flex: 1;
      font-size: 24px;
      border-bottom: 2px solid #CCC;
    }
    .main-list-header{
      padding: 0;
      margin-bottom: 6px;
      width: 100%;
      list-style: none;
    }
    .main-list-header li{
      padding: 0.5em 0em 0.5em 1em;
      font-size: 24px;
      color: #FFF;
      background: #AAA;
      cursor: default;
    }
    .main-list{
      padding: 0;
      margin: 0;
      width: 100%;
      list-style: none;
      overflow:auto;
      height: calc(100% - 120px);
    }
    .main-list li{
      padding: 0.5em 0em 0.5em 1em;
      margin-bottom: 2px;
      font-size: 24px;
      color: #000;
      background: #CCC;
      cursor: pointer;
    }
    .main-list li:nth-child(odd) {
      background-color: #EEE;
    }
  }
  .loading-modal {
    padding: 0;
    .modal-box {
      padding: 10px;
    }
  }
`
