import styled from 'styled-components'

export const ContractMonthlyWrapper = styled.div`
  display: grid;
  grid-template: 1fr 1fr 1fr/1fr 1fr 1fr 1fr;
  .active {
    ::after {
      content: '';
      width: 100%;
      height: 100%;
      border: 4px solid ${props => props.theme.primeColor};
      position: absolute;
      top: -4px;
      border-radius: 3px;
      left: -4px;
      z-index: 1;
      background: rgb(185 16 61 / 17%);
    }
  }
  .month-card {
    border: 1px solid #dadada;
    position: relative;
    .header-contain {
      font-weight: bold;
      font-size: 16px;
      background: #f1f1f1;
      text-align: center;
      padding: 12px;
    }
    .value-contain {
      display: grid;
      grid-template: 1fr/ 1fr 1fr;
      color: #fff;
      text-align: center;
      grid-gap: 8px 16px;
      padding: 18px 12px 28px 12px;
      .value-box {
        font-size: 16px;
        border-radius: 5px;
        min-height: 18px;
        padding: 2px 0px;
      }
      #inspection{
        background: rgb(168 0 47);
      }
      #lease {
        background: rgb(23 86 99);
      }
      #regular-maintenance {
        background: rgb(230 134 56);
      }
      #mandatory-insurance {
        background: rgb(55 173 78);
      }
      #special-vehicle-pass  {
        background: rgb(36 36 36);
      }
      #voluntary-insurance {
        background: rgb(50 66 168);
      }
    }
  }
`
