import React from 'react'
import PropTypes from 'prop-types'
import { PdfThumbnailWrapper } from './styles'
import documentImg from '../../assets/images/document-placeholder.svg'
function PdfThumbnail (props) {
  const [contentType, setContentType] = React.useState('application/pdf')
  const { file, title } = props
  React.useEffect(async () => {
    const v = file && await fetch(file, {
      credentials: 'include'
    })
    file && setContentType(v.headers.get('Content-Type'))
  }, [file])

  const openDoc = e => {
    // const pdfWindow = window.open('')
    // pdfWindow.document.write(
    //   "<iframe width='100%' height='100%' src='" + file + "'></iframe>"
    // )
    file && window.open(file, '_blank')
  }
  const classname = !file ? 'no-cursor' : 'has-cursor'
  return (
    <PdfThumbnailWrapper data-testid="pdf-thumbnail" className={`pdf-wrapper ${classname}`} id="doc1" onClick={openDoc}>
      {file
        ? contentType === 'application/pdf' ? <object type={file && contentType} style={{ marginBottom: '12px' }} data={file}/> : <img src={file} alt="" style={{ width: '100px', marginBottom: '12px' }} />
        : <img src={documentImg} alt="" style={{ width: '60px', marginBottom: '12px' }} />}
      {title && <span>{title}</span>}
    </PdfThumbnailWrapper>
  )
}

export default PdfThumbnail
PdfThumbnail.propTypes = {
  file: PropTypes.any,
  title: PropTypes.string
}
