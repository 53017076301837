import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { CommonButton } from './Button'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import i18n from 'i18next'

const YearClickWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  button {
    height: 36px;
    padding: 7px;
    min-width: 27px;
  }
  div {
    min-width: 150px;
    text-align: center;
  }
`
function YearClick (props) {
  const [currentDate, setDate] = React.useState(dayjs())

  React.useEffect(() => {
    setDate(dayjs().set('year', props.year))
  }, [props.year])

  const handleChange = (action) => {
    const date = dayjs(currentDate)
    let dateChange = date
    switch (action) {
      case 'prev':
        dateChange = date.subtract(1, 'years')
        break
      case 'next':
        dateChange = date.add(1, 'years')
        break
    }
    setDate(dateChange)
    if (props.onChange) { props.onChange(dateChange.format('YYYY')) }
  }
  return (
    <YearClickWrapper>
      <CommonButton data-testid="prev_year" id="prev" onClick={() => handleChange('prev')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </CommonButton>
      <div data-testid="current_year">{currentDate.format('YYYY')} {i18n.t('COMMON_COMMON_YEAR_LABEL')}</div>
      <CommonButton data-testid="next_year" id="next" onClick={() => handleChange('next')}>
        <FontAwesomeIcon icon={faChevronRight} />
      </CommonButton>
    </YearClickWrapper>
  )
}

export default YearClick

YearClick.propTypes = {
  onChange: PropTypes.func,
  year: PropTypes.string
}
