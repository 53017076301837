import styled from 'styled-components'

export const ContractManagementWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  section.header {
    padding: 12px;
    display: flex;
    .header-actions {
    }
    .header-title {
      flex: 1;
      font-size: 24px;
    }
    .header-actions {
      padding-bottom: 24px;
      padding-top: 24px;
      border-radius: 5px;
      border: 1px solid #ccc;
      display: flex;
      flex: 1;
      margin: auto;
      .action-options {
        flex-direction: column;
        display: flex;
        margin-left: 24px;
        height: 60px;
        flex: 1;
        label {
          flex: 1;
          max-width: 90px;
        }
        select, input.userid {
          border: 1px solid darkgrey;
          border-radius: 6px;
          height: 32px;
          font-size: 14px;
        }
      }
    }
  }
  .submit-container {
    margin-top: auto;
    margin-bottom: auto;
  }
  button.contract-button {
    height: 36px;
    padding: 4px 24px;
  }
  section.content {
    display: grid;
    grid-template: 1fr/5fr 4fr;
    flex: 1;
    margin: 12px;
    margin-bottom: 16px;
    grid-gap: 24px;
    h3 {
      margin-top: 0px;
      margin-bottom: 16px;
      height: 36px;
    }
    .display-month-type-wrapper {
      padding: 24px 0;
    }
    .filter-scope {
      display: flex;
      gap: 12px;
    }
    .input-date-wrapper {
      font-size: 14px;
      display: flex;
      .label-container {
        display: flex;
        max-height: 36px;
        align-items: center;
      }
      input[type=date] {
        border: none;
        border:solid 1px #757575;
        border-radius: 5px;
        width: 110px;
        height: 36px;
        margin: 0px 12px;
        font-size: 14px;
        letter-spacing: -1px;
        ::-webkit-calendar-picker-indicator {
            padding: 2px;
            margin: auto;
        }
      }
      p.error-text {
        margin: 0 0 0 10px;
        font-size: 12px;
        color: red;
      }
    }
  }
`
