import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { PaginationWrapper } from './styles.js'

function Pagination ({ page, count, onChange }) {
  const [disablePrev, setDisablePrev] = useState(true)
  const [disableNext, setDisableNext] = useState(true)

  useEffect(() => {
    setDisablePrev(page <= 1 || count < 1)
    setDisableNext(page >= count || count < 1)
  }, [page, count])

  const onPageClick = numner => () => onChange(numner)

  const onChangeCLick = action => () => {
    switch (action) {
      case 'next':
        onChange(page + 1)
        break
      case 'prev':
        onChange(page - 1)
        break
    }
  }

  const buildRenderList = () => {
    let list = []
    if (count > 4) {
      if (page === 1) list = [1, 2, 3, count]
      else if (page === count) list = [1, count - 2, count - 1, count]
      else {
        list = [page - 1, page, page + 1]
        if (!list.includes(1)) list.unshift(1)
        if (!list.includes(count)) list.push(count)
      }
    } else if (count > 0) {
      list = [1, 2, 3, 4].slice(0, count)
    } else if (count === 0) list = [1]
    return list
  }

  const renderItem = () => {
    const renderList = buildRenderList()
    const elements = []
    renderList.forEach((num, index) => {
      elements.push(<li key={num}><button data-testid={`page-${num}`} onClick={onPageClick(num)} className={`${(num === page ? 'current' : '')}`} >{num}</button></li>)
      if (renderList[index + 1] && ((num + 1) !== renderList[index + 1])) {
        elements.push(renderHidden(`hidden-${index}`))
      }
    })

    return elements
  }

  const renderHidden = key => <li key={key}><button data-testid={key} className="hidden">...</button></li>

  const renderPrevButton = () => (
    <li key='prev' ><button data-testid='prev-page' disabled={disablePrev} onClick={onChangeCLick('prev')}><FontAwesomeIcon icon={faChevronLeft} /></button></li>
  )

  const renderNextButton = () => (
    <li key='next' ><button data-testid='next-page' disabled={disableNext} onClick={onChangeCLick('next')}><FontAwesomeIcon icon={faChevronRight} /></button></li>
  )

  return (
    <PaginationWrapper>
      {renderPrevButton()}
      {renderItem()}
      {renderNextButton()}
    </PaginationWrapper>
  )
}

Pagination.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number,
  onChange: PropTypes.func
}

export default Pagination
