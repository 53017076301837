import axios from 'axios'
import { API_ENDPOINT } from '../../tools/apiEndpoint'

export const vehicleListApi = async ({ page, perPage, sort, body }) => {
  const URL = `${API_ENDPOINT}/vehicles/search`
  const params = {
    page,
    perPage
  }
  if (sort) {
    params.sort = `${sort.column} ${sort.by}`
  }
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params
  }

  const bodyParameters = { ...body }

  const response = await axios.post(
    URL,
    null || bodyParameters,
    config

  )

  return response
}

export const getVehicleApi = async ({ id }) => {
  const URL = `${API_ENDPOINT}/vehicles/${id}`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const response = await axios.get(
    URL,
    config
  )

  return response
}

export const getVehicleCSVApi = async ({ sort, body }) => {
  const URL = `${API_ENDPOINT}/vehicles/export`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: sort ? { sort: `${sort.column} ${sort.by}` } : {}
  }

  const bodyParameters = { ...body }

  return (await axios.post(
    URL,
    null || bodyParameters,
    config
  ))
}

export const createVehicleApi = async ({ exec, body }) => {
  const URL = `${API_ENDPOINT}/vehicles?exec=${exec}`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const bodyParameters = { ...body }

  const response = await axios.post(
    URL,
    null || bodyParameters,
    config

  )

  return response
}

export const editVehicleApi = async ({ exec, id, body }) => {
  const URL = `${API_ENDPOINT}/vehicles/${id}?exec=${exec}`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const bodyParameters = { ...body }
  const response = await axios.patch(
    URL,
    bodyParameters,
    config
  )

  return response
}

export const deleteVehicleApi = async ({ id }) => {
  const URL = `${API_ENDPOINT}/vehicles/${id}`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }
  const response = await axios.delete(
    URL,
    config
  )

  return response
}
