import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { vehicleListApi, getVehicleApi, createVehicleApi, editVehicleApi, deleteVehicleApi } from './vehicleAPI'
const initialState = {
  list: null,
  itemCount: 0,
  selectedVehicle: {},
  error: null,
  load: false,
  isLoading: false,
  isEditing: false
}

export const getVehicleList = createAsyncThunk(
  'vehicle/list',
  async (props, { rejectWithValue }) => {
    try {
      const response = await vehicleListApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const getSingleVehicle = createAsyncThunk(
  'vehicle/single',
  async (props, { rejectWithValue }) => {
    try {
      const response = await getVehicleApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const createVehicle = createAsyncThunk(
  'vehicle/create',
  async (props, { rejectWithValue }) => {
    try {
      const response = await createVehicleApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const editVehicle = createAsyncThunk(
  'vehicle/edit',
  async (props, { rejectWithValue, getState }) => {
    try {
      const response = await editVehicleApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const deleteVehicle = createAsyncThunk(
  'vehicle/delete',
  async (props, { rejectWithValue }) => {
    try {
      const response = await deleteVehicleApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    clearState: (state) => {
      state.list = null
      state.itemCount = 0
      state.selectedVehicle = {}
      state.error = null
      return state
    },
    setEditing: (state, { payload }) => {
      state.isEditing = payload
      return state
    }
  },
  extraReducers: {
    [getVehicleList.fulfilled]: (state, { payload }) => {
      state.error = null
      state.list = payload.rows
      state.itemCount = payload.count
      state.load = false
      return state
    },
    [getVehicleList.rejected]: (state, { payload }) => {
      state.load = false
    },
    [getVehicleList.pending]: (state) => {
      state.list = null
      state.itemCount = 0
      state.load = true
    },
    [getSingleVehicle.fulfilled]: (state, { payload }) => {
      state.error = null
      state.selectedVehicle = payload
      state.isLoading = false
      return state
    },
    [getSingleVehicle.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.selectedVehicle = {}
    },
    [getSingleVehicle.pending]: (state) => {
      state.isLoading = true
      state.selectedVehicle = {}
    },
    [createVehicle.fulfilled]: (state, { payload }) => {
      state.error = 'none'
      state.isLoading = false
      return state
    },
    [createVehicle.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload && payload.errors
    },
    [createVehicle.pending]: (state) => {
      state.isLoading = true
    },
    [editVehicle.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.error = 'none'
      return state
    },
    [editVehicle.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload && payload.errors
    },
    [editVehicle.pending]: (state) => {
      state.isLoading = true
    },
    [deleteVehicle.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.error = 'delete_none'
      return state
    },
    [deleteVehicle.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload && payload.errors
    },
    [deleteVehicle.pending]: (state) => {
      state.isLoading = true
    }
  }
})

export const { clearState, setEditing } = vehicleSlice.actions
export default vehicleSlice.reducer
