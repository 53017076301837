import styled from 'styled-components'
export const VehicleWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  section.header {
    padding: 12px;
    display: flex;
    .header-title {
      flex: 1;
      font-size: 24px;
    }
    .header-actions {
      padding-bottom: 24px;
      padding-top: 24px;
      border-radius: 5px;
      border: 1px solid #ccc;
      display: flex;
      flex: 1;
      margin: auto;
      .reduce-flex-size {
        flex: 0.8 !important;
      }
      .set-flex-width {
        flex: 0.4 !important;
      }
      .action-options {
        flex-direction: column;
        display: flex;
        margin-left: 24px;
        height: 60px;
        flex: 1;
        label {
          flex: 1;
          max-width: 90px;
        }
        select {
          flex: 1;
        }
        .MuiFormGroup-row {
          margin-top: 8px;
        }
      }
    }
  }
  section.list {
    table {
      min-height: calc(100% - 40px) !important;
    }
    padding: 12px 12px;
    flex: 1;
    height: calc(100% - 200px);
    .list-action-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 16px;
    }
    .list-action-wrapper > * {
      margin-left: 18px;
    }
    button.export {
      height: 36px;
      padding: 0.50rem 1rem;
    }
    .pagination {
      button.Mui-selected {
        background: ${props => props.theme.primeColor};
        color: ${props => props.theme.white};
      }
      .MuiPaginationItem-root {
        border-radius: 2px;
      }
    }
  }
  .submit-container {
    margin-top: auto;
    margin-bottom: auto;
  }
  .submit-container svg {
    vertical-align: middle;
  }
  .MuiFormControl-root {
    margin-left: 10px;
  }
  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused {
    color: black;
  }
  section.list .year-selection {
    margin-left: 0px;
    flex: 1;
  }
  section.list .number-field {
    text-align: center;
  }
  .year-text {
    font-size: 14px;
    vertical-align: middle;
  }
  .selection-left-arrow {
    padding-right: 35px;
    cursor: pointer;
    border: none;
    background: none;
  }
  .selection-right-arrow {
    padding-left: 35px;
    cursor: pointer;
    border: none;
    background: none;
  }
  .selection-left-arrow svg,
  .selection-right-arrow svg {
    vertical-align: middle;
    background: #B9103D;
    color: #fff;
    border-radius: 5px;
  }
`
