import axios from 'axios'
import { API_ENDPOINT } from '../../tools/apiEndpoint'

export const getContractReportApi = async (body) => {
  const URL = `${API_ENDPOINT}/contracts/monthly`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const bodyParameters = {
    year: body.year
  }

  if (body.organizationId.length > 0) {
    bodyParameters.organizationId = body.organizationId
  }
  return (await axios.post(
    URL,
    null || bodyParameters,
    config
  ))
}

export const getContractDetailApi = async (body) => {
  const URL = `${API_ENDPOINT}/contracts/detail`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const bodyParameters = {
    from: body.from,
    to: body.to
  }

  if (body.organizationId.length > 0) {
    bodyParameters.organizationId = body.organizationId
  }

  return (await axios.post(
    URL,
    null || bodyParameters,
    config
  ))
}
// detail Response
// {
//   "inspection": {
//     "1": 9,
//     "2": 10
//   },
//   "regularMaintenance": {
//     "1": 9,
//     "2": 10
//   },
//   "specialVehiclePass": {
//     "1": 9,
//     "2": 10
//   },
//   "lease": {
//     "1": 9,
//     "2": 10
//   },
//   "mandatoryInsurance": {
//     "1": 9,
//     "2": 10
//   },
//   "voluntaryInsurance": {
//     "1": 9,
//     "2": 10
//   }
// }

// {
// organizationId: [1, 2, 3], (optional)
// year: 2021 (required)
// }
//
// Response:
// [
// {
//   "period": 1,  (month number)
//   "data": {
//     "inspection": 10,
//     "regularMaintenance": 15,
//     "specialVehiclePass": 16,
//     "lease": 0,
//     "mandatoryInsurance": 17,
//     "voluntaryInsurance": 0
//   }}
// },
// ....
// {
//   "period": 12,  (month number)
//   "data": {
//     "inspection": 10,
//     "regularMaintenance": 15,
//     "specialVehiclePass": 16,
//     "lease": 0,
//     "mandatoryInsurance": 17,
//     "voluntaryInsurance": 99
//   }}
// },
//
// ]

// {
//   "errors": [
//       {
//           "msg": "Invalid value",
//           "param": "year",
//           "location": "body"
//       },
//       {
//           "msg": "Invalid value",
//           "param": "year",
//           "location": "body"
//       },
//       {
//           "value": {
//               "in": [
//                   1
//               ]
//           },
//           "msg": "Invalid value",
//           "param": "organizationId",
//           "location": "body"
//       }
//   ]
// }
