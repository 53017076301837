import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faPlus, faSearch, faPen, faTrash, faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import i18n from 'i18next'
import { Link } from 'react-router-dom'

const ripple = (from, to) => keyframes`
  0% {
    background: radial-gradient(circle at center, ${to} 0%, ${from} 100%);
  }
  25% {
    background: radial-gradient(circle at center, ${to} 25%, ${from} 75%);
  }
  50% {
    background: radial-gradient(circle at center, ${to} 50%, ${from} 50%);
  }
  75% {
    background: radial-gradient(circle at center, ${to} 75%, ${from} 25%);
  }
  100% {
    background: radial-gradient(circle at center, ${to} 100%, ${from} 0%);
  }
`

const ButtonWrapper = styled.button`
  border-radius: 5px;
  box-sizing: border-box;
  border: solid 2px ${props => props.theme.primeColor};
  ${props => {
    if (props.outline) {
      return `
        background: ${props.theme.white};
        color: ${props.theme.primeColor};
      `
    } else {
      return `
        background: ${props.theme.primeColor};
        color: ${props.theme.white};
      `
    }
  }}
  font-size: 16px;
  height: 36px;
  min-height: 28px;
  min-width: 150px;
  max-width: 200px;
  padding: 5px 10px;
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
    background: #DB566D;
    border: solid 2px #DB566D;
    color: ${props => props.theme.white};
    transition: all 150ms ease;
  }
  &:active {
    opacity: 1;
    background: #A41040;
    border: solid 2px #A41040;
    color: ${props => props.theme.white};
    animation: ${props => ripple('#DB566D', '#A41040')} 50ms
  }
  &:disabled, &[disabled]{
    border: 2px solid #D4D4D4;
    background-color: #D4D4D4;
    color: #ffffff;
  }
`

function CommonButton (props) {
  return <ButtonWrapper {...props}>{props.children}</ButtonWrapper>
}

function SearchButton (props) {
  return <CommonButton {...props} ><FontAwesomeIcon icon={faSearch} />{i18n.t('COMMON_COMMON_SEARCH-BUTTON_BUTTON')}</CommonButton>
}

function CreateButton (props) {
  return <CommonButton {...props}><FontAwesomeIcon icon={faPlus} />{i18n.t('VEHICLE_VEHICLE-LIST_CREATE-BUTTON_BUTTON')}</CommonButton>
}

function AddUserButton (props) {
  return <CommonButton {...props}><FontAwesomeIcon icon={faPlus} />{i18n.t('USER-MANAGEMENT_USER-MANAGEMENT_ADD-USER-BUTTON_BUTTON')}</CommonButton>
}

function ExportButton (props) {
  return <CommonButton {...props}><FontAwesomeIcon icon={faFileExcel} />{i18n.t('COMMON_COMMON_EXPORT-CSV-BUTTON_BUTTON')}</CommonButton>
}

function ImportButton (props) {
  return <CommonButton {...props}><FontAwesomeIcon icon={faFileExcel} />{i18n.t('REPORT_CO2-REPORT_IMPORT-CSV-BUTTON_BUTTON')}</CommonButton>
}

function ImportOrganizationButton (props) {
  return <CommonButton {...props}><FontAwesomeIcon icon={faFileExcel} />{i18n.t('REPORT_ORGANIZATION_IMPORT-CSV-BUTTON_BUTTON')}</CommonButton>
}

function EditButton (props) {
  return <CommonButton {...props}><FontAwesomeIcon icon={faPen} />{i18n.t('COMMON_COMMON_EDIT-BUTTON_BUTTON')}</CommonButton>
}

function DeleteButton (props) {
  return <CommonButton outline={true} {...props}><FontAwesomeIcon icon={faTrash} />{i18n.t('COMMON_COMMON_DELETE-BUTTON_BUTTON')}</CommonButton>
}

function SaveButton (props) {
  return <CommonButton {...props}><FontAwesomeIcon icon={faCheck} />{i18n.t('COMMON_COMMON_SAVE-BUTTON_BUTTON')}</CommonButton>
}

function BackButton (props) {
  return <CommonButton {...props}><FontAwesomeIcon icon={faArrowLeft} />{i18n.t('COMMON_COMMON_BACK-BUTTON_BUTTON')}</CommonButton>
}

function CancelButton (props) {
  return <CommonButton outline={true} {...props}>{i18n.t('COMMON_COMMON_CANCEL-BUTTON_BUTTON')}</CommonButton>
}

function YesButton (props) {
  return <CommonButton {...props}>{i18n.t('COMMON_COMMON_YES-BUTTON_BUTTON')}</CommonButton>
}

function NoButton (props) {
  return <CommonButton outline={true} {...props}>{i18n.t('COMMON_COMMON_NO-BUTTON_BUTTON')}</CommonButton>
}

function OkButton (props) {
  return <CommonButton {...props}>{i18n.t('COMMON_COMMON_OK-BUTTON_BUTTON')}</CommonButton>
}
function ExportTxtButton (props) {
  return <CommonButton {...props}>{i18n.t('COMMON_COMMON_EXPORT-ERROR_BUTTON')}</CommonButton>
}

function LoginButton (props) {
  return <CommonButton {...props}>{i18n.t('LOGIN_LOGIN_LOGIN-BUTTON_BUTTON')}</CommonButton>
}

function ImportVehicleButton (props) {
  return <CommonButton {...props}><FontAwesomeIcon icon={faFileExcel} />{i18n.t('COMMON_COMMON_IMPORT-VEHICLE-BUTTON_BUTTON')}</CommonButton>
}

function ImportFinanceButton (props) {
  return <CommonButton style={{ fontSize: '14px', width: 'min-content' }} {...props}><FontAwesomeIcon icon={faFileExcel} /> {i18n.t('COMMON_COMMON_IMPORT-FINANCE-BUTTON_BUTTON')}</CommonButton>
}

function BackToContractButton (props) {
  return <CommonButton {...props}><FontAwesomeIcon icon={faArrowLeft} />
  <Link to={{ pathname: '/contract', state: props.state }} style={{ color: 'white', textDecoration: 'none' }}>
    {i18n.t('COMMON_COMMON_BACK-CONTRACT-BUTTON_BUTTON')}
  </Link>
  </CommonButton>
}

export default CommonButton
export {
  CommonButton,
  LoginButton,
  SearchButton,
  CreateButton,
  ExportButton,
  ImportButton,
  AddUserButton,
  EditButton,
  DeleteButton,
  SaveButton,
  BackButton,
  CancelButton,
  YesButton,
  NoButton,
  OkButton,
  ExportTxtButton,
  ImportFinanceButton,
  ImportVehicleButton,
  BackToContractButton,
  ImportOrganizationButton
}

CommonButton.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  outline: PropTypes.bool
}

BackToContractButton.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  outline: PropTypes.bool,
  state: PropTypes.object
}
