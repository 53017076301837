import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import i18n from 'i18next'

import { ContractMonthlyWrapper } from './styles'

// const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const months = [
  'COMMON_COMMON_MONTH-JAN_LABEL',
  'COMMON_COMMON_MONTH-FEB_LABEL',
  'COMMON_COMMON_MONTH-MAR_LABEL',
  'COMMON_COMMON_MONTH-APR_LABEL',
  'COMMON_COMMON_MONTH-MAY_LABEL',
  'COMMON_COMMON_MONTH-JUN_LABEL',
  'COMMON_COMMON_MONTH-JUL_LABEL',
  'COMMON_COMMON_MONTH-AUG_LABEL',
  'COMMON_COMMON_MONTH-SEP_LABEL',
  'COMMON_COMMON_MONTH-OCT_LABEL',
  'COMMON_COMMON_MONTH-NOV_LABEL',
  'COMMON_COMMON_MONTH-DEC_LABEL']

function renderMonth (monthlyData, selected, onSelectMonth, currentYear) {
  const selectMonth = selected.format('MMM-YYYY')
  const result = months.map((i, index) => {
    const date = dayjs().set('month', index).set('year', currentYear)
    const monthYear = dayjs().set('month', index).set('year', currentYear).format('MMM-YYYY')
    return (
      <div key={i} data-testid={`month-${index}`} onClick={() => onSelectMonth(date)} className={`month-card ${selectMonth === monthYear ? 'active' : ''}`}>
        <div className="header-contain">{i18n.t(i)}</div>
        <div className="value-contain">
          <div className="value-box" data-testid={`inspection-month-${index}`} id="inspection">{monthlyData[index]?.data.inspection}</div>
          <div className="value-box" data-testid={`lease-month-${index}`} id="lease">{monthlyData[index]?.data.lease}</div>
          <div className="value-box" data-testid={`regular-maintenance-month-${index}`} id="regular-maintenance">{monthlyData[index]?.data.regularMaintenance}</div>
          <div className="value-box" data-testid={`mandatory-insurance-month-${index}`} id="mandatory-insurance">{monthlyData[index]?.data.mandatoryInsurance}</div>
          <div className="value-box" data-testid={`special-vehicle-pass-month-${index}`} id="special-vehicle-pass">{monthlyData[index]?.data.specialVehiclePass}</div>
          <div className="value-box" data-testid={`voluntary-insurance-month-${index}`} id="voluntary-insurance">{monthlyData[index]?.data.voluntaryInsurance}</div>
        </div>
      </div>)
  })
  return result
}

function ContractMonthly ({ data, selected, onSelectMonth, currentYear }) {
  return (
    <ContractMonthlyWrapper data-testid="monthly-view">
      {renderMonth(data, selected, onSelectMonth, currentYear)}
    </ContractMonthlyWrapper>
  )
}

export default ContractMonthly

ContractMonthly.propTypes = {
  data: PropTypes.any,
  selected: PropTypes.object,
  onSelectMonth: PropTypes.func,
  currentYear: PropTypes.node
}

ContractMonthly.defaultProps = {
  data: [
    { data: '' },
    { data: '' },
    { data: '' },
    { data: '' },
    { data: '' },
    { data: '' },
    { data: '' },
    { data: '' },
    { data: '' },
    { data: '' },
    { data: '' },
    { data: '' }
  ]
}
