/* istanbul ignore file */
import styled from 'styled-components'

export const FilterTabWrapper = styled.div`
  form.form-container {
    display: flex;
    gap: 5px;
    flex-direction: column;
  }
  div.general-filter-group {
    flex: 1;
  }
  div.filter-container {
    flex: 1;
    display: grid;
    align-content: start;
    align-items: start;
    grid-template: auto / minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 2ch;
  }
  div.advanced-input-container {
    display: grid;
    grid-template: auto / 1fr 1fr;
    select {
      margin-right: 8px;
    }
  }
  div.input-container {
    display: grid;
    min-width: 0;
    grid-template: auto / 30% 70%;
    div.label-text {
      display: flex;
      align-items: center;
      max-height 36px;
      white-space: pre-line;
    }
  }
  div.input-container > div {
    height: 56px;
  }
  div.input-group {
    display: flex;
    height: 36px;
    input[type=date], input[type=month] {
      ::-webkit-calendar-picker-indicator {
        padding: 2px;
        margin: auto;
      }
    }
  }
  select.input-item, input.input-item {
    flex: 1 1 auto;
    width: 0;
    margin-left: 10px;
    border: none;
    border:solid 1px #757575;
    border-radius: 5px;
    font-size: 14px;
  }
  select option {
    color: initial;
  }
  select option[disabled] {
    color: gray;
  }
  select.item_, select.item_undefined {
    color: gray;
  }
  label {
    font-size: 14px;
    // font-weight: bold;
  }
  p.error-text {
    margin: 0 0 0 10px;
    font-size: 12px;
    color: red;
  }
  div.submit-container {
    display: flex;
    justify-content: flex-end;
    align-items: end;
  }
  .btn-add {
    color: ${props => props.theme.primeColor};
  }
  .btn-add:hover {
    cursor: pointer;
  }
  button.submit-button {
    padding: 7px 14px;
  }
  button.search-submit {
    background: ${props => props.theme.primeColor};
    color: ${props => props.theme.white};
    border-style: none;
    padding: 7px 14px;
    border-radius: 3px;
    font-size: 12px;
    width: 80px;
    height: 30px;
    cursor: pointer;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .btn-position {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  form {
    position: relative;
    width: 100%;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
  }
  input {
    border: solid 1px grey;
    border-radius: 5px;
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .grid {
    display: flex;
    width: 100%;
    float: left;
    padding: 10px 10px 0 10px;
    box-sizing: border-box;

    ul {
      width: 100%
    }
  }
  .error {
    font-size: 12px;
    color: red;
  }
  .input-flex{
    flex: 0.5;
  }
  .item-container {
    width: 100%;
    display: flex;
    min-height: 35px;
  }
  .label {
    min-height: 25px;
    width: 35%; 
    font-weight: bold;
    font-size: 14px;
  }
  .btn-add {
    height: 25px;
  }
  .licenseplate {
    width: 33.33%;
    margin-right: 15px;
    height: 27px;
  }
  .left, .middle {
    flex-direction: 'column';
    padding: 10px;
    box-sizing: border-box;
    width: 50%;
  }
  .modal-box {
    width: 300px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .modal-message-content {
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .modal-message-button {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .modal-message-button > div {
    margin: auto;
    background: ${props => props.theme.primeColor};
    color: ${props => props.theme.white};
    border-style: none;
    padding: 6px 12px;
    border-radius: 3px;
    font-size: 14px;
    width: 80px;
    cursor: pointer;
    text-align: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
