import styled from 'styled-components'
export const UserWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  section.header {
    padding: 12px;
    display: flex;
    .header-actions {
    }
    .header-title {
      flex: 1;
      font-size: 24px;
    }
    .header-actions {
      padding-bottom: 24px;
      padding-top: 24px;
      border-radius: 5px;
      border: 1px solid #ccc;
      display: flex;
      flex: 1;
      margin: auto;
      .action-options {
        flex-direction: column;
        display: flex;
        margin-left: 24px;
        height: 60px;
        flex: 1;
        label {
          flex: 1;
          max-width: 90px;
        }
        select, input.userid {
          border: 1px solid darkgrey;
          border-radius: 6px;
          height: 32px;
          font-size: 14px;
        }
      }
    }
  }
  section.list {
    padding: 12px;
    flex: 1;
    height: calc(100% - 200px);
    .list-action-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
    button.export, button.add-user {
      padding: 0.50rem 1rem;
      border-radius: 0.3rem;
      cursor: pointer;
    }
    button.export svg{
      padding-right: 5px;
    }
    .pagination {
      .MuiPaginationItem-root {
        border-radius: 2px;
      }
    }
  }
  .submit-container {
    margin-top: auto;
    margin-bottom: auto;
  }
  .submit-container svg {
    vertical-align: middle;
  }
  .action-container {
    display: flex;
    align-items: center;
  }
  .MuiFormControl-root {
    margin-left: 10px;
  }
  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused {
    color: black;
  }
  .year-selection {
    flex: 1;
    margin-left: 40px;
  }
  .year-text {
    font-size: 14px;
    vertical-align: middle;
  }
  .selection-left-arrow {
    padding-right: 35px;
    cursor: pointer;
    border: none;
    background: none;
  }
  .selection-right-arrow {
    padding-left: 35px;
    cursor: pointer;
    border: none;
    background: none;
  }
  .selection-left-arrow svg,
  .selection-right-arrow svg {
    vertical-align: middle;
    background: #B9103D;
    color: #fff;
    border-radius: 5px;
  }
  table {
    width: 100%;
    // height: calc(100% - 20px);
    border-collapse: collapse;
    // display: block;
    overflow: auto;
    white-space: nowrap;
    table-layout: fixed;
    margin: auto;
    padding: 0;
    td {
      padding:12px;
      font-size: 14px;
    }
    td.column-USER-MANAGEMENT_USER-MANAGEMENT_USER-ID_LABEL {
      width: 30%;
    }
    td.column-COMMON_COMMON_COMPANY-DROPDOWN_LABEL,
    td.column-COMMON_COMMON_BRANCH-DROPDOWN_LABEL,
    td.column-USER-MANAGEMENT_USER-MANAGEMENT_EDIT_LABEL,
    td.column-USER-MANAGEMENT_USER-MANAGEMENT_ADMIN_LABEL {
      width: 10%;
    }
    td.column- {
      width: 210px;
    }
    thead > tr {
      background: rgb(235 235 235);
      width: 100%;
    }
    td.column-name {
      border-bottom: 1px solid grey;
      font-weight: 500;
    }
    td.value {
      border-bottom: 1px solid grey;
      word-wrap: break-word;
      white-space: normal;
    }
    .group-header {
      text-align: center;
      font-weight: 500;
    }
    .cell-buttons {
      display: flex;
      justify-content: center;
      gap: 10px;
      button {
        min-width: 100px;
      }
    }
    tr.selected-row {
      background: pink;
    }
    tr.disabled {
      pointer-events: none;
      background-color: lightgrey;
    }
    .table-message {
      position: sticky;
      z-index: 1;
      left: 0px;
      font-weight: bold;
    }
  }
  .modal-box {
    width: auto;
    background: ${props => props.theme.white};
  }
  .add-user-modal,
  .modal-message-container {
    .modal-box {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  .loading-modal {
    padding: 0;
    .modal-box {
      padding: 10px;
    }
  }
  .modal-message-content {
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .modal-message-button {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  .message-ok, .message-yes, .save, .message-no, .cancel{
    margin: auto;
  }
  p.error-text {
    margin: 0 0 0 10px;
    font-size: 12px;
    color: red;
  }
  .modal-edit-title {
    margin: 0 10px;
    font-size: 16px;
  }
  .modal-edit-message-button {
    display: flex;
    width: 100%;
    justify-content: center;
    button {
      margin: 0px 20px;
    }
    div {
      padding: 6px 12px;
      border-radius: 3px;
      font-size: 14px;
      width: 80px;
      cursor: pointer;
      text-align: center;
    }
    .cancel {
      margin-left: 24px;
    }
  }
  section.edit {
    display: flex;
    .edit-actions {
      padding-bottom: 24px;
      padding-top: 24px;
      display: flex;
      flex: 1;
      margin: auto;
      .action-options {
        flex-direction: column;
        display: flex;
        margin-left: 24px;
        height: auto;
        width: 170px;
        flex: 1;
        :first-child {
          margin-left: 10px;
        }
        .user-info {
          display: flex;
          flex-direction: column;
          height: 68px;
        }
        label {
          flex: 1;
          max-width: 90px;
          margin-right: 6px;
          font-size: 16px;
        }
        select, input.input-data {
          flex: 1;
          border: 1px solid darkgrey;
          border-radius: 6px;
          font-size: 14px;
        }
      }
    }
    input[type="checkbox"] {
      position: relative;
      height: 16px;
      width: 16px;
      appearance: none;
      border: 2px solid ${props => props.theme.primeColor};
      border-radius: 2px;
      outline: none;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      cursor: pointer;
      transform: translateY(-6px);
    }
    input[type="checkbox"]:checked::before {
      content: '✓';
      display: block;
      text-align: center;
      color: ${props => props.theme.primeColor};
      position: absolute;
      left: 1px;
      top: -3px;
      font-weight: bold;
    }
  }
  .privilege {
    display: flex;
    flex-direction: row;
    height: 100%;
    .privilege-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 24px;
      label {
        flex: 1;
        max-width: 90px;
        margin-right: 6px;
      }
    }
  }
`
