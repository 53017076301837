import styled from 'styled-components'
export const VehicleWrapper = styled.div`
  height: 100vh;
  display: ${props => props.show ? 'none' : 'flex'};
  flex-direction: column;
  section.header {
    margin: 12px;
    min-height: 36px;
    align-items: center;
    display: flex;
    .header-title {
      align-self: normal;
      font-size: 24px;
      margin-right: 12px;
    }
    .header-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: auto;
      flex: 1;
      gap 20px;
      .action-options {
        display: flex;
        gap: 5px;
        label {
          flex: 1;
          overflow: hidden;
          justify-content: flex-end;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  section.tab {
    margin: 12px;
  }
  section.list {
    margin: 12px;
    flex: 1;
    height: calc(100% - 200px);
    .list-action-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      align-items: center;
      margin-bottom: 12px;
      height: 36px;
    }
    tbody > tr.row-click:hover {
      background-color: #ebebeb;
    }
    td.number-field {
      text-align: end;
    }
  }

`
