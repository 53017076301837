import {
  createAsyncThunk,
  createSlice
  // createAsyncThunk
} from '@reduxjs/toolkit'
import { createUserApi, deleteUserApi, editUserApi, getSelfDataApi, userListApi } from './userAPI'
// import { logoutApi } from './userAPI'
const initialState = {
  token: localStorage.getItem('token') || '',
  name: '',
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  list: null,
  itemCount: 0,
  error: null,
  self: {}
}

// TODO: legacy login logout  flow pattern
// export const loginUser = createAsyncThunk(
//   'user/login',
//   async () => {
//     const uid = uuidv4()
//     const response = await loginApi(uid)
//     localStorage.setItem('token', response)
//     return response.data
//   }
// )

// export const logoutUser = createAsyncThunk(
//   'user/logout',
//   async () => {
//     try {
//       const response = await logoutApi()
//       console.log(response)
//       return ''
//     } catch (e) {
//       if (e.response.status === 401) {
//       // TODO: follow GEMS sans approach to handle with 401 status as success logout
//         localStorage.removeItem('token')
//         return 'success'
//       }
//       console.log(e.response)
//     }
//   }
// )

export const getUserList = createAsyncThunk(
  'user/list',
  async (props, { rejectWithValue }) => {
    try {
      const response = await userListApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const getSelfData = createAsyncThunk(
  'user/me',
  async (props, { rejectWithValue }) => {
    try {
      const response = await getSelfDataApi()
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const createUser = createAsyncThunk(
  'user/create',
  async (props, { rejectWithValue }) => {
    try {
      const response = await createUserApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const editUser = createAsyncThunk(
  'user/edit',
  async (props, { rejectWithValue }) => {
    try {
      const response = await editUserApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const deleteUser = createAsyncThunk(
  'user/delete',
  async (props, { rejectWithValue }) => {
    try {
      const response = await deleteUserApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isFetching = false
      state.list = null
      state.itemCount = 0
      return state
    },
    clearErrorState: (state) => {
      state.error = null
      return state
    },
    logout: (state) => {
      localStorage.removeItem('token')
      state.token = ''
    },
    setUser: (state, action) => {
      state.name = action.payload.user
      state.token = action.payload.token
    }
  },
  extraReducers: {
    // TODO: legacy login logout flow pattern
    // [loginUser.fulfilled]: (state, { payload }) => {
    //   state.token = payload.email
    //   state.isFetching = false
    //   state.isSuccess = true
    //   return state
    // },
    // [loginUser.rejected]: (state, { payload }) => {
    //   state.isFetching = false
    //   state.isError = true
    //   state.errorMessage = payload
    // },
    // [loginUser.pending]: (state) => {
    //   state.isFetching = true
    // },
    // [logoutUser.fulfilled]: (state, { payload }) => {
    //   state.token = ''
    //   state.isFetching = false
    //   state.isSuccess = true
    //   return state
    // },
    // [logoutUser.rejected]: (state, { payload }) => {
    //   state.isFetching = false
    //   state.isError = true
    //   state.errorMessage = payload
    // },
    // [logoutUser.pending]: (state) => {
    //   state.isFetching = true
    // }
    [getUserList.fulfilled]: (state, { payload }) => {
      state.error = null
      state.list = payload.rows
      state.itemCount = payload.count
      state.isFetching = false
      return state
    },
    [getUserList.rejected]: (state, { payload }) => {
      state.isFetching = false
    },
    [getUserList.pending]: (state) => {
      state.error = null
      state.list = null
      state.itemCount = 0
      state.isFetching = true
    },
    [getSelfData.fulfilled]: (state, { payload }) => {
      state.self = payload
      return state
    },
    [getSelfData.rejected]: (state, { payload }) => {
    },
    [getSelfData.pending]: (state) => {
    },
    [createUser.fulfilled]: (state, { payload }) => {
      state.error = 'none'
      return state
    },
    [createUser.rejected]: (state, { payload }) => {
      state.error = payload && payload.errors
    },
    [createUser.pending]: (state) => {
    },
    [editUser.fulfilled]: (state, { payload }) => {
      state.error = 'none'
      return state
    },
    [editUser.rejected]: (state, { payload }) => {
      state.error = payload && payload.errors
    },
    [editUser.pending]: (state) => {
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      state.error = 'none'
      return state
    },
    [deleteUser.rejected]: (state, { payload }) => {
      state.error = payload && payload.errors
    },
    [deleteUser.pending]: (state) => {
    }
  }
})

export const { clearState, setUser, logout, clearErrorState } = userSlice.actions

// export const userSelector = (state) => state.user
export default userSlice.reducer
