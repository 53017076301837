export const advancedSearchOptions = [
  { value: 'fifthWheelLoad', label: 'VEHICLE_COMMON_FIFTH-WHEEL-LOAD_LABEL', type: 'number', maxLength: '', selected: false },
  { value: 'modelDesignationNumber', label: 'VEHICLE_COMMON_MODEL-DESIGNATION-NUMBER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'classificationCategoryNumber', label: 'VEHICLE_COMMON_CLASSIFICATION-CATEGORY-NUMBER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'batteryType', label: 'VEHICLE_COMMON_BATTERY-TYPE_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'batteryReplacementDate', label: 'VEHICLE_COMMON_BATTERY-REPLACEMENT-DATE_LABEL', type: 'date', maxLength: '', selected: false },
  { value: 'transmission', label: 'VEHICLE_COMMON_TRANSMISSION_LABEL', type: 'dropdown', maxLength: '', options: ['AT', 'MT'], selected: false },
  { value: 'cabinColor', label: 'VEHICLE_COMMON_CABIN-COLOR_LABEL', type: 'dropdown', maxLength: '', options: ['ドリームカラー（ライン塗装）', 'ドリームカラー（後塗り）', 'その他'], selected: false },
  { value: 'bodyColor', label: 'VEHICLE_COMMON_BODY-COLOR_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'sbsMarking', label: 'VEHICLE_COMMON_SBS-MARKING_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'aoriHeight', label: 'VEHICLE_COMMON_AORI-HEIGHT_LABEL', type: 'number', maxLength: '10', selected: false },
  { value: 'lashingRailPosition', label: 'VEHICLE_COMMON_LASHING-RAIL-POSITION_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'tireManufacturer', label: 'VEHICLE_COMMON_TIRE-MANUFACTURER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'snowTire', label: 'VEHICLE_COMMON_SNOW-TIRE_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'tireChain', label: 'VEHICLE_COMMON_TIRE-CHAIN_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'spareTire', label: 'VEHICLE_COMMON_SPARE-TIRE_LABEL', type: 'dropdown', maxLength: '', options: ['あり', 'なし'], selected: false },
  { value: 'tireSizeFront', label: 'VEHICLE_COMMON_TIRE-SIZE-FRONT_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'tireSizeRear', label: 'VEHICLE_COMMON_TIRE-SIZE-REAR_LABEL', type: 'text', maxLength: '255', selected: false }
]
