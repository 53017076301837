import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { userApi } from './userAPI'
const initialState = {
  email: '',
  isEdit: false,
  isAdmin: false
}

export const userInfo = createAsyncThunk(
  'users',
  async () => {
    const response = await userApi()
    return response.data
  }
)

export const userInfonSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
  },
  extraReducers: {
    [userInfo.fulfilled]: (state, { payload }) => {
      state.email = payload.email
      state.isEdit = payload.isEdit
      state.isAdmin = payload.isAdmin
      return state
    },
    [userInfo.rejected]: (state, { payload }) => {
    },
    [userInfo.pending]: (state) => {
      state.email = ''
      state.isEdit = false
      state.isAdmin = false
    }
  }
})

export default userInfonSlice.reducer
