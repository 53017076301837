import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Modal from '../Modal'
import ModalError from './ModalError'
import { checkOrganization, importOrganization, clearState } from '../../features/organizations/importOrganizationSlice'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ImportOrganizationWrap } from './styles'
import { ImportOrganizationButton, NoButton, OkButton, YesButton } from '../Button'
import i18n from 'i18next'
function ImportOrganization () {
  const hiddenFileInput = React.useRef(null)

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const [selectedFile, setFile] = React.useState('')
  const [randomString, setRandomString] = React.useState('0')
  const [show, setShow] = React.useState(false)
  const [showSuccess, setSuccessShow] = React.useState(false)
  const dispatch = useDispatch()
  const organizationState = useSelector(state => state.importOrganization)

  const handleClose = () => {
    setShow(false)
    dispatch(clearState())
  }

  const handleSuccessClose = () => {
    setSuccessShow(false)
    dispatch(clearState())
  }

  React.useEffect(() => {
    dispatch(clearState())
  }, [])

  const submitImport = () => {
    dispatch(importOrganization({ file: selectedFile }))
    setShow(false)
  }

  const handleShow = e => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0])
      dispatch(checkOrganization({ file: e.target.files[0] }))
    }
    e.target.value = ''
  }
  React.useEffect(() => {
    const { check } = organizationState
    if ('insert' in check.success && 'update' in check.success) {
      setShow(true)
    }
    if ('insert' in organizationState.import.data && 'update' in organizationState.import.data) {
      setSuccessShow(true)
    }
  }, [organizationState])
  return (
    <ImportOrganizationWrap>
      <ImportOrganizationButton data-testid="import-button" onClick={handleClick} />
      <input
        type="file"
        data-testid="file-input"
        key={randomString}
        ref={hiddenFileInput}
        onChange={handleShow}
        accept=".csv"
        style={{ display: 'none' }}
      />
      {(organizationState.import.load || organizationState.check.load) &&
        <Modal id="model-popup-dialog" show={true}>
          <CircularProgress />
        </Modal>
      }
      {showSuccess && <Modal id="model-popup-dialog" show={true}>
        <section className="confirm-modal">
          <span className="flex-1">{i18n.t('REPORT_ORGANIZATION_POPUP-CONFIRM_LABEL')}</span>
          <div className="flex-1">
            <span>- {organizationState.import.data?.insert} {i18n.t('REPORT_ORGANIZATION_POPUP-SUCCESS-MESSAGE-INSERT-1_TEXT')}</span>
            <span>- {organizationState.import.data?.update} {i18n.t('REPORT_ORGANIZATION_POPUP-SUCCESS-MESSAGE-INSERT-2_TEXT')}</span>
          </div>
          <div className="bottom">
            <OkButton data-testid="success-button" onClick={handleSuccessClose} />
          </div>
        </section>
      </Modal>}
      {show && <Modal id="model-popup-dialog" show={true}>
        <section className="confirm-modal">
          <span className="flex-1">{i18n.t('REPORT_CO2-REPORT_POPUP-CONFIRM_LABEL')}</span>
          <div className="flex-1">
            <span>- {organizationState.check.success.insert} {i18n.t('REPORT_ORGANIZATION_POPUP-RECORDS-MESSAGE-INSERT-1_TEXT')}</span>
            <span>- {organizationState.check.success.update} {i18n.t('REPORT_ORGANIZATION_POPUP-RECORDS-MESSAGE-INSERT-2_TEXT')}</span>
          </div>
          <div className="bottom">
            <YesButton data-testid="yes-button" onClick={submitImport} />
            <NoButton data-testid="no-button" onClick={handleClose} />
          </div>
        </section>
      </Modal>}
      {!!organizationState.check.error.fieldName && <ModalError
        title={i18n.t('REPORT_ORGANIZATION_POPUP-WARNING1_LABEL')}
        show={true}
        testId='fieldname-modal'
        onOk={setRandomString}
      />}
      {/* {!!organizationState.check.error.blank && <ModalError
        title={i18n.t('REPORT_CO2-REPORT_POPUP-WARNING3_LABEL')}
        value={organizationState.check.error.blank}
        show={true}
        testId='blank-modal'
        onOk={setRandomString}
      />} */}
      {!!organizationState.check.error.unknown && <ModalError
        title={i18n.t('COMMON_UNKNOWN-ERROR_MESSAGE_LABEL')}
        value=""
        show={true}
        testId='unknown-modal'
        onOk={setRandomString}
      />}
      {!!organizationState.check.error.csvError && <ModalError
        title={i18n.t('REPORT_ORGANIZATION_POPUP-WARNING7_LABEL')}
        show={true}
        testId='csverror-modal'
        onOk={setRandomString}
      />}
    </ImportOrganizationWrap>
  )
}

export default ImportOrganization
ImportOrganization.propTypes = {
  children: PropTypes.node
}
