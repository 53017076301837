import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { importConsumptionApi } from './consumptionAPI'
const initialState = {
  check: {
    load: false,
    error: {},
    success: {}
  },
  import: {
    load: false,
    data: {}
  }
}

export const importConsumption = createAsyncThunk(
  'consumption/import',
  async (props, { rejectWithValue }) => {
    try {
      const response = await importConsumptionApi({ ...props, exec: true })
      return response.data
    } catch (err) {
      if (err.response.status >= 500) {
        return rejectWithValue({ error: { unknown: true } })
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const checkConsumption = createAsyncThunk(
  'consumption/import-check',
  async (props, { rejectWithValue }) => {
    try {
      const response = await importConsumptionApi({ ...props, exec: false })
      // console.log('RES', response);
      return response.data
    } catch (err) {
      if (err.response.status >= 500) {
        return rejectWithValue({ error: { unknown: true } })
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const consumptionSlice = createSlice({
  name: 'consumption',
  initialState,
  reducers: {
    clearState: (state) => {
      state.check.load = false
      state.check.error = {}
      state.check.success = {}
      state.import.data = {}
      return state
    }
  },
  extraReducers: {
    [checkConsumption.fulfilled]: (state, { payload }) => {
      state.check.load = false
      state.check.success = payload
      return state
    },
    [checkConsumption.rejected]: (state, { payload }) => {
      state.check.load = false
      state.check.error = payload.error
    },
    [checkConsumption.pending]: (state) => {
      state.check.load = true
      state.check.success = {}
      state.check.error = {}
      state.import.data = {}
    },
    [importConsumption.fulfilled]: (state, { payload }) => {
      state.import.load = false
      state.import.data = payload
      return state
    },
    [importConsumption.rejected]: (state, { payload }) => {
      state.import.load = false
      state.check.error = payload.error
    },
    [importConsumption.pending]: (state) => {
      state.import.load = true
      state.check.success = {}
      state.import.data = {}
    }
  }
})

export const { clearState } = consumptionSlice.actions

// export const userSelector = (state) => state.vehicle
export default consumptionSlice.reducer
