import styled from 'styled-components'
import Select from 'react-dropdown-select'
export const MultiSelectWrap = styled.div`
  width: 100%;
  font-size: 14px;
  .tab-row {
    display: flex;
  }
  .react-dropdown-select {
    min-height: 36px;
    border-radius: 5px;
    border-color: #757575 !important;
  }

  .react-dropdown-select:hover,
  .react-dropdown-select:focus,
  .react-dropdown-select:focus-within {
    box-shadow: none;
    border-color: #757575 !important;
  }
  .react-dropdown-select-dropdown {
    top: 28px;
    padding-bottom: 8px;
    width: calc(100% + 2px) !important;
    box-shadow: none;
    border-top: none;
    border-color: #757575 !important;
    border-radius: 0px 0px 5px 5px;
  }

  .place-holder {
    color: gray;
  }
`

export const StyledSelect = styled(Select)`
${({ dropdownRenderer }) =>
    dropdownRenderer &&
    `
  .react-dropdown-select-dropdown {
    overflow: initial;
  }
`}
${({ width }) => `
    width: ${width} !important;
  `}
`

export const SearchAndToggle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;

  svg {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #ccc;
  }

  input {
    margin: 10px 10px 0;
    line-height: 36px;
    padding: 0 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding-left: 36px;
    font-size: 14px;
    :focus {
      outline: none;
      border: 1px solid ${({ color }) => color};
    }
  }
`

export const Items = styled.div`
  overflow: auto;
  min-height: 10px;
  max-height: 200px;
`

export const Item = styled.div`
  display: flex;
  margin: 0px 10px;
  align-items: baseline;
  cursor: pointer;
  border-bottom: 1px dotted transparent;

  input[type="checkbox"] {
    position: relative;
    top: 3px;
    height: 16px;
    width: 16px;
    appearance: none;
    border: 2px solid ${props => props.theme.primeColor};
    border-radius: 2px;
    outline: none;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    cursor: pointer;
  }

  input[type="checkbox"][data-patial-check=true]::before {
    content: '◼';
    display: block;
    text-align: center;
    color: ${props => props.theme.primeColor};
    position: absolute;
    left: 0px;
    top: -2px;
    font-size: 14px;
  }

  input[type="checkbox"]:checked::before {
    content: '✓';
    display: block;
    text-align: center;
    color: ${props => props.theme.primeColor};
    position: absolute;
    left: 1px;
    top: -3px;
    font-weight: bold;
  }

  ${({ disabled }) =>
    disabled
      ? `
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  `
      : ''}
`

export const ItemLabel = styled.div`
  margin: 5px 10px;
  flex: 1;
  white-space: normal;
  word-break: break-all;
`
