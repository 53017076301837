import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { getContractReportApi, getContractDetailApi } from './contractAPI'
const initialState = {
  errors: [],
  data: [],
  load: false,
  detail: {}
}

export const getContract = createAsyncThunk(
  'contract/get',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await getContractReportApi(body)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const getContractDetail = createAsyncThunk(
  'contract/detail',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await getContractDetailApi(body)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    resetState: (state) => {
      state.data = []
      state.load = false
      state.detail = {}
      return state
    }
  },
  extraReducers: {
    [getContract.fulfilled]: (state, { payload }) => {
      state.load = false
      state.data = payload
      return state
    },
    [getContract.rejected]: (state, { payload }) => {
      state.load = false
      state.data = []
      state.errors = payload.errors
    },
    [getContract.pending]: (state) => {
      state.load = true
      state.data = []
    },
    [getContractDetail.fulfilled]: (state, { payload }) => {
      state.detail = payload
      return state
    },
    [getContractDetail.rejected]: (state, { payload }) => {
      state.errors = payload.errors
    },
    [getContractDetail.pending]: (state) => {
      state.detail = {}
    }
  }
})

export const { resetState } = contractSlice.actions

export default contractSlice.reducer
