/* istanbul ignore file */
import styled from 'styled-components'

export const QuarterWrapper = styled.div`
div.chart-container {
  display: grid;
  grid-template: minmax(0, 1fr) minmax(0, 1fr) / minmax(0, 1fr) minmax(0, 1fr);
}

div.active {
  ::after {
    content: '';
    width: 100%;
    height: 100%;
    box-shadow:  0 0 0 4px ${props => props.theme.primeColor};
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    z-index: 1;
    background: rgb(185 16 61 / 17%);
  }
}

div.chart-item {
  cursor: pointer;
  border: 1px solid #dadada;
  position: relative;

  div.chart-header {
    height: 40px;
    display: flex;
    background-color: #f1f1f1;
    p.chart-label {
      font-weight: bold;
      font-size: 16px;
      justify-content: center; 
      margin: auto;
    }
  }

  .chart-body {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    gap: 5px;
    .item {
      cursor: pointer;
      padding: 5px;
      border: none;
      border-radius: 5px;
      color: white;
      font-size: 16px;
      display: flex;
      label.text {
        margin auto;
        flex: 1;
        overflow: hidden;
        cursor: pointer;
      }
      label.number {
        margin: auto;
        cursor: pointer;
      }
    }
    .inspection {
      background-color: ${props => props.theme.primeColor};
    }
    .regularMaintenance {
      background-color: #ec9847;
    }
    .specialVehiclePass {
      background-color: #303030;
    }
    .lease {
      background-color: #196977;
    }
    .mandatoryInsurance {
      background-color: #40b761;
    }
    .voluntaryInsurance {
      background-color: #4159b7;
    }
  }
}
`
