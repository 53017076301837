import styled from 'styled-components'

export const TableWrap = styled.table`
  width: 100%;
  min-height: calc(100% - 36px);
  border-collapse: collapse;
  display: block;
  overflow: auto;
  white-space: nowrap;
  table-layout: fixed;
  td {
    padding:12px;
    font-size: 14px;
  }
  thead > tr {
    background: rgb(235 235 235);
  }
  td.column-name {
    border-bottom: 1px solid grey;
    font-weight: 500;
    white-space: pre;
  }
  td.value {
    border-bottom: 1px solid grey;
  }
  /* tbody {
    display: block;
    height:100%;
    overflow: auto;
  }
  thead {
    overflow: auto;
    display: block;
    width: 100%;
  } */

  .row-click {
    cursor: pointer;
  }

  .sticky-col-data {
    position: sticky;
    z-index: 1;
    background: white;
  }
  .sticky-col-header {
    position: sticky;
    z-index: 1;
    background: rgb(235 235 235);
  }

  .group-header {
    text-align: center;
    font-weight: 500;
  }

  .divice-col::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-right: 1px solid grey;
  }

  .divice-col-group {
    border-right: 1px solid grey;
    text-align: center;
  }

  .table-message {
    position: sticky;
    z-index: 1;
    left: 0px;
    font-weight: bold;
  }

  .value-container {
    word-wrap: break-word;
    white-space: normal;
  }
`
