import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { FilterTabWrapper } from './styles'
import i18n from 'i18next'
import { SearchButton } from '../Button'
import { integerNumberOnly } from './advanceSearchOptions'
import { advancedSearchOptions } from './Defines/updateHistoryTabDefine'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

const fieldNames = {
  licenseAddress: { name: 'licensePlateAddress', type: 'license' },
  licenseClassNo: { name: 'licensePlateClass', type: 'license' },
  licenseNumber: { name: 'licensePlateNumber', type: 'license' },
  'vehiclehistory.organizationIdUpdatedAt': {
    label: 'VEHICLE_COMMON_ORGANIZATION-ID-UPDATED-AT_LABEL',
    name: 'vehiclehistory.organizationIdUpdatedAt',
    type: 'date',
    tabIndexes: [4, 5]
  },
  'vehiclehistory.registrationDateUpdatedAt': {
    label: 'VEHICLE_COMMON_REGISTRATION-DATE-UPDATED-AT_LABEL',
    name: 'vehiclehistory.registrationDateUpdatedAt',
    type: 'date',
    tabIndexes: [6, 7]
  },
  'vehiclehistory.statusUpdatedAt': {
    label: 'VEHICLE_COMMON_STATUS-UPDATED-AT_LABEL',
    name: 'vehiclehistory.statusUpdatedAt',
    type: 'date',
    tabIndexes: [8, 9]
  },
  'vehiclehistory.userAddressUpdatedAt': {
    label: 'VEHICLE_COMMON_USER-ADDRESS-UPDATED-AT_LABEL',
    name: 'vehiclehistory.userAddressUpdatedAt',
    type: 'date',
    tabIndexes: [10, 11]
  },
  'vehiclehistory.mileageUpdatedAt': {
    label: 'VEHICLE_COMMON_MILEAGE-UPDATED-AT_LABEL',
    name: 'vehiclehistory.mileageUpdatedAt',
    type: 'date',
    tabIndexes: [12, 13]
  },
  'vehiclehistory.document1UpdatedAt': {
    label: 'VEHICLE_COMMON_DOCUMENT1-UPDATED-AT_LABEL',
    name: 'vehiclehistory.document1UpdatedAt',
    type: 'date',
    tabIndexes: [14, 15]
  },
  'vehiclehistory.document3UpdatedAt': {
    label: 'VEHICLE_COMMON_DOCUMENT3-UPDATED-AT_LABEL',
    name: 'vehiclehistory.document3UpdatedAt',
    type: 'date',
    tabIndexes: [16, 17]
  },
  'vehiclehistory.batteryReplacementDateUpdatedAt': {
    label: 'VEHICLE_COMMON_BATTERY-REPLACEMENT-DATE-UPDATED-AT_LABEL',
    name: 'vehiclehistory.batteryReplacementDateUpdatedAt',
    type: 'date',
    tabIndexes: [18, 19]
  },
  'vehiclehistory.snowTireUpdatedAt': {
    label: 'VEHICLE_COMMON_SNOW-TIRE-UPDATED-AT_LABEL',
    name: 'vehiclehistory.snowTireUpdatedAt',
    type: 'date',
    tabIndexes: [20, 21]
  },
  'vehiclehistory.tireChainUpdatedAt': {
    label: 'VEHICLE_COMMON_TIRE-CHAIN-UPDATED-AT_LABEL',
    name: 'vehiclehistory.tireChainUpdatedAt',
    type: 'date',
    tabIndexes: [22, 23]
  }
}

function UpdateHistoryTab (props) {
  const { onSearchSubmit } = props
  const { register, handleSubmit, formState: { errors }, setError, getValues, setValue, clearErrors, watch, unregister } = useForm()
  const [errorFields, setErrorFields] = React.useState([])

  const watchAdvancedSelected = watch([
    'advanceSearchLabel_1',
    'advanceSearchLabel_2',
    'advanceSearchLabel_3'
  ])

  const prepareSearchBody = data => {
    const body = {}
    for (const key of Object.keys(fieldNames)) {
      const fieldName = fieldNames[key].name
      const type = fieldNames[key].type
      const column = fieldName.split('.')[1]
      switch (type) {
        case 'license':
          if (data[fieldName].length > 0) {
            switch (fieldName) {
              case 'licensePlateNumber':
                body[fieldName] = data[fieldName]
                break
              default:
                body[fieldName] = { ilike: `%${data[fieldName]}%` }
                break
            }
          }
          break
        case 'date':
          if (data.vehiclehistory[column] && data.vehiclehistory[column].from && data.vehiclehistory[column].to) {
            body[`vehiclehistory.${column}`] = { between: [data.vehiclehistory[column].from, data.vehiclehistory[column].to] }
          } else if (data.vehiclehistory[column] && data.vehiclehistory[column].from && !data.vehiclehistory[column].to) {
            body[`vehiclehistory.${column}`] = { gte: data.vehiclehistory[column].from }
          } else if (data.vehiclehistory[column] && !data.vehiclehistory[column].from && data.vehiclehistory[column].to) {
            body[`vehiclehistory.${column}`] = { lte: data.vehiclehistory[column].to }
          }
          break
      }
    }

    for (const key of Object.keys(data)) {
      const [label, searchNumber, optionName] = key.split('_')
      if (label !== 'advanceSearchInput') continue
      if (searchNumber && optionName && data[`advanceSearchLabel_${searchNumber}`] !== optionName) {
        unregister(key)
        continue
      }
      if (data[key].length > 0) body[`vehiclehistory.${optionName}`] = { ilike: `%${data[key]}%` }
    }
    return body
  }

  const onSubmit = data => {
    const body = prepareSearchBody(data)
    onSearchSubmit(body)
  }

  const onDateBlur = field => () => {
    const [table, column] = field.split('.')
    clearErrors([`${table}.${column}.from`, `${table}.${column}.to`])
    const index = errorFields.indexOf(`${table}.${column}.from`)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    const from = Date.parse(getValues(`${table}.${column}.from`))
    const to = Date.parse(getValues(`${table}.${column}.to`))
    if (from > to && !Number.isNaN(from) && !Number.isNaN(to)) {
      setError(`${table}.${column}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${table}.${column}.from`])
    }
  }

  const onLicenseBlur = (item) => {
    const index = errorFields.indexOf(item)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    clearErrors(item)
    const licenseNum = getValues(item)
    if (licenseNum && !/^[0-9.]*$/.test(licenseNum)) {
      setErrorFields(prevState => [...prevState, item])
      setError(item, { type: 'manual', message: i18n.t('VEHICLE_VEHICLE-LIST_WARNING-NUMERIC_LABEL') })
    }
  }
  const getSelectedStatus = (formKey, value) => {
    const curSelected = getValues(formKey)

    const selectedItems = [
      getValues('advanceSearchLabel_1'),
      getValues('advanceSearchLabel_2'),
      getValues('advanceSearchLabel_3')
    ]

    if (value !== curSelected && selectedItems.includes(value)) {
      return true
    }
    return false
  }
  const renderErrorText = field => {
    let errorMessage = ''
    if (errors[field]) {
      errorMessage = errors[field].message
    }
    const column = field.split('.')[1]
    if (errors.vehiclehistory && errors.vehiclehistory[column]) {
      errorMessage = errors.vehiclehistory[column].from.message
    }
    return errorMessage ? <p data-testid={`${field}-error-text`} className="error-text">{`${errorMessage}`}</p> : ''
  }

  const contains = (stringValue, charValue) => {
    return stringValue.indexOf(charValue) > -1
  }
  // const allowTypeNumericOnly = (e) => {
  //   const invalidKey = (e.key.length === 1 && !contains(allowedChars, e.key)) || (e.key === '.' && contains(e.target.value, '.'))
  //   return invalidKey && e.preventDefault()
  // }

  const renderInput = options => {
    let component = ''
    switch (options.type) {
      case 'date':
        component = (
          <>
            <input
              {...!integerNumberOnly.includes(options.name) && { step: 'any' }}
              // onKeyDown={(e) => allowTypeNumericOnly(e)}
              className="input-item"
              type={options.type}
              tabIndex={options.tabIndexes[0]}
              placeholder="From"
              {...(options.type === 'date' && { min: '1890-01-01' })}
              {...(options.type !== 'month' && { max: watch(`${options.name}.to`) || '9999-12-31' })}
              data-testid={`${options.name}.from`}
              {...register(`${options.name}.from`)} />
            <input
              {...!integerNumberOnly.includes(options.name) && { step: 'any' }}
              className="input-item"
              type={options.type}
              tabIndex={options.tabIndexes[1]}
              placeholder="To"
              min={getValues(`${options.name}.from`) || '1890-01-01'}
              {...(options.type === 'date' && { max: '9999-12-31' })}
              data-testid={`${options.name}.to`}
              {...register(`${options.name}.to`)} />
          </>
        )
        break
    }
    return component
  }

  const renderInputGroup = options => {
    let onBlur = null
    onBlur = onDateBlur(options.name)
    return (
      <>
        <div className="label-text"><label>{i18n.t(options.label)}</label></div>
        <div>
          <div data-testid={`${options.name}-input-group`} className="input-group" onBlur={onBlur}>{renderInput(options)}</div>
          {renderErrorText(options.name)}
        </div>
      </>
    )
  }

  const renderAdvancedSearchInput = (formKey, num) => {
    const advanceSearchValue = getValues(formKey)
    const index = advancedSearchOptions.map((e) => e.value).indexOf(advanceSearchValue)
    if (!advanceSearchValue || advanceSearchValue === '1') return
    const advanceSearchItem = advancedSearchOptions[index]
    const dataType = 'text'
    // Advanced search fields only have dropdown type
    return (
      <input tabIndex={23 + num + 1}
      placeholder=''
      type={dataType}
      maxLength={advanceSearchItem.maxLength}
      {...register(`advanceSearchInput_${num}_${advanceSearchValue}`)}
      data-testid={`advanceSearchInput_${num}_${advanceSearchValue}`}
      className="input-item"
      />)
  }

  return (
    <FilterTabWrapper>
      <form className="form-container" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="filter-container">
          <div className="input-container">
            <div className="label-text">
              <label>{i18n.t('VEHICLE_COMMON_LICENSE-PLATE_LABEL')}</label>
            </div>
            <div>
              <div className="input-group" data-testid={'licensePlate-input-group'} onBlur={(e) => onLicenseBlur(fieldNames.licenseNumber.name)}>
                <input
                  className="input-item"
                  type="text"
                  maxLength={255}
                  data-testid={`${fieldNames.licenseAddress.name}`}
                  placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-ADDRESS_LABEL')}
                  {...register(fieldNames.licenseAddress.name)}
                  tabIndex={1} />
                <input
                  className="input-item"
                  type="text"
                  maxLength={255}
                  data-testid={`${fieldNames.licenseClassNo.name}`}
                  placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-CLASS-NO_LABEL')}
                  {...register(fieldNames.licenseClassNo.name)}
                  tabIndex={2} />
                <input
                  onKeyDown={(e) => { (e.key.length === 1 && !contains('0123456789', e.key)) && e.preventDefault() }}
                  className="input-item"
                  type="text"
                  maxLength={4}
                  data-testid={`${fieldNames.licenseNumber.name}`}
                  placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-NUMBER_LABEL')}
                  {...register(fieldNames.licenseNumber.name)}
                  tabIndex={3} />
              </div>
              {renderErrorText(fieldNames.licenseNumber.name)}
            </div>
            {renderInputGroup(fieldNames['vehiclehistory.statusUpdatedAt'])}
            {renderInputGroup(fieldNames['vehiclehistory.document1UpdatedAt'])}
            {renderInputGroup(fieldNames['vehiclehistory.snowTireUpdatedAt'])}
          </div>
          <div className="input-container">
            {renderInputGroup(fieldNames['vehiclehistory.organizationIdUpdatedAt'])}
            {renderInputGroup(fieldNames['vehiclehistory.userAddressUpdatedAt'])}
            {renderInputGroup(fieldNames['vehiclehistory.document3UpdatedAt'])}
            {renderInputGroup(fieldNames['vehiclehistory.tireChainUpdatedAt'])}
          </div>
          <div className="input-container">
            {renderInputGroup(fieldNames['vehiclehistory.registrationDateUpdatedAt'])}
            {renderInputGroup(fieldNames['vehiclehistory.mileageUpdatedAt'])}
            {renderInputGroup(fieldNames['vehiclehistory.batteryReplacementDateUpdatedAt'])}
          </div>
          <div className="filter-container" style={{ marginBottom: '8px' }}>
            <div className="label-text">
              <label>{i18n.t('VEHICLE_VEHICLE-HISTORY-LIST_ADVANCE-SEARCH_LABEL')}</label>
            </div>
          </div>
        </div>
          <div className="filter-container">
            {[1, 2, 3].map((e, i) => {
              return (
                <div key={e} className="advanced-input-container">
                  <div className="input-group">
                    {!watch(`advanceSearch_${e}`) && e !== 1 &&
                      <AddCircleOutlineIcon
                        style={{ alignSelf: 'center' }}
                        fontSize="large"
                        className="btn-add"
                        data-testid={`plus-icon-${e}`}
                        {...register(`advanceSearch_${e}`)}
                        {...watch(`advanceSearch_${e}`, false)}
                        onClick={() => setValue(`advanceSearch_${e}`, true)}
                      />
                    }
                    {((watch(`advanceSearch_${e}`) && watchAdvancedSelected) || e === 1) &&
                      <select defaultValue="" data-testid={`advanceSearchLabel_${e}`} className={`input-item item_${getValues(`advanceSearchLabel_${e}`)}`} style={{ marginLeft: '0' }} {...register(`advanceSearchLabel_${e}`)}
                      tabIndex={23 + e}>
                        <option value="1">{i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}</option>
                        {advancedSearchOptions.map(({ value, label }, index) => {
                          return <option value={value} key={index} disabled={getSelectedStatus(`advanceSearchLabel_${e}`, value)}>{i18n.t(label)}</option>
                        })}
                      </select>
                    }
                  </div>
                  <div className="input-group">
                    {watch(`advanceSearchLabel_${e}`) && renderAdvancedSearchInput(`advanceSearchLabel_${e}`, e)}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="submit-container">
            <SearchButton data-testid="search-button" tabIndex={31} type="submit" {...errorFields.length > 0 && { disabled: true }}/>
          </div>
      </form>
    </FilterTabWrapper>
  )
}

export default UpdateHistoryTab

UpdateHistoryTab.propTypes = {
  onSearchSubmit: PropTypes.func,
  defaultValue: PropTypes.object
}
