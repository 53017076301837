export const dialogMessageList = [
  { type: 'info', code: 'save', message: 'DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_POPUP-SAVE_LABEL' },
  { type: 'info', code: 'delete', message: 'DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_POPUP-DELETE_LABEL' },
  { type: 'info', code: 'cancel', message: 'DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_POPUP-CANCEL_LABEL' },
  { type: 'error', code: 'unknown', message: 'COMMON_UNKNOWN-ERROR_MESSAGE_LABEL' },
  { type: 'error', code: 'conflict', message: 'DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_POPUP-CONFLICT_LABEL' },
  { type: 'error', code: 'exist', message: 'DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_POPUP-DELETE-CHECK_LABEL' }
]

export const requiredItems = [
  'name',
  'sort'
]
