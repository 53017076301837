import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import i18n from 'i18next'
import { DropDownWrapper } from './styles'
import Modal from '../../components/Modal'
import { CircularProgress } from '@material-ui/core'
import EditDropDown from './EditDropDown'
import { getDropDownCategoryList, clearState } from '../../features/dropdown/dropdownSlice'

function DropDownManagement (props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [onDetail, setShowDetail] = React.useState(false)
  const userState = useSelector(state => state.user)
  const dropDownState = useSelector(state => state.dropDown)

  React.useEffect(() => {
    dispatch(clearState())
    // 親の一覧を取得
    dispatch(getDropDownCategoryList())
  }, [])

  const onListClick = (id, name) => {
    // 編集画面を表示
    setShowDetail(true)
    // URLを変更
    history.push({
      pathname: `/dropdownmanagement/edit/${id}`,
      state: {
        id: id,
        name: name
      }
    })
  }

  return (
    <>
      {onDetail && <EditDropDown />}
      <DropDownWrapper show={onDetail}>
        <Modal show={userState.isFetching} className="loading-modal"><CircularProgress></CircularProgress></Modal>
        <section className="header">
          <div className="header-title">{i18n.t('DROP-DOWN-MANAGEMENT_DROP-DOWN-MANAGEMENT_PAGE-HEADER_LABEL')}</div>
        </section>
        <section className="main">
          <div className="main-title">{i18n.t('DROP-DOWN-MANAGEMENT_DROP-DOWN-MANAGEMENT_PAGE-MIDDLE-HEADER_LABEL')}</div>
          <ul className="main-list-header">
            <li>{i18n.t('DROP-DOWN-MANAGEMENT_DROP-DOWN-MANAGEMENT_PAGE-LIST-HEADER_LABEL')}</li>
          </ul>
          <ul className="main-list">
            {dropDownState.categoryList.map((x, i) => <li key={i} onClick={() => onListClick(x.categoryId, x.name)}>{x.name}</li>)}
          </ul>
        </section>
      </DropDownWrapper>
    </>)
}
export default DropDownManagement
