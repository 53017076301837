import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Modal from '../Modal'
import ModalError from './ModalError'
import { checkConsumption, importConsumption, clearState } from '../../features/consumptions/consumptionSlice'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ImportConsumptionWrap } from './styles'
import { ImportButton, NoButton, OkButton, YesButton } from '../Button'
import i18n from 'i18next'
function ImportConsumption ({ children }) {
  const hiddenFileInput = React.useRef(null)

  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  const [selectedFile, setFile] = React.useState('')
  const [randomString, setRandomString] = React.useState('0')
  const [show, setShow] = React.useState(false)
  const [showSuccess, setSuccessShow] = React.useState(false)
  const dispatch = useDispatch()
  const consumptionState = useSelector(state => state.consumption)

  const handleClose = () => {
    setShow(false)
    dispatch(clearState())
  }

  const handleSuccessClose = () => {
    setSuccessShow(false)
  }

  React.useEffect(() => {
    dispatch(clearState())
  }, [])

  const submitImport = e => {
    dispatch(importConsumption({ file: selectedFile }))
    setShow(false)
  }

  const handleShow = e => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0])
      dispatch(checkConsumption({ file: e.target.files[0] }))
    }
    e.target.value = ''
  }
  React.useEffect(() => {
    const { check } = consumptionState
    if ('insert' in check.success && 'update' in check.success) {
      setShow(true)
    }
    if ('insert' in consumptionState.import.data && 'update' in consumptionState.import.data) {
      setSuccessShow(true)
    }
  }, [consumptionState])
  return (
    <ImportConsumptionWrap>
      <ImportButton data-testid="import-button" onClick={handleClick} />
      <input
        type="file"
        data-testid="file-input"
        key={randomString}
        ref={hiddenFileInput}
        onChange={handleShow}
        accept=".csv"
        style={{ display: 'none' }}
      />
      {(consumptionState.import.load || consumptionState.check.load) &&
        <Modal id="model-popup-dialog" show={true}>
          <CircularProgress />
        </Modal>
      }
      {showSuccess && <Modal id="model-popup-dialog" show={true}>
        <section className="confirm-modal">
          <span className="flex-1">{i18n.t('REPORT_CO2-REPORT_POPUP-CONFIRM_LABEL')}</span>
          <div className="flex-1">
            <span>- {consumptionState.import.data?.insert} {i18n.t('REPORT_CO2-REPORT_POPUP-SUCCESS-MESSAGE-INSERT-1_TEXT')}</span>
            <span>- {consumptionState.import.data?.update} {i18n.t('REPORT_CO2-REPORT_POPUP-SUCCESS-MESSAGE-INSERT-2_TEXT')}</span>
          </div>
          <div className="bottom">
            <OkButton data-testid="success-button" onClick={handleSuccessClose} />
          </div>
        </section>
      </Modal>}
      {show && <Modal id="model-popup-dialog" show={true}>
        <section className="confirm-modal">
          <span className="flex-1">{i18n.t('REPORT_CO2-REPORT_POPUP-CONFIRM_LABEL')}</span>
          <div className="flex-1">
            <span>- {consumptionState.check.success.insert} {i18n.t('REPORT_CO2-REPORT_POPUP-RECORDS-MESSAGE-INSERT-1_TEXT')}</span>
            <span>- {consumptionState.check.success.update} {i18n.t('REPORT_CO2-REPORT_POPUP-RECORDS-MESSAGE-INSERT-2_TEXT')}</span>
          </div>
          <div className="bottom">
            <YesButton data-testid="yes-button" onClick={submitImport} />
            <NoButton data-testid="no-button" onClick={handleClose} />
          </div>
        </section>
      </Modal>}
      {!!consumptionState.check.error.fieldName && <ModalError
        title={i18n.t('REPORT_CO2-REPORT_POPUP-WARNING1_LABEL')}
        show={true}
        testId='fieldname-modal'
        onOk={setRandomString}
      />}
      {!!consumptionState.check.error.mileageFuel && <ModalError
        title={i18n.t('REPORT_CO2-REPORT_POPUP-WARNING2_LABEL')}
        value={consumptionState.check.error.mileageFuel}
        show={true}
        testId='mileage-modal'
        onOk={setRandomString}
      />}
      {!!consumptionState.check.error.period && <ModalError
        title={i18n.t('REPORT_CO2-REPORT_POPUP-WARNING6_LABEL')}
        value={consumptionState.check.error.period}
        show={true}
        testId='period-modal'
        onOk={setRandomString}
      />}
      {!!consumptionState.check.error.blank && <ModalError
        title={i18n.t('REPORT_CO2-REPORT_POPUP-WARNING3_LABEL')}
        value={consumptionState.check.error.blank}
        show={true}
        testId='blank-modal'
        onOk={setRandomString}
      />}
      {!!consumptionState.check.error.duplication && <ModalError
        title={i18n.t('REPORT_CO2-REPORT_POPUP-WARNING4_LABEL')}
        value={consumptionState.check.error.duplication}
        show={true}
        testId='duplication-modal'
        onOk={setRandomString}
      />}
      {!!consumptionState.check.error.vehicleId && <ModalError
        title={i18n.t('REPORT_CO2-REPORT_POPUP-WARNING5_LABEL')}
        value={consumptionState.check.error.vehicleId}
        show={true}
        testId='vehicleid-modal'
        onOk={setRandomString}
      />}
      {!!consumptionState.check.error.unknown && <ModalError
        title={i18n.t('COMMON_UNKNOWN-ERROR_MESSAGE_LABEL')}
        value=""
        show={true}
        testId='unknown-modal'
        onOk={setRandomString}
      />}
      {!!consumptionState.check.error.csvError && <ModalError
        title={i18n.t('REPORT_CO2-REPORT_POPUP-WARNING7_LABEL')}
        show={true}
        testId='csverror-modal'
        onOk={setRandomString}
      />}
    </ImportConsumptionWrap>
  )
}

export default ImportConsumption
ImportConsumption.propTypes = {
  children: PropTypes.node
}
