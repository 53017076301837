import styled from 'styled-components'

export const PdfThumbnailWrapper = styled.a`
  position: relative;
  span {
    position: absolute;
    bottom: 12px;
    display: flex;
    justify-content: center;
    min-width: 155px;
    width: 150%;
    left: 50%;
    transform: translate(-50%,125%);
    text-align: center;
    color: grey;
  }
`
