import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons'

const Sorting = props => {
  const { sort, column, setCurrentColumn, currentColumn } = props
  const [state, setState] = React.useState(0)
  const iconMapping = {
    0: { icon: faSort, by: 'def' },
    1: { icon: faSortDown, by: 'desc' },
    2: { icon: faSortUp, by: 'asc' }
  }
  const handle = e => {
    setCurrentColumn(column)
    if (parseInt(e.target.id) === 2) {
      setState(0)
    } else {
      setState(parseInt(e.target.id) + 1)
    }
  }
  React.useEffect(() => {
    if (state || currentColumn === column) {
      sort(state, column, iconMapping[state].by)
    }
  }, [state])

  React.useEffect(() => {
    if (currentColumn !== column) {
      setState(0)
    }
  }, [currentColumn])

  return (
    <span data-testid={`sort-icon-${column}-${state}`} style={{ cursor: 'pointer' }} onClick={handle} id={state}>
      <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={iconMapping[state].icon} />
    </span>
  )
}

export default Sorting

Sorting.propTypes = {
  sort: PropTypes.func,
  column: PropTypes.string,
  setCurrentColumn: PropTypes.func,
  currentColumn: PropTypes.string
}
