import styled, { keyframes } from 'styled-components'

const ripple = (from, to) => keyframes`
  0% {
    background: radial-gradient(circle at center, ${to} 0%, ${from} 100%);
  }
  25% {
    background: radial-gradient(circle at center, ${to} 25%, ${from} 75%);
  }
  50% {
    background: radial-gradient(circle at center, ${to} 50%, ${from} 50%);
  }
  75% {
    background: radial-gradient(circle at center, ${to} 75%, ${from} 25%);
  }
  100% {
    background: radial-gradient(circle at center, ${to} 100%, ${from} 0%);
  }
`

export const PaginationWrapper = styled.ul`
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  li {
    display: list-item;
    button {
      font-size: 14px;
      height: 26px;
      margin: 0 1px;
      padding: 0 4px;
      min-width: 26px;
      color: black;
      background: ${props => props.theme.white};
      border-radius: 5px;
      border-style: none;
      cursor: pointer;
      &.current {
        color: ${props => props.theme.white};
        background: ${props => props.theme.primeColor};
      }
      &:hover {
        opacity: 1;
        background: #DB566D;
        color: ${props => props.theme.white};
        transition: all 100ms ease;
      }
      &:active:not(&:disabled):not(&.hidden) {
        opacity: 1;
        background: #A41040;
        color: ${props => props.theme.white};
        animation: ${props => ripple('#DB566D', '#A41040')} 50ms
      }
      &.hidden {
        cursor: default;
        background: ${props => props.theme.white};
        color: black;
      }
      &:disabled, &[disabled]{
        cursor: default;
        background: ${props => props.theme.white};
        color: #D4D4D4;
      }
    }
  }
`
