import React from 'react'
import PropTypes from 'prop-types'
import { ValueGroupWrapper } from './styles'
import i18n from 'i18next'
function renderValues (label, value) {
  const result = []
  const organizationItems = ['company', 'branch', 'businessUnit']
  const handleOnClick = (e) => {
    // select text
    const range = document.createRange()
    range.selectNode(e.target)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
  }

  const getTooltipElem = (parent) => {
    const tooltip = parent.querySelector('.tooltip-wrapper')
    return tooltip
  }

  const handleMouseIn = (e) => {
    if (e.target.offsetWidth < e.target.scrollWidth) {
      // get the tooltip
      const tooltip = getTooltipElem(e.target.parentNode)
      // show tooltip
      if (tooltip) {
        tooltip.style.display = 'block'
      }
    }
  }

  const handleMouseOut = (e) => {
    // get the tooltip
    const tooltip = getTooltipElem(e.target.parentNode)
    // hide tooltip
    if (tooltip) {
      tooltip.style.display = 'none'
    }
  }

  const handleTooltipLeave = (e) => {
    // hide tooltip
    e.target.parentNode.style.display = 'none'
  }

  for (const i in label) {
    const printedLabel = <div>{i18n.t(label[i])}</div>
    let printedValue = value[i] || '-'
    if (i === 'nextRegularMaintenanceMonth' && value[i]) {
      const date = new Date(value[i])
      const options = { year: 'numeric', month: 'long' }
      const newValue = date.toLocaleDateString('ja-JP', options)
      printedValue = newValue
    }
    if (i === 'licensePlateNumber') {
      printedValue = `${value.licensePlateAddress} ${value.licensePlateClass} ${value.licensePlateHiragana} ${value.licensePlateNumber}`
    }
    if (organizationItems.includes(i)) {
      printedValue = value.organization && value.organization[i]
    }
    // // value s which is in last value in column 3 of detailedVehicleInformationMap means space
    if (i === 'blank') {
      printedValue = ''
    }
    result.push(
      <div className="value-contain" key={i}>
        {printedLabel}
        {<div data-testid={`value-${label[i]}`} className="value" onMouseOver={handleMouseIn} onMouseLeave={handleMouseOut}>
          <div data-testid={`text-${label[i]}`} onClick={handleOnClick}>{printedValue}</div>
          {value[i]
            ? (
            <span data-testid={`tooltip-${label[i]}`} className="tooltip-wrapper" onMouseOut={handleTooltipLeave}>
              <span className="tooltiptext">{printedValue}</span>
            </span>
              )
            : ''}
        </div>}
      </div>
    )
  }
  return result
}

function ValueGroup ({ title, valueLabels, values, column, row, children }) {
  return (
    <ValueGroupWrapper column={column} row={row}>
      <h3>{title}</h3>
      <div className="content">
        {renderValues(valueLabels, values)}
        {children}
      </div>
    </ValueGroupWrapper>
  )
}

export default ValueGroup
ValueGroup.propTypes = {
  title: PropTypes.string,
  valueLabels: PropTypes.object,
  values: PropTypes.object,
  column: PropTypes.number,
  row: PropTypes.number,
  children: PropTypes.any
}
