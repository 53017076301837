import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { dropDownCategoryListApi, dropDownContentListApi, editDropDownApi } from './dropdownAPI'
const initialState = {
  categoryList: [],
  contentList: [],
  selectedDropDown: {},
  error: null,
  load: false,
  isLoading: false,
  isEditing: false
}

export const getDropDownCategoryList = createAsyncThunk(
  'dropdown/mainSearch',
  async (props, rejectWithValue) => {
    try {
      const response = await dropDownCategoryListApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const getDropDownContentList = createAsyncThunk(
  'dropdown/subSearch',
  async (props, { rejectWithValue }) => {
    try {
      const response = await dropDownContentListApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const editDropDown = createAsyncThunk(
  'dropdown/edit',
  async (props, { rejectWithValue, getState }) => {
    try {
      const response = await editDropDownApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const dropDownSlice = createSlice({
  name: 'dropDown',
  initialState,
  reducers: {
    clearState: (state) => {
      state.categoryList = []
      state.contentList = []
      state.selectedVehicle = {}
      state.error = null
      return state
    },
    setEditing: (state, { payload }) => {
      state.isEditing = payload
      return state
    }
  },
  extraReducers: {
    [getDropDownCategoryList.fulfilled]: (state, { payload }) => {
      state.error = null
      state.categoryList = payload.rows
      state.load = false
      return state
    },
    [getDropDownCategoryList.rejected]: (state, { payload }) => {
      state.load = false
    },
    [getDropDownCategoryList.pending]: (state) => {
      state.categoryList = []
      state.load = true
    },
    [getDropDownContentList.fulfilled]: (state, { payload }) => {
      state.error = null
      state.contentList = payload.rows
      state.load = false
      return state
    },
    [getDropDownContentList.rejected]: (state, { payload }) => {
      state.load = false
    },
    [getDropDownContentList.pending]: (state) => {
      state.contentList = []
      state.load = true
    },
    [editDropDown.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.error = 'none'
      return state
    },
    [editDropDown.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload && payload.errors
    },
    [editDropDown.pending]: (state) => {
      state.isLoading = true
    }
  }
})

export const { clearState, setEditing } = dropDownSlice.actions
export default dropDownSlice.reducer
