import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import i18n from 'i18next'

const ripple = (from, to) => keyframes`
  0% {
    background: radial-gradient(circle at center, ${to} 0%, ${from} 100%);
  }
  25% {
    background: radial-gradient(circle at center, ${to} 25%, ${from} 75%);
  }
  50% {
    background: radial-gradient(circle at center, ${to} 50%, ${from} 50%);
  }
  75% {
    background: radial-gradient(circle at center, ${to} 75%, ${from} 25%);
  }
  100% {
    background: radial-gradient(circle at center, ${to} 100%, ${from} 0%);
  }
`

const ContractViewToggleWrapper = styled.div`
  .group {
    height: 36px;
    min-width: 320px;
    display: flex;

    button {
      flex: 1;
      height: 100%;
      cursor: pointer;
      box-sizing: border-box;
      border: solid 2px ${props => props.theme.primeColor};
      background: ${props => props.theme.white};
      color: black;
      font-size: 16px;

      &:hover {
        opacity: 1;
        background: #DB566D;
        border: solid 2px #DB566D;
        color: ${props => props.theme.white};
        transition: all 200ms ease;
      }
      &:active {
        opacity: 1;
        background: #A41040;
        border: solid 2px #A41040;
        color: ${props => props.theme.white};
        animation: ${props => ripple('#DB566D', '#A41040')} 50ms
      }
    }

    button.active {
      background: ${props => props.theme.primeColor};
      color: ${props => props.theme.white};

      &:hover {
        opacity: 1;
        background: #DB566D;
        border: solid 2px #DB566D;
        color: ${props => props.theme.white};
        transition: all 200ms ease;
      }

      &:active {
        opacity: 1;
        background: #A41040;
        border: solid 2px #A41040;
        color: ${props => props.theme.white};
        animation: ${props => ripple('#DB566D', '#A41040')} 50ms
      }
    }

    button.left {
      border-radius: 5px 0px 0px 5px;
    }

    button.right {
      border-radius: 0px 5px 5px 0px;
    }
  }

`

function ContractViewToggle ({ onClick, active }) {
  const getActive = type => active === type ? 'active' : ''

  return (
    <ContractViewToggleWrapper>
      <div className="group" >
        <button className={`left ${getActive('monthly')}`} data-testid="monthly-view-button" id="monthly" onClick={onClick} >{i18n.t('CONTRACT_CONTRACT-MANAGEMENT_MONTHLY_BUTTON')}</button>
        <button className={`right ${getActive('quarterly')}`} data-testid="quarterly-view-button" id="quarterly" onClick={onClick}>{i18n.t('CONTRACT_CONTRACT-MANAGEMENT_QUARTERLY_BUTTON')}</button>
      </div>
    </ContractViewToggleWrapper>
  )
}

export default ContractViewToggle

ContractViewToggle.propTypes = {
  active: PropTypes.string,
  onClick: PropTypes.func
}
