import React from 'react'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import Sorting from '../Sorting'
import { TableWrap } from './styles'
import i18n from 'i18next'

function Table (props) {
  const [currentColumn, setCurrentColumn] = React.useState('')
  const { groups, stickyWidthPass, data, columnName, sortHandle, resetSwitch, onItemClick } = props
  const sortTable = (e, col, type) => {
    setCurrentColumn(col)
    sortHandle(col, type)
  }

  React.useEffect(() => {
    setCurrentColumn('')
  }, [resetSwitch])

  const stickyWidth = stickyWidthPass || {}

  const vehicleHistoryCols = [
    'vehiclehistory.organizationIdUpdatedAt',
    'vehiclehistory.organizationIdUpdatedBy',
    'vehiclehistory.registrationDateUpdatedAt',
    'vehiclehistory.registrationDateUpdatedBy',
    'vehiclehistory.statusUpdatedAt',
    'vehiclehistory.statusUpdatedBy',
    'vehiclehistory.userAddressUpdatedAt',
    'vehiclehistory.userAddressUpdatedBy',
    'vehiclehistory.mileageUpdatedAt',
    'vehiclehistory.mileageUpdatedBy',
    'vehiclehistory.document1UpdatedAt',
    'vehiclehistory.document1UpdatedBy',
    'vehiclehistory.document3UpdatedAt',
    'vehiclehistory.document3UpdatedBy',
    'vehiclehistory.batteryReplacementDateUpdatedAt',
    'vehiclehistory.batteryReplacementDateUpdatedBy',
    'vehiclehistory.snowTireUpdatedAt',
    'vehiclehistory.snowTireUpdatedBy',
    'vehiclehistory.tireChainUpdatedAt',
    'vehiclehistory.tireChainUpdatedBy'
  ]

  const getGroupDivide = (groups, cols) => {
    if (!groups) { return [] }
    const result = []
    const colKeys = Object.keys(cols).filter((item) => !(item in stickyWidth))
    for (const groupItem of groups) {
      for (let i = 1; i <= groupItem.colspan; i++) {
        if (colKeys.length === 0) { break }
        if (i === groupItem.colspan) {
          result.push(colKeys[0])
        }
        colKeys.shift()
      }
    }
    return result
  }

  const groupDivice = getGroupDivide(groups, columnName)

  const renderGroupColumn = (groups, cols) => {
    const result1 = []
    const result2 = []
    // first row
    let curLeft = 0
    for (const i in stickyWidth) {
      const border = i === Object.keys(stickyWidth)[Object.keys(stickyWidth).length - 1]
      result1.push(<td key={i} className={`column-name sticky-col-header ${border ? 'divice-col' : ''}`} style={{ left: `${curLeft}px` }} rowSpan={2}>
        <div style={{ width: `${stickyWidth[i]}px` }}></div>
        {i18n.t(cols[i])} <Sorting column={i} sort={sortTable} currentColumn={currentColumn} setCurrentColumn={setCurrentColumn} /></td>)
      curLeft += 12
      curLeft += (stickyWidth[i] + 12)
    }

    for (const groupItem of groups) {
      result1.push(<td key={groupItem.label} className='group-header divice-col-group' colSpan={groupItem.colspan}>
        {i18n.t(groupItem.label)} </td>)
    }

    // second row
    for (const colKey in cols) {
      if (colKey in stickyWidth) { continue }
      result2.push(<td key={colKey} className={`column-name group-header ${groupDivice.includes(colKey) ? 'divice-col-group' : ''}`}>
        <Trans>{i18n.t(cols[colKey])}</Trans></td>)
    }

    return <thead className="header">
      <tr>{result1}</tr>
      <tr>{result2}</tr>
    </thead>
  }

  const renderColumnName = d => {
    const result = []
    let curLeft = 0
    for (const i in d) {
      result.push(<td key={i} className={`column-name ${stickyWidth[i] ? 'sticky-col-header' : ''}`}
        {...(
          stickyWidth[i]
            ? {
                style:
              {
                left: `${curLeft}px`
              }
              }
            : {})
        }
      >
        <div
          {...(
            stickyWidth[i]
              ? {
                  style:
                {
                  width: `${stickyWidth[i]}px`
                }
                }
              : {})
          }
        ></div>
        {i18n.t(d[i])} <Sorting column={i} sort={sortTable} currentColumn={currentColumn} setCurrentColumn={setCurrentColumn} /></td>)
      if (stickyWidth[i]) {
        curLeft += 12
        curLeft += (stickyWidth[i] + 12)
      }
    }
    return <thead className="header"><tr>{result}</tr></thead>
  }

  const handleRowClick = id => {
    if (props.history) {
      // Prevent action while selecting
      const selection = window.getSelection().toString()
      if (selection.length > 0) { return }
      // Do the action
      // TODO: change path to /vehicle/:id!
      onItemClick ? onItemClick(id) : props.history.push(`/viewinfo/${id}`)
    }
  }

  const renderData = list => {
    // no data
    if (list && list.length === 0) {
      return (
        <tbody className="table">
          <tr>
            <td className="table-message">{i18n.t('COMMON_COMMON_DATA-NOT-FOUND_LABEL')}</td>
          </tr>
        </tbody>
      )
    }
    // initial
    if (!list) {
      list = []
    }
    let result = []
    result = list.map((i, index) => {
      const row = []
      let dex = 0
      let curLeft = 0
      for (const j in columnName) {
        let value = i[j] === null || i[j] === undefined ? '-' : i[j]
        const border = (j in stickyWidth) && (j === Object.keys(stickyWidth)[Object.keys(stickyWidth).length - 1])
        if (j === 'licensePlateNumber') {
          value = `${i.licensePlateAddress} ${i.licensePlateClass} ${i.licensePlateHiragana} ${i.licensePlateNumber}`
        }
        if (j === 'nextRegularMaintenanceMonth' && value !== '-') {
          const date = new Date(value)
          const options = { year: 'numeric', month: 'long' }
          const newValue = date.toLocaleDateString('ja-JP', options)
          value = newValue
        }
        if (i.organization && ['organization.company', 'organization.department', 'organization.businessUnit', 'organization.branchOffice', 'organization.branch'].includes(j)) {
          value = i.organization[j.split('.')[1]]
        }
        if (i.vehiclehistory && vehicleHistoryCols.includes(j)) {
          value = i.vehiclehistory[j.split('.')[1]]
        }
        row.push(<td data-testid='render-value' key={dex} className={`value ${stickyWidth[j] ? 'sticky-col-data' : ''} ${border ? 'divice-col' : ''}  ${groupDivice.includes(j) ? 'divice-col-group' : ''} ${typeof value === 'number' ? 'number-field' : 'string-field'}`}
          {...(stickyWidth[j]
            ? {
                style: {
                  left: `${curLeft}px`
                }
              }
            : {})}
        ><div className={stickyWidth[j] ? 'value-container' : ''}
          {...(stickyWidth[j]
            ? {
                style: {
                  width: `${stickyWidth[j]}px`
                }
              }
            : {})}
        >{value}</div></td>)
        dex = ++dex

        if (stickyWidth[j]) {
          curLeft += 12
          curLeft += (stickyWidth[j] + 12)
        }
      }
      return <tr data-testid={`row-${index}`} className={`row ${props.history ? 'row-click' : ''}`} onClick={() => handleRowClick(list[index].id)} id={list[index].id} key={index}>{row}</tr>
    })
    return <tbody className="table">{result}</tbody>
  }
  return (
    <TableWrap>
      {groups ? renderGroupColumn(groups, columnName) : renderColumnName(columnName)}
      {renderData(data)}
    </TableWrap>
  )
}

export default Table
Table.propTypes = {
  data: PropTypes.array,
  columnName: PropTypes.object,
  sortHandle: PropTypes.func,
  stickyWidthPass: PropTypes.object,
  groups: PropTypes.array,
  history: PropTypes.object,
  resetSwitch: PropTypes.bool,
  onItemClick: PropTypes.func
}
