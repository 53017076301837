import axios from 'axios'
import { API_ENDPOINT } from '../../tools/apiEndpoint'

export const reportListApi = async ({ page, perPage, sort, body }) => {
  const URL = `${API_ENDPOINT}/consumptions/search`
  const params = {
    page,
    perPage
  }
  if (sort) {
    params.sort = `${sort.column} ${sort.by}`
  }
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params
  }

  const bodyParameters = { ...body }

  const response = await axios.post(
    URL,
    null || bodyParameters,
    config

  )

  return response
}

export const getReportCSVApi = async ({ sort, body }) => {
  const URL = `${API_ENDPOINT}/consumptions/export`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: sort ? { sort: `${sort.column} ${sort.by}` } : {}
  }

  const bodyParameters = { ...body }

  return (await axios.post(
    URL,
    null || bodyParameters,
    config
  ))
}
