import styled from 'styled-components'

export const ModalWrap = styled.div`
  position: fixed;
  left:0;
  top:0;
  right:0;
  bottom:0;
  display:flex;
  align-items:center;
  justify-content:center;
  background: rgb(0 0 0 / 65%);
  z-index: 30;
  opacity:0;
  transition: all 0.1s ease-in-out;
  pointer-events: none;
  ${props => props.show ? 'opacity: 1; pointer-events: visible;' : ''}
  .modal-box {
    padding: 10px;
    background: ${props => props.theme.white};
    border-radius: 3px;
  }
  .MuiCircularProgress-circle {
    stroke: ${props => props.theme.primeColor};
  }
`
export const LogoutWrap = styled.form`
  min-width: 350px;
  padding: 16px 32px;
  section {
    padding-top: 36px;
    display: flex;
    justify-content: space-around;
  }
`

export const ActionConfirmWrap = styled.div`
  width: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  .modal-message-content {
    min-width: 300px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .modal-message-button {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
  .message-yes{
    background: ${props => props.theme.primeColor};
    color: ${props => props.theme.white};
  }
  .message-no{
    border: 1px solid ${props => props.theme.primeColor};
    color: ${props => props.theme.primeColor}
  }
`
